<template>
  <div class="p-4 flex text-sm flex-col items-center max-[575px]:p-2">
    <div class="grid grid-cols-1 max-w-[1200px] w-full">
      <div class="w-full px-2 py-2 sm:px-0 flex flex-row items-center">
        <TabGroup
          :selectedIndex="historyS.selectedTab"
          @change="changeTab"
          as="div"
          class="hidden min-w-md flex-1">
          <div class="flex flex-row justify-start pb-4 md:pb-8">
            <TabList class="flex space-x-4 rounded-full max-w-md">
              <Tab
                v-for="item in items"
                as="template"
                :key="item.key"
                v-slot="{ selected }">
                <button
                  class="min-w-[100px] py-2.5 text-sm font-medium text-white border-b-2 focus:outline-none"
                  :class="[
                    selected
                      ? 'border-onprimary'
                      : 'text-white hover:opacity-75 border-white',
                  ]">
                  <span>{{ item.name }}</span>
                </button>
              </Tab>
            </TabList>
          </div>
        </TabGroup>
        <div class="flex-grow border-b border-light-border/40">
          <ul
            class="flex flex-wrap -mb-px sm:space-x-2 w-full max-[575px]:justify-between">
            <li v-for="(page, i) in items">
              <button
                :class="{
                  '!text-white rounded-t-lg border-b-2 border-success-pink':
                    historyS.selectedTab === i,
                }"
                class="inline-block px-2 py-3 sm:p-4 font-semibold text-text-dim-color text-left"
                v-on:click="changeTab(i)">
                {{ page.name }}
              </button>
            </li>
          </ul>
        </div>
        <div class="hidden md:flex items-center">
          <img src="../../assets/svg/mines/icon-eye.svg" class="px-2" alt="" />
          <span class="border-l-2 px-2 h-10 hidden lg:block border-slate-600">
            <select
              class="rounded-lg border-line-color bg-secondary-light"
              v-model="historyS.pageLength">
              <option value="11">10</option>
              <option value="21">20</option>
              <option value="36">35</option>
              <option value="51">50</option>
            </select>
          </span>
        </div>
      </div>

      <div
        class="w-full rounded-md"
        :style="
          'height: ' +
          tableHeight +
          'px; max-height:' +
          tableHeight +
          'px; overflow: hidden;'
        ">
        <p v-if="!currentTable || currentTable.length === 0">
          <label class="flex flex-row items-center justify-center text-center w-full">
            <div v-if="this.loading" class="pt-2">
              <svg
                class="h-10"
                id="L4"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                enable-background="new 0 0 0 0"
                xml:space="preserve">
                <circle fill="#fff" stroke="none" cx="6" cy="50" r="6">
                  <animate
                    attributeName="opacity"
                    dur="1s"
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin="0.1" />
                </circle>
                <circle fill="#fff" stroke="none" cx="26" cy="50" r="6">
                  <animate
                    attributeName="opacity"
                    dur="1s"
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin="0.2" />
                </circle>
                <circle fill="#fff" stroke="none" cx="46" cy="50" r="6">
                  <animate
                    attributeName="opacity"
                    dur="1s"
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin="0.3" />
                </circle>
              </svg>
            </div>
            <div v-else class="pt-2">Hmm... Nothing to see here.</div>
          </label>
        </p>
        <div
          v-else
          :style="
            'height: ' +
            tableHeight +
            'px; max-height:' +
            tableHeight +
            'px; overflow: hidden;'
          ">
          <table class="table w-full">
            <thead>
              <tr class="text-slate-300">
                <th class="flex w-[200px]">
                  <label class="px-3"> Game </label>
                </th>
                <th class="hidden lg:table-cell w-[120px]">
                  <label> ID </label>
                </th>
                <th class="hidden md:table-cell w-[230px]">
                  <label class="w-[90px] 2xl:w-full justify-center"> Username </label>
                </th>
                <th class="hidden xl:table-cell w-[100px]">
                  <label> Time </label>
                </th>
                <th class="hidden xl:table-cell w-[160px] text-right">
                  <label> Play </label>
                </th>
                <th class="hidden xl:table-cell w-[140px] text-right">
                  <label> Multiplier </label>
                </th>
                <th class="w-[160px] text-right">
                  <label class="px-3"> Profit </label>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(bet, idx) in currentPage"
                v-bind:key="bet.id"
                :class="bet.class"
                :style="rowStyle(idx)">
                <td class="rounded-l-lg max-w-[50px] min-w-[10px] px-3 py-2">
                  <router-link
                    :is="
                      bet.user !== null || (isLoggedIn && bet.user_id === me.id)
                        ? 'router-link'
                        : 'span'
                    "
                    :to="gameNameToGameClass(bet.game_name, bet.game_id, bet.id, bet.user_id)"
                    :event="rowEvent(idx)"
                    class="flex flex-row items-center justify-start w-full"
                    :title="bet.game_name === 'Dicing' ? 'Dice' : bet.game_name">
                    <img
                      class="h-4 w-4 mr-2"
                      v-if="getGameIcon(bet.game_name)"
                      :src="getGameIcon(bet.game_name)" />
                    <span
                      class="whitespace-nowrap overflow-hidden text-ellipsis block max-w-[140px]"
                      >{{ bet.game_name === 'Dicing' ? 'Dice' : bet.game_name }}</span
                    >
                  </router-link>
                </td>
                <td
                  class="hidden lg:table-cell hover:opacity-70 max-w-[50px] min-w-[10px]">
                  <router-link
                    :is="
                      bet.user !== null || (isLoggedIn && bet.user_id === me.id)
                        ? 'router-link'
                        : 'span'
                    "
                    :to="gameNameToGameClass(bet.game_name, bet.game_id, bet.id, bet.user_id)"
                    :event="rowEvent(idx)">
                    <span
                      class="flex flex-row items-center justify-center text-center"
                      :style="`opacity:${
                        bet.user !== null || (isLoggedIn && bet.user_id === me.id)
                          ? 1
                          : 0.5
                      }`">
                      {{ bet.id.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
                    </span>
                  </router-link>
                </td>
                <td
                  class="hidden md:table-cell max-w-[50px] min-w-[10px]"
                  style="min-width: 90px; max-width: 90px; overflow: hidden">
                  <span
                    v-if="bet.user !== null || (isLoggedIn && bet.user_id === me.id)"
                    class="flex flex-row items-center justify-center"
                    style="color: #ffffff">
                    {{ bet.user !== null ? bet.user.username : me.username }}
                  </span>
                  <span
                    v-else
                    class="flex flex-row items-center justify-center w-full"
                    v-tooltip="`User has enabled private mode`"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      flex-direction: row;
                      opacity: 0.54;
                    ">
                    Hidden
                    <img
                      style="width: 16px; margin-left: 5px; opacity: 0.54"
                      src="../../assets/svg/ic_ghost_mode.svg"
                      alt="incognito logo" />
                  </span>
                </td>
                <td class="hidden xl:table-cell max-w-[50px] min-w-[10px]">
                  <label
                    class="flex flex-row items-center justify-center text-center w-full"
                    style="color: #9097a8">
                    {{ formattedTime(bet.timestamped) }}
                  </label>
                </td>

                <td class="hidden xl:table-cell max-w-[50px] min-w-[10px]">
                  <label
                    class="flex flex-row items-center justify-end text-right w-full"
                    style="color: #9097a8">
                    {{ balS.formatCrypto(parseFloat(bet.wager), true) }}
                    <img alt="icon" :src="getIcon(bet.game_mode)" class="ml-2 w-4 h-4" />
                  </label>
                </td>
                <td class="hidden xl:table-cell max-w-[50px] min-w-[10px]">
                  <label
                    class="flex flex-row items-center justify-end text-right w-[120px] 2xl:w-full"
                    style="color: #9097a8"
                    >{{
                      Number.parseFloat(bet.multiplier)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '×'
                    }}
                  </label>
                </td>
                <td class="rounded-r-lg max-w-[50px] min-w-[10px]">
                  <label
                    v-if="bet.profit > 0"
                    class="flex flex-row items-center justify-end text-right w-full pr-2"
                    style="color: #00e405"
                    >{{ balS.formatCrypto(parseFloat(bet.profit), true) }}
                    <img alt="icon" :src="getIcon(bet.game_mode)" class="ml-2 w-4 h-4" />
                  </label>
                  <label
                    v-else
                    class="flex flex-row items-center justify-end text-right w-full pr-2"
                    style="color: #9097a8"
                    >{{ balS.formatCrypto(parseFloat(bet.profit), true) }}
                    <img alt="icon" :src="getIcon(bet.game_mode)" class="ml-2 w-4 h-4" />
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue';
import { orderBy } from 'lodash';
import { defaultConvertor } from '@/lib/rsgp';
import { mapGetters } from 'vuex';
import axios from '../../lib/myAxios';
import { getBalanceIcon } from '@/lib/icons';
import { balanceStore } from '@/store/pinia/balanceStore';
import { historyStore } from '@/store/historyStore';
import dice from '@/assets/svg/history/dice.svg';
import limbo from '@/assets/svg/history/limbo.svg';
import diamond from '@/assets/svg/history/diamond.svg';
import duelsIcon from '@/assets/svg/history/duels.svg';
import hilo from '@/assets/svg/history/hilo.svg';
import plinko from '@/assets/svg/history/plinko.svg';
import war from '@/assets/svg/history/war.svg';
import keno from '@/assets/svg/history/keno.svg';
import chip from '@/assets/svg/history/chip.svg';
import bet from '@/assets/svg/history/bet.svg';
//import crash from '@/assets/svg/history/crash.svg'

const tabs = [
  { key: 'mine', name: 'My Games' },
  { key: 'all', name: 'All Games' },
  { key: 'big', name: 'High Rollers' },
];
export default {
  components: {
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
  },
  setup() {
    const balS = balanceStore();
    const historyS = historyStore();
    return { balS, historyS };
  },
  data: () => ({
    page: 1,
    loading: false,
    historyData: [],
    fetchingHistoryInProgress: false,
  }),
  props: {
    forceRender: false,
  },
  created() {
    /*window.Echo.channel('game-history').listen('BetResult', ({ history }) => {
      if (this.historyData.length > 50) {
        this.historyData.shift();
      }

      this.historyData.push(history);
    });*/

    window.Echo.channel('game-history').listen('BetResultsBulk', ({ history }) => {
      const existing = this.historyData;
      this.historyData = [...history, ...existing];
      if (this.historyData.length > 60) {
        this.historyData = this.historyData.slice(0, 60);
      }
    });

    setInterval(() => {
      if (this.historyData.length > 0) {
        let historyRow = this.historyData.shift();

        if (historyRow) {
          /*
          while(historyRow.timestamped < (new Date().setSeconds(0)/1000))
          {
             historyRow = this.historyData.shift();
          }*/
          this.pushRollOut(historyRow);
        }
      } else {
        // this.fetchHistory('all');
      }
    }, 1000);

    if (this.isLoggedIn) {
      if(this.me && typeof this.me.id !== "undefined" )
      {
        try {
          window.Echo.private(`App.User.${this.me.id}`).listen(
            'PrivateBetResult',
            ({ history }) => {
              this.pushRoll('mine', history);
            },
          );
        } catch(error) {
          console.log('PrivateBetResult failed');
        }
      }
    }
  },
  mounted() {
    this.loading = true;
    setTimeout(() => {
      this.changeTableTab('all');
      this.historyS.selectedTab = this.isLoggedIn ? 1 : 0;
    }, 1500);
  },
  computed: {
    ...mapGetters(['me', 'isLoggedIn', 'userBalance']),
    currentPage() {
      const offset = (this.historyS.page - 1) * this.historyS.pageLength;
      return this.currentTable.slice(offset, offset + this.historyS.pageLength - 1);
    },
    currentTable() {
      // return orderBy(this.historyS.tables[this.historyS.tab], ["id"], ["desc"]);
      return this.historyS.tables[this.historyS.tab];
    },
    tableHeight() {
      return 50 * (Number.parseInt(this.historyS.pageLength) + 1);
    },
    items() {
      return tabs.filter((tab) => {
        return !(!this.isLoggedIn && tab.key === 'mine');
      });
    },
  },
  methods: {
    getIcon(currency) {
      return getBalanceIcon(currency);
    },

    getGameIcon(name) {
      switch (name) {
        case 'Poker':
          return hilo;
        case 'Slots':
          return diamond;
        case 'Dicing':
          return dice;
        case 'Blackjack':
          return hilo;
        case 'Baccarat':
          return hilo;
        case 'Duels':
          return duelsIcon;
        case 'Limbo':
          return limbo;
        case 'Mines':
          return diamond;
        case 'Plinko':
          return plinko;
        case 'War':
          return war;
        case 'Roulette':
          return chip;
        case 'Keno':
          return keno;
        /*case 'Crash':
          return crash*/
        default:
          return bet;
      }
    },
    rsgp(x) {
      return defaultConvertor.format(x);
    },
    async fetchHistory(table) {
      try {
        const response = await axios.get(`history/${table}`);
        return response.data;
      } catch (error) {
        console.error('Error fetching history:', error);
        return { data: [] };
      }
    },
    changeTab(index) {
      this.historyS.selectedTab = index;
      const tab = this.items[index];
      this.changeTableTab(tab.key);
    },
    async changeTableTab(x) {
      this.historyS.tab = x;
      if (this.historyS.loaded[x]) return;
      this.loading = true;
      try {
        const bets = await this.fetchHistory(x);
        this.historyS.tables[x] = [];
        bets.data.forEach((bet) => this.pushRoll(x, bet));
        this.historyS.loaded[x] = true;
      } catch (error) {
        console.error('Error fetching table tab:', error);
        this.historyS.tables[x] = [];
      } finally {
        this.loading = false;
      }
    },
    rowStyle(idx) {
      if (idx === this.historyS.pageLength - 1) {
        return 'animation-name: last-row';
      } else {
        return '';
      }
    },
    rowEvent(idx) {
      if (idx === this.historyS.pageLength - 1) {
        return '';
      } else {
        return 'click';
      }
    },
    pushRollOut(roll) {
      /*if (roll.user_id === this.me.id) {
        this.pushRoll('mine', roll);
      }*/
      try {
        const big = this.historyS.tables.big;
        if (big && this.balS.cryptoToFiat(roll.game_mode, 'USD', roll.wager) >= 50) {
          this.pushRoll('big', roll);
        }
      } catch (e) {}
      this.pushRoll('all', roll);
    },
    pushRoll(table, roll, prop = 'id') {
      if (
        !this.historyS.tables ||
        !this.historyS.tables[table] ||
        !this.historyS.tables[table][0] ||
        this.historyS.tables[table][0].id != roll.id
      ) {
        this.historyS.tables[table] = [
          {
            ...roll,
            class: this.historyS.next[table] % 2 ? 'is-even' : 'bg-secondary-light',
          },
          ...this.historyS.tables[table].slice(0, 50),
        ];
        this.historyS.next[table]++;
      }
    },
    formattedTime(timestamp) {
      const date = new Date(timestamp * 1000);
      let hours = date.getHours();
      let minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours || 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes;
      return hours + ':' + minutes + ' ' + ampm;
    },

    gameNameToGameClass(game, gameId, betId, betUserId) {
      let path = '/casino';
      let gamePath = null;
      switch (game) {
        case 'Mines':
        case 'Baccarat':
        case 'Crash':
        case 'Roulette':
        case 'Slots':
        case 'Blackjack':
        case 'War':
        case 'Plinko':
        case 'Keno':
        case 'Limbo':
          gamePath = '/' + game.toLowerCase();
          break;
        case 'Dicing':
          gamePath = '/dice';
          break;
        case 'Duels':
          return {
            path: this.$route.path,
            query: { tab: 'duels', duelId: gameId },
          };
      }
      if (gamePath && betUserId === this.me.id)
        return path + gamePath + '/history/' + gameId;
      return {
        path: this.$route.path,
        query: { tab: 'history', history: betId },
      };

    },
  },
};
</script>

<style scoped>
.panel {
  border: none;
}

.table {
  border: none;
}

td {
  border: none;
  color: #ffffff;
}

tr {
  height: 48px;
  border: none;
}

th {
  padding: 15px 0 0 0;
  border: none;
}

table {
  transform-style: preserve-3d;
}

tbody {
  overflow: hidden;
}

thead {
  z-index: 0;
}

tbody tr {
  animation-fill-mode: forwards;
  animation-duration: 400ms;
  animation-timing-function: ease-out;
  border-radius: 0.25rem;
}

tbody tr:nth-child(2n) {
  animation-name: even-row;
}

tbody tr:nth-child(2n + 1) {
  animation-name: odd-row;
}

tbody tr:first-child {
  animation-name: first-row;
}

/* tbody tr:last-child td a {
  pointer-events: none;
  cursor: default;
} */

@keyframes first-row {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  60% {
    opacity: 0;
    transform: translateY(-40%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes even-row {
  0% {
    transform: translate(0px, -100%);
  }
}

@keyframes odd-row {
  0% {
    transform: translateY(-100%);
  }
}

@keyframes last-row {
  0% {
    opacity: 1;
    transform: translateY(-100%);
  }

  100% {
    opacity: 0;
    transform: translateY(0%);
  }
}
</style>