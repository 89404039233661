import axios from '@/lib/myAxios';
import { deleteAuthLocalStorage } from "@/lib/utils";

export const postFetch = (path, params, action) => {
  try {
    return axios.post(path, params).catch(({ data, status }) => {
      if ((typeof data.error != 'undefined')  && data.error === 'Unauthenticated.' || status === 401) {
        window.location.href = '/auth?action=login';
        deleteAuthLocalStorage();
      }
      window.swal(
        'Uh-oh!',
        'We encountered an error while ' + action + ':\n' + ((typeof data.error != 'undefined') || data.message),
        'error',
      );
    });
  } catch(error) {
    console.log('issue with post fetch');
    return Promise.resolve(null);
  }
};

export const getFetch = (path, action) => {
  try {
    return axios.get(path).catch(({ data }) => {
      if ( (typeof data.error != 'undefined')    && data.error === 'Unauthenticated.') {
        window.location.href = '/auth?action=login';
        deleteAuthLocalStorage();
      }
      if((typeof data.error != 'undefined') )
      throw new Error(data.error);
   
    });
  } catch(error) {
    console.log('issue with get fetch');
    return Promise.resolve(null);
  }
};
