<template>
   
    <punt-modal size="max-w-[800px] p-4 my-3" spacing="0" show-close="true" :onClose="close">
        <template #title>
          <div class="flex flex-col">
            <span class="font-bold text-lg">Updated your details</span>
          </div>
        </template>
         <template #body>
            <div class="flex  align-center  p-10  max-h-[100vh] max-w-[100vh] rounded-md h-[100%] ">
              <div class="max-w-[500px] m-auto text-white ">
                 <form class="extra_social_field"    @submit.stop.prevent="() => SaveExtrafield()">

                    <label
                      for="username"
                      class="block mt-4 mb-2 text-sm font-medium text-text-dim-color"
                      >Username</label
                    >
                    <div class="relative">
                      <div
                        class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M9 14C6.23858 14 4 16.2386 4 19C4 20.6569 5.34315 22 7 22H17C18.6569 22 20 20.6569 20 19C20 16.2386 17.7614 14 15 14H9Z"
                            fill="white" />
                          <path
                            d="M12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2Z"
                            fill="white" />
                        </svg>
                      </div>
                      <input
                        v-model="form.username"
                        required
                        type="text"
                        id="username"
                        class="input-with-icon focus:ring-accent"
                        placeholder="Your username here" />
                    </div>
                    <p v-if="usernameError" class="ml-0.5 mt-0.5 text-sm text-red-500 opacity-80">
                      {{ usernameError }}
                    </p>
                    
                   
                     
                 

                    <div class="flex flex-row gap-x-3">
                      <div class="flex-initial w-[50%]">
                        <div class="state-out" v-if="form.country != ''">
                          <label
                            for="state"
                            class="block mt-4 mb-2 text-sm font-medium text-text-dim-color">
                            State/Province
                          </label>
                          <select v-model="form.state" required class="input-bet focus:ring-accent">
                            <option value="">Select</option>
                            <option v-for="(item, i) in statelist" v-bind:key="i" v-bind:value="item">
                              {{ item }}
                            </option>
                          </select>
                        </div>
                         <p v-if="stateError" class="ml-0.5 mt-0.5 text-sm text-red-500 opacity-80">
                            {{ stateError }}
                          </p>
                      </div>
                      <div class="flex-initial w-[50%]">
                         

                        <div class="relative"  >
                          <label
                            for="dob"
                            class="block mt-4 mb-2 text-sm font-medium text-text-dim-color">
                            Date of Birth
                          </label>
                          <div class="relative">
                            <input
                            v-model="form.dob"
                            required
                            type="date"
                            id="dob"
                            
                          
                            
                            class="input-bet focus:ring-accent"
                            placeholder="Date of birth" />
                            <div class="calendar-icon">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="#ffffff" d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40L64 64C28.7 64 0 92.7 0 128l0 16 0 48L0 448c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-256 0-48 0-16c0-35.3-28.7-64-64-64l-40 0 0-40c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40L152 64l0-40zM48 192l352 0 0 256c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16l0-256z"/></svg>
                            </div>
                          </div>
                        </div>
                       
                      <p  class="ml-0.5 mt-0.5 text-sm text-red-500 opacity-80">
                        {{ dobError }}
                      </p>
                      </div>
                    </div>
                    <button type="submit" :disabled="loading" @click="SaveExtrafield()" class="btn-bet">
                      {{ loading ? 'Please wait...' : 'Play Now' }}
                    </button>

                 </form>
              </div>
            </div>
         </template>
    </punt-modal>
     
   
 
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { doRecaptcha } from '@/lib/recaptcha';
import Radar from 'radar-sdk-js';
import { fraudAndLocationPassed, proxyLocation } from '@/lib/utils';
import { getState, getUsStatesWithCode } from '@/lib/state';
import Loader from '@/components/Shared/Loader.vue';
import PuntModal from '@/components/Shared/PuntModal.vue';
import { generalStore } from '@/store/pinia/generalStore';
import { authStore } from '@/store/pinia/authStore';
import dateFormat from 'dateformat';  
import axios from '@/lib/myAxios';
 
export default {
  name: 'SocialUserField',
  components: { Loader ,PuntModal},
  setup()
  {
    const generalS = generalStore();
    const authS = authStore();
    return { generalS,authS };
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
  },
  mounted()
  {  

    this.getStateByCountry();
    // this.socialLogin();
  },
   data(){
    return {
      form: {
        username:'',
        state: '',
        dob:null
       
      },
      usernameError: null,
      dobError:null,
      stateError:null,
      statelist: [],
      loading:false
    };
  },
  methods: {
     
    getStateByCountry() {
      this.statelist = getState('us');
    },
    validateDoB() {
       
      const date = new Date(this.form.dob);
      if (!date) return 'This field Required.';
      const age = this.calculateAge(date);
      if (age < 18) {
        return 'You must be at-least 18 years old to proceed';
      }
      return null;
    },
    calculateAge(birthday) {
      // birthday is a date
      let ageDifMs = Date.now() - birthday;
      let ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
    validateRegisterForm() {
    
      this.usernameError = this.onlyNumAndLetters(this.form.username);
      if (this.usernameError) {
        return false;
      }

      

      
  
      if(!this.form.state || this.form.state == '' )
      {
        this.stateError ="State is required";
         return false;
      }
      else
        this.stateError =null;

      this.dobError = this.validateDoB();
   
      if (this.dobError) {
        return false;
      }
     
     
       
      

      return true;
    },  
    onlyNumAndLetters() {
      const username = this.form.username;
      if (!username) return 'This field Required.';
      let res = /^[A-Za-z0-9]*$/.test(username);
      if (!res) {
        return 'Username should only have Letters and Numbers!';
      }
      return null;
    },
    async SaveExtrafield() {
      
      if(this.loading)
        return '';
      if (!this.validateRegisterForm()) return;
       
         
      try{
        this.loading = true;
        const data = await  this.authS.saveSocialfield(this.form);
       
        this.loading = false;
       
        window.swal('', 'Registration Complete!', 'success');
        await this.fetchUser();
        var Router = this.$router;
        setTimeout(() => {
            Router.push('/');
        }, 1500);
        
      } 
      catch(e)
      {
      
        window.swal({
          title: 'Somethign went wrong',
          text: (e.data && e.data.error !=undefined)?e.data.error:'Something wrong please try again ',
          type: 'error',

        });
    
        this.loading = false;

      }
       
      
    },
    close(){
     this.$router.push('/');
    },
    ...mapActions(['performLogin', 'performSocialLogin','fetchUser']),
  },
};
</script>
<style>
input[type="date"]::-webkit-calendar-picker-indicator
{
 opacity:0;
  position:relative;
  z-index:1;
}
.calendar-icon
{
  position:absolute;
  right:10px;
  width:15px;
  top:50%;
  transform:translateY(-50%);
  z-index:0;
  pointer-events: none;
}
</style>