<template>
  <router-view></router-view>
  <div
    class="flex h-auto flex-row justify-center p-0 md:min-h-[auto] md:p-8 main_original_wrapper">
    <div class="w-full lg:max-w-[1200px] max-[767px]:max-w-[400px]">
      <div
        class="flex h-auto flex-col-reverse justify-center md:min-h-[auto] xl:flex-row">
        <!-- Side Menu -->
        <div
          class="h-auto w-full min-w-[300px] bg-side-color px-2 py-2 md:w-full md:rounded-tl-xl md:px-3 md:py-3 xl:w-[300px] max-[767px]:pb-[0.8rem]">
          <!-- Bets -->
          <roulette-bets
            :currentState="status"
            :playerProfit="winning"
            :spinBall="spinBall"
            :updateSelectedChipValue="updateSelectedChipValue" />
        </div>
        <div
          class="col-auto -mt-8 flex flex-grow flex-col items-center rounded-br-lg rounded-tr-lg bg-secondary md:mt-0 md:w-full">
          <div
            class="flex h-full w-full flex-col items-center rounded-br-lg bg-no-repeat bg-cover bg-center">
            <!-- <span>Game Here</span> -->
            <div class="is-hidden-small relative flex w-[600px] flex-col">
              <div class="top-container flex">
                <div
                  class="winning-wrap hidden min-w-[90px] flex-col items-center justify-center md:flex">
                  <button
                    v-if="showWinningNumber"
                    :class="{
                      'bg-[#1EE563]':
                        fetchSlotColor(finalPositionOfBall) === RouletteColors.GREEN,
                      'bg-secondary-light':
                        fetchSlotColor(finalPositionOfBall) === RouletteColors.BLACK,
                      'bg-[#FF243E]':
                        fetchSlotColor(finalPositionOfBall) === RouletteColors.RED,
                    }"
                    class="winning-number rounded-lg p-4">
                    <span>{{ slotNumberText(finalPositionOfBall) }}</span>
                  </button>
                  <span v-else class="winning-number"></span>

                  <div class="flex hidden flex-col">
                    <span
                      class="my-1 rounded-full p-1 text-center"
                      v-for="(v, $index) in showedLatestNumbers"
                      :key="$index"
                      :class="{
                        'bg-[#1EE563]': fetchSlotColor(v) === RouletteColors.GREEN,
                        'bg-secondary-light': fetchSlotColor(v) === RouletteColors.BLACK,
                        'bg-[#FF243E]': fetchSlotColor(v) === RouletteColors.RED,
                      }"
                      :style="`order: ${-$index}; opacity :  ${
                        (6 - showedLatestNumbers.length + 1 + index) * 0.2
                      };`"
                      >{{ slotNumberText(v) }}
                    </span>
                  </div>
                </div>
                <div class="m-auto flex flex-grow items-center justify-center px-4 pt-16">
                  <RouletteWheel
                    :final-slot="finalPositionOfBallOnTheWheel"
                    :theme="''"
                    @afterBallStopped="onBallStopped"
                    @beforeBallDrop="onBallDrop" />
                </div>
                <div
                  class="winning-wrap hidden min-w-[90px] flex-col items-center justify-between space-y-4 p-4 md:flex">
                  <div class="flex flex-col">
                    <span
                      class="my-1 flex h-8 w-8 items-center justify-center rounded-full text-center text-sm font-bold"
                      v-for="(v, $index) in recentBets"
                      :key="$index"
                      :class="{
                        'bg-[#1EE563]': fetchSlotColor(v) === RouletteColors.GREEN,
                        'bg-secondary-light': fetchSlotColor(v) === RouletteColors.BLACK,
                        'bg-[#FF243E]': fetchSlotColor(v) === RouletteColors.RED,
                      }"
                      :style="`opacity :  ${1 - $index * 0.2};`">
                      {{ slotNumberText(v) }}
                    </span>
                  </div>
                </div>
              </div>

              <RouletteTable
                :locked="!bettingEnabled"
                :selected="selectedChip"
                class="scale-80 mb-4 pb-7"
                @placingChips="onChipsPlacedOrRemoved"
                @removingChips="onChipsPlacedOrRemoved" />

              <div
                v-if="!removingBets"
                class="absolute bottom-0 left-0 z-20 hidden items-center gap-2 p-3 md:flex">
                <button
                  :disabled="!bettingEnabled"
                  class="btn-multiplier w-10 rounded-full px-3 py-2"
                  v-on:click.prevent="undoBets">
                  <img alt="" src="../../assets/svg/roulette/undo.svg" />
                </button>
                <span class="text-white">Undo</span>
              </div>
              <div
                v-if="!removingBets"
                class="absolute bottom-0 right-0 z-20 flex items-center gap-2 p-3">
                <span class="text-white">Clear</span>
                <button
                  :disabled="!bettingEnabled"
                  class="btn-multiplier w-10 rounded-full px-3.5 py-1.5"
                  v-on:click="onClearBets">
                  <img alt="" src="../../assets/svg/roulette/delete.svg" />
                </button>
              </div>
            </div>
            <div class="is-hidden-other flex flex-col items-center">
              <div class="top-container">
                <div v-if="!bettingEnabled" class="mx-4 py-20">
                  <RouletteWheel
                    :final-slot="finalPositionOfBallOnTheWheel"
                    :theme="''"
                    @afterBallStopped="onBallStopped"
                    @beforeBallDrop="onBallDrop" />
                </div>

                <div v-else style="margin: auto">
                  <div class="flex flex-row items-center justify-center">
                    <!-- Mobile -->
                    <div class="relative flex flex-col items-center">
                      <RouletteTableMobile
                        :locked="!bettingEnabled"
                        :selected="selectedChip"
                        class="scale-80"
                        @placingChips="onChipsPlacedOrRemoved"
                        @removingChips="onChipsPlacedOrRemoved">
                      </RouletteTableMobile>

                      <div
                        class="max-[767px]:flex max-[767px]:justify-between max-[767px]:w-full">
                        <div
                          v-if="!removingBets"
                          class="md:absolute md:bottom-0 z-20 flex items-center gap-2 pb-2"
                          :class="showWinningNumber ? '-left-12' : '-left-16'">
                          <button
                            :disabled="!bettingEnabled"
                            class="h-8 w-10 items-center rounded-2xl bg-secondary-light px-2 py-1 hover:bg-[#335B70]"
                            v-on:click="undoBets">
                            <img alt="" src="../../assets/svg/roulette/undo.svg" />
                          </button>
                          <span class="hidden text-white">Undo</span>
                        </div>
                        <div
                          v-if="!removingBets"
                          class="md:absolute md:bottom-0 z-20 flex items-center gap-2 pb-2"
                          :class="showWinningNumber ? '-right-24' : '-right-16'">
                          <span class="hidden text-white">Clear</span>
                          <button
                            :disabled="!bettingEnabled"
                            class="h-8 w-10 rounded-2xl bg-secondary-light px-2 py-1 pl-3 hover:bg-[#335B70]"
                            v-on:click="onClearBets">
                            <img alt="" src="../../assets/svg/roulette/delete.svg" />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div class="ml-4 flex flex-col">
                      <div class="winning-wrap flex flex-col">
                        <span
                          v-if="showWinningNumber"
                          :class="{
                            'bg-[#1EE563]':
                              fetchSlotColor(finalPositionOfBall) ===
                              RouletteColors.GREEN,
                            'bg-secondary-light':
                              fetchSlotColor(finalPositionOfBall) ===
                              RouletteColors.BLACK,
                            'bg-[#FF243E]':
                              fetchSlotColor(finalPositionOfBall) === RouletteColors.RED,
                          }"
                          class="winning-number p-2"
                          >{{ slotNumberText(finalPositionOfBall) }}</span
                        >
                        <span v-else class="winning-number"></span>

                        <div class="flex flex-col">
                          <span
                            v-for="(v, $index) in showedLatestNumbers"
                            :key="$index"
                            :class="{
                              'bg-[#1EE563]': fetchSlotColor(v) === RouletteColors.GREEN,
                              'bg-secondary-light':
                                fetchSlotColor(v) === RouletteColors.BLACK,
                              'bg-[#FF243E]': fetchSlotColor(v) === RouletteColors.RED,
                            }"
                            :style="{ order: -$index }"
                            class="p-2"
                            >{{ slotNumberText(v) }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <game-actions
          :game-data="{ name: 'Roulette', id: null }"
          :pre-extras="gameActions"
          class="rounded-b-xl bg-secondary" />

      <game-details
        game="Roulette"
        edge="5.26"
        provider="Chanced Originals"
        class="mt-6 md:mt-12" />
    </div>
  </div>
  <!-- <span class="bg-slate-500 w-full">Game History Table Here</span> -->
  <game-history-table class="hidden md:flex" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { takeRight } from 'lodash';
import { defaultConvertor } from '@/lib/rsgp';

import ProvablyFairCard from './ProvablyFairCard.vue';

import RouletteWheel from './RouletteWheel.vue';
import RouletteTable from './RouletteTable.vue';
import RouletteTableMobile from './RouletteTableMobile.vue';
import BetPerChipInput from './BetPerChipInput.vue';
import GameActions from '../Shared/GameActions.vue';
import GameDetails from '../Shared/GameDetails.vue';
import RouletteBets from './RouletteBets.vue';

import { GetSlotColor, RouletteColors } from './RouletteLayouts.js';

import {
  betSound,
  bouncingSound,
  placeSound,
  spinningSound,
  winSound,
  looseSound,
} from './sounds';

import GameHistoryTable from '../GameHistory/BetsHistory.vue';
import { log } from 'debug/src/browser';

export default {
  name: 'Roulette',
  components: {
    RouletteWheel,
    RouletteTable,
    RouletteTableMobile,
    BetPerChipInput,
    ProvablyFairCard,
    GameHistoryTable,
    GameActions,
    RouletteBets,
    GameDetails,
  },
  data: () => ({
    theme: 'dark',
    showWinningNumber: false,
    winningNumbers: [],
    finalPositionOfBallOnTheWheel: -1,
    finalPositionOfBall: -1,
    status: 'notStarted',
    page: 1,
    pageLength: 10,
    tab: 'all',
    gameActions: [
      ['/casino/roulette/rules', 'Rules'],
      // ['/casino/roulette/fairness', 'Fairness'],
    ],
    tables: {
      mine: [],
      all: [],
      big: [],
    },
    selectedChip: 10000,
    profit: 0,
  }),
  computed: {
    RouletteColors: () => RouletteColors,
    latestNumbers() {
      return takeRight(this.winningNumbers, 5);
    },
    showedLatestNumbers() {
      //    v-if="showWinningNumber? $index < winningNumbers.length - 1 : true"
      const ret = [];
      for (let i = 0; i < this.latestNumbers.length; ++i) {
        if (!this.showWinningNumber || i < this.latestNumbers.length - 1) {
          ret.push(this.latestNumbers[i]);
        }
      }
      return ret;
    },
    recentBets() {
      return this.showedLatestNumbers.reverse();
    },
    ...mapGetters(['me', 'userBalance', 'clientSeed']),
    ...mapGetters('Roulette', [
      'bettingEnabled',
      'allowPlacingBets',
      'removingBets',
      'totalChips',
      'totalBets',
      'animatingBall',
    ]),
    ...mapGetters('Sound', ['isSoundMuted']),
  },
  methods: {
    ...mapActions('Roulette', [
      'enablePlacingBets',
      'enableRemovingBets',
      'placingBets',
      'clearBets',
      'animateBall',
      'enableBetting',
      'disableBetting',
      'betsPerChip',
      'executePlay',
      'backupBets',
      'restoreBets',
      'undoBets',
    ]),
    ...mapActions(['placeBet']),
    ...mapActions('Sound', ['toggleSound']),
    rsgp(x) {
      return defaultConvertor.format(x);
    },
    fetchSlotColor(i) {
      return GetSlotColor(i);
    },
    slotNumberText(i) {
      return i > 36 ? '00' : `${i}`;
    },
    onClearBets() {
      this.clearBets();
      this.playSound(placeSound);
    },
    spinBall() {
      this.profit = 0;
      this.showWinningNumber = false;
      this.playSound(betSound);
      this.status = 'betting';

      try
      {
        ///dim the bet table
        this.executePlay().then((res) => {
              
              if(res.data.result)
              {
                this.status = 'spinning';
                this.finalPositionOfBall = res.data.result;
                this.finalPositionOfBallOnTheWheel = res.data.result;

                this.profit = res.data.profit;
              }
              //hide the table
              //show the wheel
            }).catch((res) => {
              console.log('There are some issue')
            });
      } catch(error) {
        console.log('issue while play');
      }
    },
    onResult(result) {
      this.finalPositionOfBall = result;
      this.finalPositionOfBallOnTheWheel = result;
    },
    onBallDrop() {
      this.animateBall();
      this.playSound(spinningSound);

      setTimeout(() => {
        this.playSound(bouncingSound);
      }, 1300);
    },
    onBallStopped() {
      this.status = 'notStarted';

      setTimeout(() => this.enableBetting(), 500);
      this.showWinningNumber = true;

      this.winningNumbers.push(this.finalPositionOfBall);
      this.backupBets();
      //this.clearBets()

      this.playSound(this.profit > 0 ? winSound : looseSound);

      this.finalPositionOfBallOnTheWheel = -1;
    },
    updateBetsPerChip(e) {
      this.betsPerChip(e.value);
    },
    onChipsPlacedOrRemoved() {
      this.playSound(placeSound);
    },
    playSound(sound) {
      if (!this.isSoundMuted) {
        sound.play();
      }
    },
    // new chip selection
    onChipSelected(chip) {
      this.selectedChip = chip;
    },
    updateSelectedChipValue(value) {
      this.selectedChip = value;
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 1249px) {
  .is-hidden-small {
    display: none !important;
  }
}

@media screen and (min-width: 1250px) {
  .is-hidden-other {
    display: none !important;
  }

  ul {
    max-height: 10rem;
  }
}

@media (max-width: 768px) {
}

.rbets {
  background-color: rgb(38, 55, 66);
  padding-bottom: 20px;
  padding-top: 3rem;
}

.rcanvas {
  background-color: transparent;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-top: 3rem;
  margin-right: 0px;
}

.roulette .winning-wrap {
  flex-flow: row nowrap;
}

.bac-chips-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  max-width: 100%;
  width: 100%;
}

.bac-chips-wrap-content {
  display: flex;
  border: 2px solid #375262;
  align-items: center;
  font-weight: 600;
  gap: 3px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.bac-chips-wrap-content-slider {
  display: flex;
  align-items: center;
  padding: 0.5em;
}
</style>

<style lang="scss" scoped>
@import './variables';

.roulette {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  position: relative;
  padding: 0;
  margin: 0;

  background: #192c38;
  border-radius: 0.5rem;

  .btn-table {
    background: none;
    border: none;

    z-index: 2;

    color: #ffffff;
    font-size: 1rem;
    outline: none;

    cursor: pointer;

    i {
      line-height: 1.5rem;
      margin-right: 0.25rem;
    }
  }

  .stat-flex {
    display: flex;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    position: relative;
  }

  .stat-grid {
    flex-grow: 1;
    align-self: center;
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto auto auto;
    grid-column-gap: 0.5rem;
    grid-row-gap: 1rem;

    margin-left: 1rem;

    .span-4 {
      grid-column: span 4;
    }

    .span-2 {
      grid-column: span 2;
    }

    .bet-group {
      .label {
        display: inline-block;
      }
    }
  }

  .top-container {
    display: flex;
    justify-content: flex-start;
    align-self: stretch;

    position: relative;

    .fairness-hint {
      position: absolute;
      top: -1.5rem;
      right: 1rem;
    }
  }

  .winning-wrap {
    min-width: 2rem;
    max-width: 8rem;

    padding: 2rem 2rem 2rem 0;

    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;

    .winning-number {
      align-self: center;

      font-size: 2rem;
      line-height: 2.5rem;

      padding: 0.5rem;

      text-align: center;
      vertical-align: middle;
      color: #ffffff;

      border-radius: 0.5rem;

      min-width: 3.5rem;
      max-width: 3.5rem;
      min-height: 3.5rem;
      max-height: 3.5rem;
    }

    ul {
      display: flex;
      flex-flow: column wrap;
      justify-self: stretch;
      justify-content: flex-end;
      align-items: center;
      flex: 1 0 auto;

      li {
        width: 1.75rem;
        height: 1.75rem;

        margin: 0.25rem;
        border-radius: 50%;

        text-align: center;
        vertical-align: middle;

        color: #ffffff;
        line-height: 1.75rem;
      }
    }

    .slot-green {
      background-color: #1ee563;
    }

    .slot-red {
      background-color: #ff243e;
    }

    .slot-black {
      background-color: #1b323a;
    }
  }
}

.roulette {
  background: transparent;
  border: none;

  .total-wager {
    background-color: #1b323a !important;
    box-shadow: 0 0.15rem 0 0 darken(#1b323a, 5%) !important;
  }

  .winning-wrap {
    .slot-black {
      background-color: #1b323a;
    }
  }
}

.table-read {
  display: inline-block;
  color: #ffffff;
  font-size: 1.25rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.align-center {
  align-self: center;
}
</style>

<style lang="scss">
@import './variables';
@import url(https://fonts.googleapis.com/css?family=Merriweather);

@mixin button-theme-color($color) {
  background: lighten($color, 5%);
  border: 0.5px solid darken($color, 5%);
  box-shadow:
    0 0.25rem 0 0 darken($color, 5%),
    0 0.26rem 0 0 lighten($color, 80%);

  &:active,
  &.active {
    background: lighten($color, 12%);
    box-shadow:
      0 0.05rem 0 0 darken($color, 5%),
      0 0.08rem 0 0 lighten($color, 80%),
      inset 0 0.05rem 0 0 darken($color, 15%);
    transform: translateY(0.15rem);
  }

  &:hover {
    background: lighten($color, 10%);
  }
}

.roulette {
  button {
    outline: none !important;
  }

  .btn {
    @include button-theme-color(#076324);

    border-radius: 0.25rem;

    padding: 0.5rem 1rem;

    color: #ffffff;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-transform: uppercase;

    cursor: pointer;

    transition: all 150ms ease-in;

    &.btn-round {
      width: 3.5rem;
      height: 3.5rem;
      text-align: center;
      border-radius: 50%;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      padding: 0;
    }

    i {
      vertical-align: baseline;
      margin: 0 0.15rem;
    }
  }

  .btn-group {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    .btn {
      border-radius: 0;

      &:first-of-type {
        border-radius: 0.25rem 0 0 0.25rem;
      }

      &:last-of-type {
        border-radius: 0 0.25rem 0.25rem 0;
      }
    }
  }
}

.theme-dark {
  .btn {
    @include button-theme-color(#1ee563);
  }
}
</style>
