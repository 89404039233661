<template>
  <div
    class="w-full border-t-[1px] border-secondary-light bg-secondary p-1 md:p-2 game_actions">
    <!-- <span>Action Icons Here</span> -->
    <div class="flex flex-row items-center">
      <div class="flex flex-grow flex-row px-2 gap-2 text-sm">
        <router-link
          v-for="item in preExtras"
          :to="item[0]"
          class="z-20 text-slate-300 hover:opacity-70"
          v-bind:key="item.i">
          {{ item[1] }}
        </router-link>
        <!-- <fiat-dropdown
          v-if="$route.name === 'Relax'"
          :show-balance-label="false"
        /> -->
      </div>
      <div class="flex gap-2 px-2 md:gap-4 md:px-4">
        <button
          v-if="$route.name !== 'Relax' && $route.name !== 'LaterGator'  && $route.name !== 'CloverCross'"
          class="z-20 hover:opacity-70"
          v-on:click="toggleSound">
          <svg
            v-if="isSoundMuted"
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor">
            <path
              fill-rule="evenodd"
              fill="#415C70"
              d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM12.293 7.293a1 1 0 011.414 0L15 8.586l1.293-1.293a1 1 0 111.414 1.414L16.414 10l1.293 1.293a1 1 0 01-1.414 1.414L15 11.414l-1.293 1.293a1 1 0 01-1.414-1.414L13.586 10l-1.293-1.293a1 1 0 010-1.414z"
              clip-rule="evenodd" />
          </svg>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor">
            <path
              fill-rule="evenodd"
              fill="#415C70"
              d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM14.657 2.929a1 1 0 011.414 0A9.972 9.972 0 0119 10a9.972 9.972 0 01-2.929 7.071 1 1 0 01-1.414-1.414A7.971 7.971 0 0017 10c0-2.21-.894-4.208-2.343-5.657a1 1 0 010-1.414zm-2.829 2.828a1 1 0 011.415 0A5.983 5.983 0 0115 10a5.984 5.984 0 01-1.757 4.243 1 1 0 01-1.415-1.415A3.984 3.984 0 0013 10a3.983 3.983 0 00-1.172-2.828 1 1 0 010-1.415z"
              clip-rule="evenodd" />
          </svg>
        </button>

        <button
          :disabled="loading"
          v-if="status || loading"
          v-on:click="toggleFavourites"
          class="text-[#415C70] hover:opacity-70 disabled:cursor-not-allowed">
          <img
            v-if="status === 'created'"
            class="h-5 w-5 gold_star_img"
            src="../../assets/svg/mines/icon-star.svg" />
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
          </svg>
        </button>

        <button
          v-if="documentId"
          v-on:click="toggleTheatreMode"
          class="text-[#415C70] hover:opacity-70 disabled:cursor-not-allowed">
          <svg
            class="h-6 w-5"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid meet">
            <path
              d="M8.5 22.5A3.5 3.5 0 0 0 5 26v48a3.5 3.5 0 0 0 3.5 3.5h83A3.5 3.5 0 0 0 95 74V26a3.5 3.5 0 0 0-3.5-3.5h-83zm3.5 7h76v41H12v-41z"
              fill="currentColor"></path>
          </svg>
        </button>

        <button
          v-if="documentId"
          v-on:click="fullScreen"
          class="text-[#415C70] hover:opacity-70 disabled:cursor-not-allowed">
          <svg
            class="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            xml:space="preserve">
            <path
              fill="#415C70"
              d="M5,21H1c-0.6,0-1-0.4-1-1v-4c0-0.6,0.4-1,1-1s1,0.4,1,1v3h3c0.6,0,1,0.4,1,1S5.6,21,5,21z" />
            <path
              fill="#415C70"
              d="M23,21h-4c-0.6,0-1-0.4-1-1s0.4-1,1-1h3v-3c0-0.6,0.4-1,1-1s1,0.4,1,1v4C24,20.6,23.6,21,23,21z" />
            <path
              fill="#415C70"
              d="M23,9c-0.6,0-1-0.4-1-1V5h-3c-0.6,0-1-0.4-1-1s0.4-1,1-1h4c0.6,0,1,0.4,1,1v4C24,8.6,23.6,9,23,9z" />
            <path
              fill="#415C70"
              d="M1,9C0.4,9,0,8.6,0,8V4c0-0.6,0.4-1,1-1h4c0.6,0,1,0.4,1,1S5.6,5,5,5H2v3C2,8.6,1.6,9,1,9z" />
          </svg>
        </button>

        <!-- <button v-on:click="onSettingsClick">
          <img class="px-2" src="../../assets/svg/mines/icon-settings.svg" />
        </button>
        <button v-on:click="onRecClick">
          <img class="px-2" src="../../assets/svg/mines/icon-rec.svg" />
        </button>
        <button v-on:click="onBarsClick">
          <img class="px-2" src="../../assets/svg/mines/icon-bars.svg" />
        </button>
        <button v-on:click="onShieldClick">
          <img class="px-2" src="../../assets/svg/mines/icon-shield.svg" />
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FiatDropdown from '@/components/Bank/FiatDropdown.vue';
// import { softswissStore } from "@/store/pinia/softswissStore";
import { relaxStore } from '@/store/pinia/relaxStore';
import { openFullscreen } from '@/lib/utils';
import { generalStore } from '@/store/pinia/generalStore';

export default {
  components: {
    FiatDropdown,
  },
  props: {
    onSettingsClick: {
      type: Function,
      // required: true,
      default: () => {},
    },
    onStarClick: {
      type: Function,
      //required: true,
      default: () => {},
    },
    onRecClick: {
      type: Function,
      //required: true,
      default: () => {},
    },
    onBarsClick: {
      type: Function,
      //required: true,
      default: () => {},
    },
    onShieldClick: {
      type: Function,
      //required: true,
      default: () => {},
    },
    preExtras: {
      type: Array,
      default: [],
    },
    documentId: {
      default: null,
    },
    gameData: {
      default: { name: null, id: null },
    },
  },
  setup() {
    // const softS = softswissStore();
    const relaxS = relaxStore();
    const generalS = generalStore();
    return { relaxS, generalS };
  },
  computed: {
    ...mapGetters('Sound', ['isSoundMuted']),
  },
  methods: {
    ...mapActions('Sound', ['toggleSound']),
    fullScreen() {
      if (this.documentId) {
        openFullscreen(this.documentId);
      }
    },
    toggleTheatreMode() {
      this.generalS.isTheatreMode = !this.generalS.isTheatreMode;
    },
    async checkFavourites() {
      this.status = null;
      if (this.gameData && (this.gameData.name || this.gameData.id)) {
        try {
          this.loading = true;
          // const { data } = await this.softS.checkFavourite(this.gameData);
          const { data } = await this.relaxS.checkFavourite(this.gameData);
          this.status = data.action;
        } catch (e) {
        } finally {
          this.loading = false;
        }
      }
    },
    async toggleFavourites() {
      this.status = null;
      if (this.gameData && (this.gameData.name || this.gameData.id)) {
        try {
          this.loading = true;
          // const { data } = await this.softS.toggleFavourite(this.gameData);
          const { data } = await this.relaxS.toggleFavourite(this.gameData);
          this.status = data.action;
        } catch (e) {
        } finally {
          this.loading = false;
        }
      }
    },
  },
  data() {
    return { status: null, loading: false };
  },
  watch: {
    gameData: {
      async handler() {
        await this.checkFavourites();
      },
      // force eager callback execution
      immediate: true,
    },
  },
};
</script>
