<template>
  <div class="flex flex-row md:w-[auto] w-full">
    <TabGroup
      :selectedIndex="selectedTab"
      @change="setActiveTab"
      as="div"
      class="flex flex-col-reverse md:flex-col w-full max-w-[650px]">
      <div class="flex flex-col w-full justify-start">
        <TabList
          class="flex flex-row mt-2 px-2 md:mt-0 w-full md:w-auto space-x-1 sm:space-x-2 bg-primary rounded-xl">
          <Tab
            :disabled="autoBet"
            v-for="tab in tabs"
            :key="tab"
            v-slot="{ selected }"
            as="template">
            <button
              :disabled="autoBet"
              :class="[
                selected ? 'bg-secondary-light text-white' : 'bg-primary text-slate-600',
                tab === '' ? 'flex-1 border-b-0 pointer-events-none' : '',
              ]"
              class="text-white focus:outline-none flex-1 font-medium rounded-lg py-2 my-2 bg-secondary hover:bg-opacity-70 disabled:opacity-80">
              {{ tab }}
            </button>
          </Tab>
        </TabList>
      </div>
      <TabPanels class="mt-1">
        <TabPanel key="1">
          <div class="flex flex-col-reverse md:flex-col h-full">
            <div class="mt-3">
              <span class="flex h-[1px] opacity-50 bg-line-color-bright" />
            </div>
            <div class="flex flex-col h-full justify-between">
              <div class="grow">
                <div class="flex flex-row gap-4">
                  <div class="flex-grow">
                    <label
                      class="mt-1 flex justify-between mb-1 text-sm font-medium text-text-dim-color">
                      Bet Amount
                    </label>
                    <div class="relative">
                      <div
                        class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <img class="h-4 w-4" :src="balS.mainIcon" alt />
                      </div>
                      <input
                        id="bet-manual-amount"
                        v-model="wager"
                        class="input-bet-amount"
                        placeholder="0.0000"
                        type="number" />
                      <div
                        class="flex absolute inset-y-0 right-0 items-center pr-2 space-x-2 pointer-events-auto">
                        <button
                          :disabled="autoBet"
                          class="btn-multiplier"
                          type="button"
                          v-on:click="scaleWager(0.5)">
                          ½
                        </button>
                        <button
                          :disabled="autoBet"
                          class="btn-multiplier"
                          type="button"
                          v-on:click="scaleWager(2.0)">
                          2x
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="flex-1 block md:hidden">
                    <button
                      :disabled="
                        buttonDisabled ||
                        numberOfSelected <= 0 ||
                        currentState === 'inProgress' ||
                        currentState === 'picking'
                      "
                      class="btn-bet !mt-0 h-full"
                      v-on:click="initAutoBet(1)">
                      Play
                    </button>
                  </div>
                </div>

                <label
                  class="mt-1 block MBX text-sm font-medium text-text-dim-color"
                  for="mines"
                  >Risk</label
                >
                <select v-model="pRisk" :disabled="autoBet" class="input-bet-select">
                  <option
                    v-for="i in ['Classic', 'Low', 'Medium', 'High']"
                    v-bind:key="i">
                    {{ i }}
                  </option>
                </select>
              </div>
              <div class="flex-none mt-2">
                <div class="flex gap-2">
                  <button class="btn-neutral" type="button" v-on:click="autoPick()">
                    Auto Pick
                  </button>
                  <button class="btn-neutral" type="button" v-on:click="clearTable()">
                    Clear Table
                  </button>
                </div>
              </div>
            </div>
            <div class="md:block hidden">
              <button
                :disabled="
                  buttonDisabled ||
                  numberOfSelected <= 0 ||
                  currentState === 'inProgress' ||
                  currentState === 'picking'
                "
                class="btn-bet"
                v-on:click="initAutoBet(1)">
                Play
              </button>
            </div>
          </div>
        </TabPanel>
        <TabPanel key="2">
          <div class="flex flex-col-reverse h-full md:flex-col">
            <div class="mt-2 md:mt-1">
              <span class="flex h-[1px] opacity-50 bg-line-color-bright" />
            </div>
            <div class="flex flex-col h-full">
              <div class="flex-none">
                <label
                  class="mt-1 flex justify-between mb-1 text-sm font-medium text-text-dim-color">
                  Bet Amount
                </label>
                <div class="relative">
                  <div
                    class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <img class="h-4 w-4" :src="balS.mainIcon" alt />
                  </div>
                  <input
                    id="bet-manual-amount"
                    v-model="wager"
                    :disabled="autoBet"
                    class="input-bet-amount"
                    placeholder="0.0000"
                    type="number" />
                  <div
                    class="flex absolute inset-y-0 right-0 items-center pr-2 space-x-2 pointer-events-auto">
                    <button
                      :disabled="autoBet"
                      class="btn-multiplier"
                      type="button"
                      v-on:click="scaleWager(0.5)">
                      ½
                    </button>
                    <button
                      :disabled="autoBet"
                      class="btn-multiplier"
                      type="button"
                      v-on:click="scaleWager(2.0)">
                      2x
                    </button>
                  </div>
                </div>
              </div>

              <label
                class="mt-1 block MBX text-sm font-medium text-text-dim-color"
                for="risk-auto"
                >Risk</label
              >
              <select
                id="risk-auto"
                v-model="pRisk"
                :disabled="autoBet"
                class="input-bet-select">
                <option v-for="i in ['Classic', 'Low', 'Medium', 'High']" v-bind:key="i">
                  {{ i }}
                </option>
              </select>
              <div class="flex-1">
                <div class="py-0.5 md:py-1">
                  <label class="mt-1 block MBX text-sm font-medium text-text-dim-color"
                    >Number of Plays</label
                  >
                  <div class="relative">
                    <input
                      v-model="numOfBets"
                      :disabled="autoBet"
                      class="input-bet"
                      placeholder="Number of Plays"
                      type="text" />
                  </div>
                </div>
                <div class="py-0.5 md:py-1">
                  <label class="mt-1 block MBX text-sm font-medium text-text-dim-color"
                    >On Win</label
                  >
                  <div class="flex flex-row w-full bg-primary p-0.5 space-x-1 rounded">
                    <button
                      :class="[
                        onWinSelected === 'Reset' ? 'bg-secondary ' : 'bg-secondary/0',
                      ]"
                      :disabled="inProgress"
                      class="px-2 text-sm text-slate-300 shadow rounded hover:opacity-70"
                      v-on:click="setOnWin('Reset')">
                      Reset
                    </button>
                    <button
                      :class="[
                        onWinSelected !== 'Reset' ? 'bg-secondary ' : 'bg-secondary/0',
                      ]"
                      :disabled="inProgress"
                      class="px-2 shadow rounded text-sm text-slate-300 hover:opacity-70"
                      v-on:click="setOnWin('Inc')">
                      IncreaseBy:
                    </button>
                    <div class="relative flex-1 rounded-xl">
                      <input
                        v-model="onWinPercent"
                        :disabled="inProgress || onWinSelected === 'Reset'"
                        class="input-bet-winloss"
                        max="1000000"
                        min="0"
                        type="text" />
                      <div
                        class="absolute inset-y-0 right-0 flex items-center rounded-r-md m-0.5 box-context">
                        <button class="btn-winloss">%</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="py-0.5 md:py-1">
                  <label class="mt-1 block MBX text-sm font-medium text-text-dim-color"
                    >On Loss</label
                  >
                  <div class="flex flex-row w-full bg-primary p-0.5 space-x-1 rounded">
                    <button
                      :class="[
                        onLossSelected === 'Reset' ? 'bg-secondary ' : 'bg-secondary/0',
                      ]"
                      :disabled="autoBet"
                      class="px-2 text-slate-300 shadow rounded text-sm hover:opacity-70"
                      v-on:click="setOnLoss('Reset')">
                      Reset
                    </button>
                    <button
                      :class="[
                        onLossSelected !== 'Reset' ? 'bg-secondary ' : 'bg-secondary/0',
                      ]"
                      :disabled="autoBet"
                      class="px-2 shadow rounded text-slate-300 text-sm hover:opacity-70"
                      v-on:click="setOnLoss('Inc')">
                      IncreaseBy:
                    </button>
                    <div class="relative flex-1">
                      <input
                        v-model="onLossPercent"
                        :disabled="autoBet || onLossSelected === 'Reset'"
                        class="input-bet-winloss"
                        max="1000000"
                        min="0"
                        type="text" />
                      <div
                        class="absolute inset-y-0 right-0 flex items-center rounded-r-md m-0.5 box-context">
                        <button class="btn-winloss">%</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="py-0.5 md:py-1">
                  <label class="mt-1 block MBX text-sm font-medium text-text-dim-color"
                    >Stop On Profit</label
                  >
                  <div class="relative">
                    <input
                      v-model="stopOnProfit"
                      :disabled="autoBet"
                      class="input-bet"
                      placeholder="Stop On Profit"
                      type="text" />
                  </div>
                </div>
                <div class="py-0.5 md:py-1">
                  <label class="mt-1 block MBX text-sm font-medium text-text-dim-color"
                    >Stop On Loss</label
                  >
                  <div class="relative">
                    <input
                      v-model="stopOnLoss"
                      :disabled="autoBet"
                      class="input-bet"
                      placeholder="Stop On Loss"
                      type="text" />
                  </div>
                </div>
                <div class="mt-1">
                  <span class="mt-1 text-slate-300 inline">
                    Profit: {{ balS.exchangeAndFormatAmount(profit, currency) }}
                    <img class="h-4 w-4 inline" :src="balS.mainIcon" alt />
                  </span>
                </div>
              </div>
            </div>
            <div class="flex-none">
              <button
                :disabled="
                  numberOfSelected === 0 ||
                  buttonDisabled ||
                  !numOfBets ||
                  Number.parseInt(numOfBets) <= 0 ||
                  (currentState === 'inProgress' && !this.autoBet)
                "
                class="btn-bet"
                type="button"
                v-on:click="initAutoBet()">
                {{
                  currentState === 'inProgress' && autoBet
                    ? 'Stop Autoplay'
                    : 'Start Autoplay'
                }}
              </button>
            </div>
          </div>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { defaultConvertor } from '../../lib/rsgp';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue';
import { ref } from 'vue';
import { balanceStore } from '@/store/pinia/balanceStore';
import { getBalanceIcon } from '@/lib/icons';
import { BigNumber } from 'bignumber.js';

const TABS = ['Manual', 'Auto'];

export default {
  name: 'KenoBets',
  components: {
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
  },
  props: {
    currentState: {},
    playerProfit: {
      default: 0,
    },
    gameId: {
      default: 0,
    },
    playHandler: {},
    riskHandler: {},
    autoPick: {},
    clearTable: {},
    numberOfSelected: {},
  },
  data() {
    return {
      wager: 0.01,
      selectedTab: 0,
      active: 'Manual',
      numOfBets: 0,
      autoBet: false,
      autoBetAmount: 0,
      onWinPercent: 0.0,
      onLossPercent: 0.0,
      onWinSelected: 'Reset',
      onLossSelected: 'Reset',
      stopOnLoss: '0',
      stopOnProfit: '0',
      profit: 0,
      buttonDisabled: false,
      pRisk: 'Classic',
      currency: 'BTC',
    };
  },
  setup() {
    const balS = balanceStore();
    balS.init();
    return { tabs: TABS, balS };
  },
  mounted() {
    this.scaleWager(1);
  },
  deactivated() {
    this.autoBet = false;
  },
  watch: {
    pRisk: {
      handler: function (newRisk, oldRisk) {
        this.riskHandler(newRisk.toLowerCase());
      },
      immediate: true,
    },
    currentState: function (newState, oldState) {
      this.buttonDisabled = false;
      if (this.autoBet) {
        if (newState === 'finished' || newState === 'failed') {
          if (this.active === 'Manual' || newState === 'failed') {
            this.autoBet = false;
            return;
          }
          if (this.numOfBets <= 0) {
            this.numOfBets = 0;
            this.autoBet = false;
          }

          this.profit += this.playerProfit - this.betAmount;
          if (this.playerWonBet) {
            if (this.onWinSelected === 'Reset') {
              this.wager = this.rsgp(this.autoBetAmount);
            } else {
              this.scaleWager(1 + Number.parseFloat(this.onWinPercent) / 100.0);
            }
          } else if (this.playerLostBet) {
            if (this.onLossSelected === 'Reset') {
              this.wager = this.rsgp(this.autoBetAmount);
            } else {
              this.scaleWager(1 + Number.parseFloat(this.onLossPercent) / 100.0);
            }
          }
          if (this.stopOnProfitAmount !== 0 && this.profit >= this.stopOnProfitAmount) {
            this.autoBet = false;
          } else if (
            this.stopOnLossAmount !== 0 &&
            this.profit <= -this.stopOnLossAmount
          ) {
            this.autoBet = false;
          }

          if (this.autoBet && this.numOfBets > 0) {
            this.bet(this.betAmount);
          } else {
            this.autoBet = false;
            this.numOfBets = 0;
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters(['me', 'userBalance']),
    betAmount() {
      return this.balS.reverseExchangeCurrency(this.wager);
    },
    inProgress() {
      return this.currentState === 'inProgress';
    },
    balanceIcon() {
      return getBalanceIcon(this.balS.default);
    },
    stopOnProfitAmount() {
      return this.balS.reverseExchangeCurrency(this.stopOnProfit);
    },
    stopOnLossAmount() {
      return this.balS.reverseExchangeCurrency(this.stopOnLoss);
    },
    playerLostBet() {
      return Number.parseFloat(this.playerProfit) < this.betAmount;
    },
    playerWonBet() {
      return !this.playerLostBet;
    },
  },
  methods: {
    rsgp(x) {
      return this.balS.exchangeCurrency(x, this.currency);
    },
    scaleWager(x) {
      const desiredWager = x * this.wager;
      this.wager = this.balS.formatCryptoRaw(desiredWager, true);
    },
    setOnWin(selected) {
      this.onWinSelected = selected;
    },
    setOnLoss(selected) {
      this.onLossSelected = selected;
    },
    setActive(active) {
      this.active = active;
      this.autoBet = false;
    },
    initAutoBet(cnt) {
      this.currency = this.balS.default;
      if (this.autoBet) {
        this.autoBet = false;
      } else if ((cnt || this.numOfBets) > 0) {
        if (cnt) {
          this.numOfBets = cnt;
        }
        this.profit = 0;
        this.autoBet = true;
        this.autoBetAmount = this.betAmount;
        this.bet(this.betAmount);
      }
    },
    bet(amount) {
      this.buttonDisabled = true;
      this.playHandler(amount, this.risk);
      this.numOfBets -= 1;
    },
    setActiveTab(index) {
      this.selectedTab = index;
      this.setActive(TABS[index]);
    },
  },
};
</script>
