<template>
  <div class="flex flex-row w-full">
    <TabGroup
      :selectedIndex="selectedTab"
      @change="setActiveTab"
      as="div"
      class="w-full flex flex-col-reverse md:flex-col">
      <div class="flex w-full flex-col-reverse md:flex-col w-full justify-start">
        <TabList
          class="flex flex-row mt-2 md:mt-0 px-2 w-full md:w-auto space-x-1 sm:space-x-2 bg-primary rounded-xl">
          <Tab
            :disabled="inProgress || autoBet"
            v-for="tab in tabs"
            :key="tab"
            v-slot="{ selected }"
            as="template">
            <button
              :class="[
                selected ? 'bg-secondary-light text-white' : 'bg-primary text-slate-600',
                tab === '' ? 'flex-1 border-b-0 pointer-events-none' : '',
              ]"
              class="text-white focus:outline-none flex-1 font-medium rounded-lg py-2 my-2 bg-secondary hover:bg-opacity-70 disabled:opacity-80">
              {{ tab }}
            </button>
          </Tab>
        </TabList>
      </div>
      <TabPanels class="mt-0 md:mt-2">
        <TabPanel key="1">
          <div class="flex flex-col-reverse md:flex-col">
            <div class="mt-2 md:mt-1 flex-none">
              <span class="flex h-[1px] opacity-50 bg-line-color-bright" />
            </div>
            <div>
              <div class="flex-none">
                <div class="flex flex-row gap-4 max-[767px]:flex-col">
                  <div class="flex-grow max-[767px]:w-full">
                    <label
                      for="bet-manual-amount"
                      class="mt-1 flex justify-between mb-1 text-sm font-medium text-text-dim-color">
                      Play Amount
                    </label>
                    <div class="relative">
                      <div
                        class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <img class="h-5 w-5" :src="balS.mainIcon" alt />
                      </div>
                      <input
                        type="number"
                        id="bet-manual-amount"
                        v-model="wager"
                        class="input-bet-amount pl-10"
                        placeholder="0.0000" />
                      <div
                        class="flex absolute inset-y-0 right-0 items-center pr-2 space-x-1 pointer-events-auto">
                        <button
                          type="button"
                          v-on:click="scaleWager(0.5)"
                          class="btn-multiplier">
                          ½
                        </button>
                        <button
                          type="button"
                          v-on:click="scaleWager(2.0)"
                          class="btn-multiplier">
                          2x
                        </button>
                      </div>
                    </div>

                    <div class="flex-none mt-2">
                      <div class="py-0.5 md:py-1">
                        <label
                            for="bet-manual-amount"
                            class="mt-1 flex justify-between mb-1 text-sm font-medium text-text-dim-color">
                          Profit on win
                        </label>
                        <div class="relative">
                          <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                            <img class="h-5 w-5" :src="balS.mainIcon" alt />
                          </div>
                          <input type="number" disabled v-model="nextProfit" class="input-bet-amount pl-10 disabled:cursor-not-allowed" placeholder="0.0000" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    :disabled="inProgress"
                    v-on:click="bet(betAmount, true)"
                    class="block md:hidden max-[767px]:!font-bold max-[767px]:!text-white max-[767px]:!mt-0 max-[767px]:p-2 flex-1 disabled:cursor-not-allowed bg-onprimary text-line-color font-semibold rounded-lg disabled:bg-[#43cc45]/60 disabled:opacity-70 hover:bg-[#43cc45]">
                    Play
                  </button>
                </div>

                <div class="hidden">
                  <label
                    for="mines"
                    class="mt-1 block mb-1 text-sm font-medium text-text-dim-color"
                    >Number of Plays</label
                  >
                  <select
                    v-model="numOfBets"
                    :disabled="autoBet"
                    class="text-sm rounded-lg block w-full p-2.5 bg-secondary-light border-sec-color placeholder-gray-400 text-white focus:ring-green-600 focus:border-green-600">
                    <option v-for="i in 24" v-bind:key="i">{{ i }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="flex-none w-full my-2 md:block hidden">
              <button
                :disabled="inProgress"
                v-on:click="bet(betAmount, true)"
                class="disabled:cursor-not-allowed bg-accent text-white w-full md:mt-3 p-3 text-line-color font-semibold rounded-xl flex flex-row items-center justify-center gap-2 disabled:bg-accent disabled:opacity-70 hover:bg-accent">
                Play
              </button>
            </div>
          </div>
        </TabPanel>
        <TabPanel key="2" class="flex flex-col-reverse md:flex-col">
          <div class="mt-2 md:mt-1 flex-none">
            <span class="flex h-[1px] opacity-50 bg-line-color-bright" />
          </div>
          <div>
            <div class="flex-1">
              <label
                for="bet-manual-amount"
                class="mt-1 flex justify-between mb-1 text-sm font-medium text-text-dim-color">
                Play Amount
              </label>
              <div class="relative">
                <div
                  class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <img class="h-5 w-5" :src="balS.mainIcon" alt />
                </div>
                <input
                  type="number"
                  id="bet-auto-amount"
                  v-model="wager"
                  :disabled="autoBet"
                  class="input-bet-amount pl-10"
                  placeholder="0.0000" />
                <div
                  class="flex absolute inset-y-0 right-0 items-center pr-2 space-x-1 pointer-events-auto">
                  <button
                    type="button"
                    v-on:click="scaleWager(0.5)"
                    :disabled="autoBet"
                    class="btn-multiplier">
                    ½
                  </button>
                  <button
                    type="button"
                    v-on:click="scaleWager(2.0)"
                    :disabled="autoBet"
                    class="btn-multiplier">
                    2x
                  </button>
                </div>
              </div>
            </div>
            <div class="flex-none mt-2">
              <div class="py-0.5 md:py-1">
                <label class="mt-1 block mb-1 text-sm font-medium text-text-dim-color"
                  >Number of Plays</label
                >
                <div class="relative">
                  <input
                    v-model="numOfBets"
                    :disabled="autoBet"
                    class="input-bet"
                    placeholder="No of Bets"
                    type="text" />
                </div>
              </div>
              <div class="py-0.5 md:py-1">
                <label class="mt-1 block MBX text-sm font-medium text-text-dim-color"
                  >On Win</label
                >
                <div class="flex flex-row w-full bg-primary p-0.5 space-x-1 rounded">
                  <button
                    :disabled="inProgress || autoBet"
                    :class="[
                      onWinSelected === 'Reset' ? 'bg-secondary ' : 'bg-secondary/0',
                    ]"
                    class="px-2 text-sm text-slate-300 shadow rounded hover:opacity-70"
                    v-on:click="setOnWin('Reset')">
                    Reset
                  </button>
                  <button
                    :class="[
                      onWinSelected !== 'Reset' ? 'bg-secondary ' : 'bg-secondary/0',
                    ]"
                    class="px-2 shadow rounded text-sm text-slate-300 hover:opacity-70"
                    :disabled="autoBet"
                    v-on:click="setOnWin('Inc')">
                    IncreaseBy:
                  </button>
                  <div class="relative flex-1 rounded-xl">
                    <input
                      v-model="onWinPercent"
                      :disabled="inProgress || onWinSelected === 'Reset'"
                      class="input-bet-winloss"
                      max="1000000"
                      min="0"
                      type="text" />
                    <div
                      class="absolute inset-y-0 right-0 flex items-center rounded-r-md m-0.5 box-context">
                      <button class="btn-winloss">%</button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="py-0.5 md:py-1">
                <label class="mt-1 block MBX text-sm font-medium text-text-dim-color"
                  >On Loss</label
                >
                <div class="flex flex-row w-full bg-primary p-0.5 space-x-1 rounded">
                  <button
                    :disabled="autoBet"
                    :class="[
                      onLossSelected === 'Reset' ? 'bg-secondary ' : 'bg-secondary/0',
                    ]"
                    class="px-2 text-slate-300 shadow rounded text-sm hover:opacity-70"
                    v-on:click="setOnLoss('Reset')">
                    Reset
                  </button>
                  <button
                    :disabled="autoBet"
                    :class="[
                      onLossSelected !== 'Reset' ? 'bg-secondary ' : 'bg-secondary/0',
                    ]"
                    class="px-2 shadow rounded text-slate-300 text-sm hover:opacity-70"
                    v-on:click="setOnLoss('Inc')">
                    IncreaseBy:
                  </button>
                  <div class="relative flex-1">
                    <input
                      v-model="onLossPercent"
                      :disabled="autoBet || onLossSelected === 'Reset'"
                      class="input-bet-winloss"
                      max="1000000"
                      min="0"
                      type="text" />
                    <div
                      class="absolute inset-y-0 right-0 flex items-center rounded-r-md m-0.5 box-context">
                      <button class="btn-winloss">%</button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="py-0.5 md:py-1">
                <label class="mt-1 block mb-1 text-sm font-medium text-text-dim-color"
                  >Stop On Profit</label
                >
                <div class="relative">
                  <input
                    v-model="stopOnProfit"
                    :disabled="autoBet"
                    class="input-bet"
                    placeholder="Stop On Profit"
                    type="text" />
                </div>
              </div>
              <div class="py-0.5 md:py-1">
                <label class="mt-1 block mb-1 text-sm font-medium text-text-dim-color"
                  >Stop On Loss</label
                >
                <div class="relative">
                  <input
                    v-model="stopOnLoss"
                    :disabled="autoBet"
                    class="input-bet"
                    placeholder="Stop On Loss"
                    type="text" />
                </div>
              </div>
              <div class="mt-2">
                <span class="mt-1 text-slate-300 inline">
                  Profit: {{ balS.exchangeAndFormatAmount(profit) }}
                  <img class="h-4 w-4 inline" :src="balS.mainIcon" alt />
                </span>
              </div>
            </div>
          </div>
          <div class="flex-1 my-2 flex items-center">
            <button
              :disabled="
                buttonDisabled ||
                !numOfBets ||
                Number.parseInt(numOfBets) <= 0 ||
                (currentState === 'inprogress' && !this.autoBet)
              "
              v-on:click="initAutoBet()"
              class="btn-bet"
              type="button">
              {{
                currentState === 'inprogress' && this.autoBet
                  ? 'Stop Autoplay'
                  : 'Start Autoplay'
              }}
            </button>
          </div>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { defaultConvertor } from '../../lib/rsgp';
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue';
const TABS = ['Manual', 'Auto'];
import { ref } from 'vue';
import { getBalanceIcon } from '@/lib/icons';
import { balanceStore } from '@/store/pinia/balanceStore';
import { BigNumber } from 'bignumber.js';

export default {
  name: 'DicingBets',
  components: {
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
  },
  props: {
    currentState: {},
    playerProfit: {},
    multiplier: {
      default: 0,
    },
    gameId: {
      default: 0,
    },
    playHandler: {},
    autoBetChanged: {
      default: () => {},
    },
  },
  data() {
    return {
      wager: 1,
      active: 'Manual',
      numOfBets: 0,
      selectedTab: 0,
      autoBet: false,
      autoBetAmount: 0,
      onWinPercent: 0.0,
      onLossPercent: 0.0,
      onWinSelected: 'Reset',
      onLossSelected: 'Reset',
      stopOnLoss: '0',
      stopOnProfit: '0',
      profit: 0,
      buttonDisabled: false,
    };
  },
  mounted() {
    this.scaleWager(1);
  },
  deactivated() {
    this.autoBet = false;
  },
  setup() {
    const balS = balanceStore();
    balS.init();
    return { tabs: TABS, balS };
  },
  watch: {
    currentState: function (newState, oldState) {
      this.buttonDisabled = false;
      if (this.autoBet) {
        if (newState === 'finished' || newState === 'failed') {
          if (this.active === 'Manual' || newState === 'failed') {
            this.autoBet = false;
            return;
          }
          if (this.numOfBets <= 0) {
            this.numOfBets = 0;
            this.autoBet = false;
          }

          this.profit += this.playerProfit;
          if (this.playerWonBet) {
            if (this.onWinSelected === 'Reset') {
              this.wager = this.rsgp(this.autoBetAmount);
            } else {
              this.scaleWager(1 + Number.parseFloat(this.onWinPercent) / 100.0);
            }
          } else if (this.playerLostBet) {
            if (this.onLossSelected === 'Reset') {
              this.wager = this.rsgp(this.autoBetAmount);
            } else {
              this.scaleWager(1 + Number.parseFloat(this.onLossPercent) / 100.0);
            }
          }

          if (this.stopOnProfitAmount > 0 && this.profit >= this.stopOnProfitAmount) {
            this.autoBet = false;
          }
          if (this.stopOnLossAmount > 0 && this.profit <= -this.stopOnLossAmount) {
            this.autoBet = false;
          }

          if (this.autoBet && this.numOfBets > 0) {
            setTimeout(() => this.bet(this.betAmount), 400);
          } else {
            this.autoBet = false;
            this.numOfBets = 0;
          }
        }
      }
    },
    autoBet() {
      this.autoBetChanged(this.autoBet);
    },
  },
  computed: {
    ...mapGetters(['me', 'userBalance']),
    betAmount() {
      return this.balS.reverseExchangeCurrency(this.wager);
    },
    betAmountCryptoFiat() {},
    balanceIcon() {
      return getBalanceIcon(this.balS.default);
    },
    stopOnProfitAmount() {
      return this.balS.reverseExchangeCurrency(this.stopOnProfit);
    },
    stopOnLossAmount() {
      return this.balS.reverseExchangeCurrency(this.stopOnLoss);
    },
    playerLostBet() {
      return Number.parseFloat(this.playerProfit) < 0;
    },
    playerWonBet() {
      return !this.playerLostBet;
    },
    inProgress() {
      return this.currentState.toLowerCase() === 'inprogress';
    },
    nextProfit() {
      return this.multiplier > 0 ? this.rsgp(((this.wager * this.multiplier) - this.wager)).toFixed(4) : 0;
    },
  },
  methods: {
    rsgp(x) {
      return defaultConvertor.format(x);
    },
    setActiveTab(index) {
      this.selectedTab = index;
      this.activeTab = TABS[index];
      this.setActive(TABS[index]);
    },
    scaleWager(x) {
      const desiredWager = x * Number.parseFloat(this.wager);
      this.wager = this.balS.formatRaw(desiredWager);
    },
    setOnWin(selected) {
      this.onWinSelected = selected;
    },
    setOnLoss(selected) {
      this.onLossSelected = selected;
    },
    setActive(active) {
      this.active = active;
    },
    initAutoBet(cnt) {
      if (this.autoBet) {
        this.autoBet = false;
      } else if ((cnt || this.numOfBets) > 0) {
        if (cnt) {
          this.numOfBets = cnt;
        }
        this.profit = 0;
        this.autoBet = true;
        this.autoBetAmount = this.wager;
        this.bet(this.betAmount);
      }
    },
    bet(amount, endEarly) {
      this.buttonDisabled = true;
      this.playHandler(amount, endEarly);
      this.numOfBets -= 1;
      this.numOfBets = Math.max(Number.parseInt(this.numOfBets), 0);
    },

  },
};
</script>
