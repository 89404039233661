<template>
  <punt-modal size="max-w-3xl" show-close="true" class="rules_title">
    <template #title>
      <h1 class="text-xl font-bold text-[#dfbc00] mb-2 max-[767px]:text-lg">
        Game Rules
      </h1>
    </template>
    <template #body>
      <div class="modal is-active">
        <div class="modal-card">
          <section class="modal-card-body text-slate-300">
            <div class="space-y-2 max-[767px]:text-sm">
              <ol class="list-disc px-5">
                <li>Only roll outcomes that hit the green area are winners.</li>
                <li>Players are prohibited from using their own dice.</li>
              </ol>
            </div>
          </section>
        </div>
      </div>
    </template>
  </punt-modal>
</template>

<script>
import PuntModal from '@/components/Shared/PuntModal.vue';

export default {
  components: { PuntModal },

  methods: {
    closeModal() {
      switch (this.onClose) {
        case 'back':
          this.$router.go(-1);
          break;
        case 'close':
          this.$emit('close');
          break;
      }
    },
  },
};
</script>
