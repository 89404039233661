<template>
  <punt-modal show-close="true" size="max-w-2xl" class="rules_title">
    <template #title>
      <div class="flex flex-row items-center gap-2 text-slate-300/80">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-8 w-8"
          viewBox="0 0 20 20"
          fill="currentColor">
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clip-rule="evenodd" />
        </svg>
        <h1 class="text-lg font-bold">Game ID {{ gameId.toLocaleString() }}</h1>
      </div>
    </template>
    <template #body>
      <div class="modal is-active">
        <div class="modal-card text-slate-300">
          <section class="modal-card-body">
            <div v-if="!game" class="text-center">Loading...</div>
            <div v-else>
              <div class="flex flex-row justify-center my-4">
                <p class="text-center text-lg">
                  <strong class="inline">
                    {{ rsgp(game.wager) }}
                    <img
                      class="ml-1 inline h-4 w-4"
                      :src="balS.getBalanceIcon(game.game_mode)" />
                  </strong>
                  <span v-if="user?.username != null">
                    played by <strong>{{ user?.username }}</strong></span
                  >
                </p>
              </div>
              <hr />
              <div class="flex flex-row text-center mt-4">
                <div class="flex-1">
                  <h2 class="subtitle">Play</h2>
                  <h5 class="subtitle is-4">
                    {{ rsgp(game.wager) }}
                    <img
                      class="ml-1 inline h-4 w-4"
                      :src="balS.getBalanceIcon(game.game_mode)" />
                  </h5>
                </div>
                <div class="flex-1">
                  <h2 class="subtitle">Profit</h2>
                  <h5 class="subtitle is-4">
                    <span
                      class="inline"
                      :style="{ color: game.profit > 0 ? 'green' : 'red' }">
                      {{ rsgp(game.profit) }}
                      <img
                        class="ml-1 inline h-4 w-4"
                        :src="balS.getBalanceIcon(game.game_mode)" />
                    </span>
                  </h5>
                </div>
              </div>
              <div>
                <div class="mt-4">
                  <h2 style="font-size: 1.4rem; font-weight: bold; text-align: center">
                    Shuffled Deck
                  </h2>
                  <div class="deck-wrap">
                    <ul class="deck encoded">
                      <li v-for="card in game.deck">
                        {{ card.encoded }}
                      </li>
                    </ul>

                    <ul class="deck card-values">
                      <li v-for="card in game.deck">
                        {{ decodeSuit(card.suit) }} {{ decodeValue(card.rank) }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </template>
  </punt-modal>
</template>

<script>
import { defaultConvertor as rsgp, convertGameMode } from '@/lib/rsgp';
import PuntModal from '@/components/Shared/PuntModal.vue';
import Card from './Card.vue';
import { SUITS } from './PlayingCards';
import { balanceStore } from '@/store/pinia/balanceStore';
import { copyToClipboard } from '@/lib/utils';

const JSBIN = 'https://output.jsbin.com/dusogiy';

export default {
  components: {
    Card,
    PuntModal,
  },
  setup() {
    return {
      balS: balanceStore(),
    };
  },
  props: {
    gameId: {
      type: [Number, String],
      validator: function (x) {
        return x > 0 && x < 50e6;
      },
    },
    onClose: {
      type: String,
      required: false,
      default: function () {
        return 'close';
      },
      validator: function (x) {
        return ['close', 'back'].includes(x);
      },
    },
  },
  data() {
    return {
      game: null,
      error: null,
      fetching: false,
    };
  },
  created() {
    this.fetchGameInfo();
  },
  computed: {
    bets() {
      const bets = this.game.bets;
      return Object.keys(bets).reduce((arr, k) => {
        arr[parseInt(k.replace('bet_', ''))] = bets[k];
        return arr;
      }, []);
    },
    currency() {
      return (convertGameMode(this.user.game_mode) || '').toUpperCase();
    },
    interactiveUrl() {
      return `${JSBIN}?cs=${this.seed.client_seed}&ss=${this.seed.server_seed}&nonce=${this.game.nonce}`;
    },
    seed() {
      return this.game.seed;
    },
    user() {
      return this.game.user;
    },
    gameResult() {
      if (this.game.result < 37) {
        return this.game.result;
      }

      if (this.game.result === 37) {
        return '00';
      }
    },
  },
  methods: {
    closeModal() {
      switch (this.onClose) {
        case 'back':
          this.$router.go(-1);
          break;
        case 'close':
          this.$emit('close');
          break;
      }
    },
    fetchGameInfo() {
      this.fetching = true;
      this.$http
        .get('blackjack/games/' + this.gameId)
        .then(({ data }) => {
          data.bets =
            typeof data.bets === 'string' || data.bets instanceof String
              ? JSON.parse(data.bets)
              : data.bets;
          this.game = data;
          this.fetching = false;
        })
        .catch((err) => {
          this.fetching = false;
          this.error = err.toString();
        });
    },
    copyClient() {
      copyToClipboard(this.seed.client_seed);
    },
    copyServer() {
      copyToClipboard(this.seed.server_seed);
    },
    copyServerHashed() {
      copyToClipboard(this.seed.server_seed_hashed);
    },
    copyNonce() {
      copyToClipboard(this.seed.nonce);
    },
    rsgp(x) {
      return this.balS.exchangeAndFormatAmount(x, this.game.game_mode);
    },
    decodeSuit(i) {
      switch (i) {
        case SUITS.CLUBS:
          return '♣';

        case SUITS.DIAMONDS:
          return '♦';

        case SUITS.HEARTS:
          return '♥';

        case SUITS.SPADES:
          return '♠';
      }
    },
    decodeValue(i) {
      if (i >= 2 && i <= 10) {
        return i;
      }

      switch (i) {
        case 1:
          return 'A';
        case 11:
          return 'J';
        case 12:
          return 'Q';
        case 13:
          return 'K';
      }
    },
  },
  watch: {
    gameId() {
      this.fetchGameInfo();
    },
  },
};
</script>

<style scoped lang="scss">
.modal {
  .deck-wrap {
    width: 100%;
    overflow: scroll;
    padding: 1rem 0;

    .deck {
      width: 3rem * 52;
      height: 1.5rem;

      li {
        display: inline-block;
        width: 3rem;

        text-align: center;

        border: 1px solid #d9d9d9;
      }
    }
  }
}
</style>
