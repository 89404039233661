<template>


    <div class="grid  grid-rows-2 gap-2 grid-cols-2 lg:flex lg:flex-row jackpots-amount mt-5 mb-5 md:mb-10 md:mt-0 lg:space-x-3  " :class="'type-'+type" v-if="type==1">
      <div  v-for="(tier,i) in alltiers" class="sm:flex-1 jackpots-amount-item items-center sm:flex-row sm:flex py-1 rounded-lg   " :class="tier.label">
        <div class="label font-bold text-sm pr-2 pl-2  ">
          {{tier.label}}
          <img :src="'/images/jackpot/'+image[i]" :alt="tier.label"  />
        </div>
        <div class="value flex-auto font-bold text-sm sm:text-lg xl:text-xl pl-2">
          {{ currency }} {{ (startvalue[i]<1000)?(parseFloat(startvalue[i])).toFixed(2).toLocaleString():(parseInt(startvalue[i])).toLocaleString() }}
        </div>
        
      </div>
    </div>
    <div v-else class="lg:flex lg:flex-row jackpots-banner mb-10  sm:space-x-3 items-center " :class="'type-'+type">
        <div class="lg:flex-auto jackpot-logo lg:w-[35%] lg:mb-0 mb-10 text-center  ">
            <img src="/images/jackpot/Jackpot Play Logo.webp" class="m-auto w-[100%]  max-w-[325px] lg:max-w-[100%] lg:w-auto" alt="jackpot play" />
        </div>
        <div class="lg:flex-auto jackpot-details lg:w-[65%]  lg:pl-10  ">
            <div class="grid grid-rows-2  grid-cols-2 gap-4 jackpots-amount    " >
              <div  v-for="(tier,i) in alltiers" class=" sm:flex jackpots-amount-item items-center sm:flex-row sm:flex py-1 rounded-lg    " :class="tier.label">
                <div class="label font-bold text-sm pr-2 pl-2  ">
                  {{tier.label}}
                  <img :src="'/images/jackpot/'+image[i]" :alt="tier.label"  />
                </div>
                <div class="value flex-auto font-bold text-sm sm:text-lg xl:text-xl pl-2">
                  {{ currency }} {{ (startvalue[i]<1000)?(parseFloat(startvalue[i])).toFixed(2).toLocaleString():(parseInt(startvalue[i])).toLocaleString() }}
                </div>
                
              </div>
            </div>
        </div>
    </div>

</template>

<script>
import { mapGetters } from 'vuex';
import { getBalanceIcon } from '@/lib/icons';
export default {
  name: 'AllJackpotCounter',
  props: {
    currency: {
      default:'SC'
    },
    type:{
      default:2
    },
    alltiers: 
    {
      default:0
    }
  },
  components: {
    
  },
  mounted()
  {
    this.init();
    

  },
  data() {
    return {
      startvalue : [
        0,0,0,0
      ],
      image :[
        'J.webp',
        'Q.webp',
        'K.webp',
        'A.webp',
      ],
      interval : null,
     
    };
  },
  methods: {
      
    getIcon(currency) {
      return getBalanceIcon(currency);
    },

    init()
    {
      var obj = this;
      setTimeout(() => {
        obj.interval = setInterval(function(){
        
        
        obj.alltiers.forEach((v,i) => {  
           
          if( parseFloat(obj.startvalue[i]) < v.amount )
          {
         
            if(obj.startvalue[i] < (v.amount*0.90))
            {
              obj.startvalue[i] +=((v.amount*0.90)/300)
            }
            else if(obj.startvalue[i] < (v.amount*0.97))
            {
              obj.startvalue[i] +=((v.amount*0.07)/300)
            }
            else if(obj.startvalue[i] < (v.amount*0.99))
            {
              obj.startvalue[i] +=((v.amount*0.02)/300)
            }
            else{
               obj.startvalue[i] +=((v.amount*0.01)/300)
            }

             
            
            if(obj.startvalue[i] > v.amount)
                obj.startvalue[i] = 0;
         
          }

        });
          
         

        }, 100);
      }, 1000);

     
      
    }
  },
};
</script>
<style>
 
.jackpots-amount .jackpots-amount-item 
{
  border-radius:20px;
  border:1px solid #ccc;
}
.jackpots-amount .Minor
{
  background: rgb(84,9,131);
  background: linear-gradient(90deg, rgba(84,9,131,1) 0%, rgba(2,0,36,1) 100%);
}
.jackpots-amount .Major 
{
  background: rgb(17,30,101);
  background: linear-gradient(90deg, rgba(17,30,101,1) 0%, rgba(2,0,36,1) 100%);
}
.jackpots-amount .Mega
{
  background: rgb(23,88,14);
  background: linear-gradient(90deg, rgba(23,88,14,1) 0%, rgba(2,0,36,1) 100%);
}
.jackpots-amount .Grand
{
  background: rgb(109,1,12);
  background: linear-gradient(90deg, rgba(109,1,12,1) 0%, rgba(2,0,36,1) 100%);
}
.jackpots-amount .label 
{
  text-transform:uppercase;
  position:relative;
}
.jackpots-amount .label:after 
{
  content:'';
  height:calc(100% - 25px);
  border:1px solid #ccc;
  position:absolute;
  right:0;
  top:50%;
  transform: translateY(-50%)
}
.jackpots-amount .label  img 
{
  max-width:23px;
  margin:-4px auto 0;
  height:auto;
}
.jackpots-banner
{
  background-image:url('/images/jackpot/background.jpg');
  background-size:cover;
  background-position:center;
  padding:25px 25px;
  border-radius:15px;
}
@media(max-width:640px)
{
  .jackpots-amount .label:after 
  {
    display:none;
  }
  .jackpots-amount .jackpots-amount-item
  {
    position:relative;
  }
  .jackpots-amount .label
  {
    position:static;
  }
   .jackpots-amount .label img
   {
     position:absolute;
     right:13px;
     margin:0;
     top:50%;
     transform:translateY(-50%);
   }
  .jackpots-banner
  {
    padding:25px 5px;
  }
   
}
</style>
 
 