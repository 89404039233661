<template>
  <punt-modal show-close="true" size="max-w-3xl" class="rules_title">
    <template #title>
      <h1 class="text-xl font-bold text-[#dfbc00] mb-2 max-[767px]:text-lg">
        Game Rules and Limits
      </h1>
    </template>
    <template #body>
      <div class="modal is-active">
        <div class="modal-background" v-on:click.stop.prevent="closeModal"></div>
        <div class="modal-card">
          <section class="modal-card-body text-slate-300">
            <div v-if="!limits" class="has-text-centered">Loading...</div>
            <div v-else>
              <div class="tabs is-small is-fullwidth max-[767px]:text-sm">
                <TabGroup as="div" class="min-w-md flex flex-row justify-center">
                  <TabList
                    class="flex space-x-1 rounded-full bg-secondary-light p-1 w-full py-2 px-2 mb-4">
                    <Tab
                      v-for="item in options"
                      :key="item"
                      v-slot="{ selected }"
                      as="template">
                      <button
                        :class="[
                          'w-full rounded-full py-2.5 text-sm font-medium leading-5 text-white',
                          'focus:outline-none',
                          selected
                            ? 'bg-line-color'
                            : 'text-white hover:bg-white/[0.12] hover:text-white',
                        ]"
                        v-on:click="setActive(item)">
                        {{ item }}
                      </button>
                    </Tab>
                  </TabList>
                </TabGroup>
              </div>
              <div v-if="active === 'Rules'" class="flex flex-col space-y-2">
                <span class="mt-1 inline text-sm text-white gap-2">
                  <strong style="color: white">Minimum play</strong> is
                  <strong style="color: white">{{
                    balS.exchangeAndFormatAmount(limits.min_wager)
                  }}</strong>
                  <img
                    :src="balS.getIcon()"
                    alt="Currency Icon"
                    class="inline h-4 w-4 ml-1" />
                </span>

                <span class="mt-1 inline text-sm text-white gap-2">
                  <strong style="color: white">Maximum play</strong> is
                  <strong style="color: white">{{
                    balS.exchangeAndFormatAmount(limits.max_wager)
                  }}</strong>
                  <img
                    :src="balS.getIcon()"
                    alt="Currency Icon"
                    class="inline h-4 w-4 ml-1" />
                </span>

                <span class="mt-1 inline text-sm text-white gap-2">
                  <strong style="color: white">Max payout</strong> is
                  <strong style="color: white">{{
                    balS.exchangeAndFormatAmount(limits.max_payout)
                  }}</strong>
                  <img
                    :src="balS.getIcon()"
                    alt="Currency Icon"
                    class="inline h-4 w-4 ml-1" />
                </span>

                <p class="has-text-centered" style="color: white; padding-bottom: 3px">
                  The more number combinations selected, the higher potential payout
                </p>
                <p class="has-text-centered" style="color: white; padding-bottom: 3px">
                  The more selections that successfully hit, the higher your payout
                  multiplier
                </p>
              </div>
              <mines-pay-table v-else />
            </div>
          </section>
        </div>
      </div>
    </template>
  </punt-modal>
</template>

<script>
import { defaultConvertor as rsgp } from '../../lib/rsgp';
import MinesPayTable from './MinesPayTable.vue';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue';
import PuntModal from '@/components/Shared/PuntModal.vue';
import { BigNumber } from 'bignumber.js';
import { balanceStore } from '@/store/pinia/balanceStore';

export default {
  components: {
    PuntModal,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    'mines-pay-table': MinesPayTable,
  },
  props: {
    onClose: {
      type: String,
      required: false,
      default: function () {
        return 'close';
      },
      validator: function (x) {
        return ['close', 'back'].includes(x);
      },
    },
  },
  setup() {
    return { balS: balanceStore() };
  },
  data() {
    return {
      limits: false,
      error: null,
      fetching: false,
      options: ['Rules', 'Pay Table'],
      active: 'Rules',
    };
  },
  created() {
    this.fetchLimits();
  },
  methods: {
    closeModal() {
      switch (this.onClose) {
        case 'back':
          this.$router.go(-1);
          break;
        case 'close':
          this.$emit('close');
          break;
      }
    },
    setActive(option) {
      this.active = option;
    },
    fetchLimits() {
      this.fetching = true;
      this.$http
        .get('mines')
        .then(({ data }) => {
          let min_wager = new BigNumber(data.limits.min_wager);
          let max_wager = new BigNumber(data.limits.max_wager);
          let max_payout = new BigNumber(data.limits.max_payout);

          data.limits.min_wager = min_wager.toFixed();
          data.limits.max_wager = max_wager.toFixed();
          data.limits.max_payout = max_payout.toFixed();
          this.limits = data.limits;

          this.fetching = false;
        })
        .catch((err) => {
          this.fetching = false;
          this.error = err.toString();
        });
    },
    rsgp(x) {
      return rsgp.format(x);
    },
  },
};
</script>

<style lang="sass">
.dark section.modal-card-body p, .dark section.modal-card-body .subtitle
  color: black
</style>
