<template>
  <div class="flex flex-row md:w-[auto] h-full w-full">
    <TabGroup
      :selectedIndex="selectedTab"
      @change="setActiveTab"
      as="div"
      class="flex flex-col-reverse md:flex-col w-full">
      <div class="flex flex-col w-full justify-start">
        <TabList
          class="flex flex-row mt-2 md:mt-0 px-2 w-full md:w-auto space-x-1 sm:space-x-2 bg-primary rounded-xl">
          <Tab
            :disabled="currentState === 'inProgress'"
            v-for="tab in tabs"
            :key="tab"
            v-slot="{ selected }"
            as="template">
            <button
              :disabled="currentState === 'inProgress'"
              :class="[
                selected ? 'bg-secondary-light text-white' : 'bg-primary text-slate-600',
                tab === '' ? 'flex-1 border-b-0 pointer-events-none' : '',
              ]"
              class="text-white focus:outline-none flex-1 font-medium rounded-lg py-2 my-2 bg-secondary hover:bg-opacity-70 disabled:opacity-80">
              {{ tab }}
            </button>
          </Tab>
        </TabList>
      </div>
      <TabPanels class="mt-0 md:mt-1 flex-grosw">
        <TabPanel class="h-full" key="1">
          <div class="flex flex-col-reverse md:flex-col gap-2 h-full justify-between">
            <div class="mt-1 md:hidden block">
              <span class="flex h-[1px] opacity-50 bg-line-color-bright" />
            </div>
            <div class="flex flex-col">
              <div class="mt-1 hidden md:block">
                <span class="flex h-[1px] opacity-50 bg-line-color-bright" />
              </div>
              <div class="grow">
                <div class="flex flex-row gap-4 max-[767px]:flex-col">
                  <div class="flex flex-col flex-grow max-[767px]:w-full">
                    <label
                      for="bet-manual-amount"
                      class="mt-1 flex justify-between mb-1 text-sm font-medium text-text-dim-color">
                      Play Amount
                    </label>
                    <div class="relative">
                      <div
                        class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <img
                          class="h-5 w-5"
                          :src="
                            balS.getIcon(
                              currentState === 'inProgress'
                                ? game.game_mode
                                : balS.mainCurrency,
                            )
                          "
                          alt />
                      </div>
                      <input
                        v-if="currentState === 'inProgress'"
                        type="text"
                        id="bet-manual-amount"
                        :value="balS.exchangeAndFormatAmount(game.wager, game.game_mode)"
                        disabled
                        class="input-bet-amount"
                        placeholder="0.0000" />
                      <input
                        v-else
                        type="number"
                        v-model="wager"
                        :disabled="
                          currentState === 'inProgress' || currentState !== 'notStarted'
                        "
                        class="input-bet-amount"
                        placeholder="0.0000" />
                      <div
                        class="flex absolute inset-y-0 right-0 items-center pr-2 space-x-2 pointer-events-auto">
                        <button
                          type="button"
                          v-on:click="scaleWager(0.5)"
                          :disabled="
                            currentState === 'inProgress' || currentState !== 'notStarted'
                          "
                          class="btn-multiplier">
                          ½
                        </button>
                        <button
                          type="button"
                          v-on:click="scaleWager(2.0)"
                          :disabled="
                            currentState === 'inProgress' || currentState !== 'notStarted'
                          "
                          class="btn-multiplier">
                          2x
                        </button>
                      </div>
                    </div>
                  </div>
                  <button
                    v-on:click="debouncedPrimaryAction()"
                    :disabled="loading || buttonDisabled || currentState === 'picking'"
                    class="btn-bet block md:hidden !mt-0 flex-1">
                    {{ currentState === 'inProgress' ? 'Cashout' : 'Play' }}
                  </button>
                </div>

                <label
                  for="mines"
                  class="mt-1 block mb-1 text-sm font-medium text-text-dim-color"
                  >Mines</label
                >
                <select
                  v-model="minesCount"
                  :disabled="
                    currentState === 'inProgress' || currentState !== 'notStarted'
                  "
                  class="input-bet-select">
                  <option v-for="i in 24" v-bind:key="i">
                    {{ i }}
                  </option>
                </select>
                <div v-if="currentState === 'inProgress'">
                  <label
                    for="profit-auto"
                    class="mt-1 block mb-1 text-sm font-medium text-text-dim-color">
                    <span class="label is-small is-unselectable">
                      Profit on Next Tile({{
                        !nextProfits ? '0.00' : nextProfits.nextMultiplier.toFixed(2)
                      }}x)
                    </span>
                  </label>
                  <input
                    type="text"
                    id="profit"
                    :value="
                      !nextProfits
                        ? ''
                        : balS.exchangeAndFormatAmount(
                            nextProfits.nextProfit,
                            game.game_mode,
                          )
                    "
                    :disabled="currentState === 'inProgress'"
                    class="input-bet"
                    placeholder="0" />
                </div>
                <div v-if="currentState === 'inProgress'">
                  <label
                    for="total-profit-auto"
                    class="mt-1 flex justify-between mb-1 text-sm font-medium text-text-dim-color">
                    <span class="label is-small is-unselectable">
                      Total Profit ({{
                        !nextProfits ? '0.00' : nextProfits.currentMultiplier.toFixed(2)
                      }}x)
                    </span>
                  </label>
                  <input
                    type="text"
                    id="total-profit"
                    :value="
                      !nextProfits
                        ? ''
                        : balS.exchangeAndFormatAmount(
                            nextProfits.currentProfit,
                            game.game_mode,
                          )
                    "
                    disabled
                    class="input-bet"
                    placeholder="0" />
                </div>
              </div>
            </div>
            <div class="flex-none mt-2 flex flex-col gap-2">
              <div v-if="canAutoPick" class="flex gap-2">
                <button
                  type="button"
                  v-on:click="autoPick()"
                  class="disabled:opacity-50 text-slate-300 w-full font-medium rounded-lg text-sm p-4 bg-secondary-light hover:opacity-70 active:opacity-50">
                  Auto Pick
                </button>
              </div>
              <button
                v-on:click="debouncedPrimaryAction()"
                :disabled="loading || buttonDisabled || currentState === 'picking'"
                class="btn-bet hidden md:flex">
                {{ currentState === 'inProgress' ? 'Cashout' : 'Play' }}
              </button>
            </div>
          </div>
        </TabPanel>
        <TabPanel class="h-full" key="2">
          <div class="flex flex-col-reverse md:flex-col gap-2 justify-between h-full">
            <div class="mt-1">
              <span class="flex h-[1px] opacity-50 bg-line-color-bright" />
            </div>
            <div class="flex flex-col">
              <div class="flex-grow flex flex-col">
                <label
                  for="bet-manual-amount"
                  class="mt-1 flex justify-between mb-1 text-sm font-medium text-text-dim-color">
                  Play Amount
                </label>
                <div class="relative">
                  <div
                    class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <img
                      class="h-5 w-5"
                      :src="
                        balS.getIcon(
                          currentState === 'inProgress' && game && game.game_mode
                            ? game.game_mode
                            : balS.mainCurrency,
                        )
                      "
                      alt />
                  </div>
                  <input
                    v-if="currentState === 'inProgress' && game"
                    type="text"
                    id="bet-manual-amount"
                    :value="balS.exchangeAndFormatAmount(game.wager, game.game_mode)"
                    disabled
                    class="input-bet-amount"
                    placeholder="0.0000" />
                  <input
                    v-else
                    type="number"
                    v-model="wager"
                    :disabled="
                      currentState === 'inProgress' || currentState !== 'notStarted'
                    "
                    class="input-bet-amount"
                    placeholder="0.0000" />
                  <div
                    class="flex absolute inset-y-0 right-0 items-center pr-2 space-x-2 pointer-events-auto">
                    <button
                      type="button"
                      v-on:click="scaleWager(0.5)"
                      :disabled="
                        currentState === 'inProgress' || currentState !== 'notStarted'
                      "
                      class="btn-multiplier">
                      ½
                    </button>
                    <button
                      type="button"
                      v-on:click="scaleWager(2.0)"
                      :disabled="
                        currentState === 'inProgress' || currentState !== 'notStarted'
                      "
                      class="btn-multiplier">
                      2x
                    </button>
                  </div>
                </div>
                <div class="flex flex-row space-x-4">
                  <div class="w-full">
                    <label
                      for="mines"
                      class="mt-1 block mb-1 text-sm font-medium text-text-dim-color"
                      >Mines</label
                    >
                    <select
                      v-model="minesCount"
                      :disabled="
                        currentState === 'inProgress' || currentState !== 'notStarted'
                      "
                      class="input-bet-select">
                      <option v-for="i in 24" v-bind:key="i">{{ i }}</option>
                    </select>
                  </div>
                </div>
                <div>
                  <label
                    for="profit-auto"
                    class="mt-1 block mb-1 text-sm font-medium text-text-dim-color">
                    <span class="label is-small is-unselectable"> No of Plays </span>
                  </label>
                  <input
                    type="text"
                    id="noOfBets"
                    v-model="numOfBets"
                    :disabled="currentState === 'inProgress'"
                    class="input-bet"
                    placeholder="0" />
                </div>
                <div class="py-0.5 md:py-1">
                  <label class="mt-1 block MBX text-sm font-medium text-text-dim-color"
                    >On Win</label
                  >
                  <div class="flex flex-row w-full bg-primary p-0.5 space-x-1 rounded">
                    <button
                      :disabled="inProgress"
                      :class="[
                        onWinSelected === 'Reset' ? 'bg-secondary ' : 'bg-secondary/0',
                      ]"
                      class="px-2 text-sm text-slate-300 shadow rounded hover:opacity-70"
                      v-on:click="setOnWin('Reset')">
                      Reset
                    </button>
                    <button
                      :disabled="loading"
                      :class="[
                        onWinSelected !== 'Reset' ? 'bg-secondary ' : 'bg-secondary/0',
                      ]"
                      class="px-2 shadow rounded text-sm text-slate-300 hover:opacity-70"
                      v-on:click="setOnWin('Inc')">
                      IncreaseBy:
                    </button>
                    <div class="relative flex-1 rounded-xl">
                      <input
                        v-model="onWinPercent"
                        :disabled="inProgress || onWinSelected === 'Reset'"
                        class="input-bet"
                        max="1000000"
                        min="0"
                        type="text" />
                      <div
                        class="absolute inset-y-0 right-0 flex items-center rounded-r-md m-0.5 box-context">
                        <button class="btn-winloss">%</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="py-0.5 md:py-1">
                  <label class="mt-1 block MBX text-sm font-medium text-text-dim-color"
                    >On Loss</label
                  >
                  <div class="flex flex-row w-full bg-primary p-0.5 space-x-1 rounded">
                    <button
                      :disabled="autoBet"
                      :class="[
                        onLossSelected === 'Reset' ? 'bg-secondary ' : 'bg-secondary/0',
                      ]"
                      class="px-2 text-slate-300 shadow rounded text-sm hover:opacity-70"
                      v-on:click="setOnLoss('Reset')">
                      Reset
                    </button>
                    <button
                      :disabled="autoBet"
                      :class="[
                        onLossSelected !== 'Reset' ? 'bg-secondary ' : 'bg-secondary/0',
                      ]"
                      class="px-2 shadow rounded text-slate-300 text-sm hover:opacity-70"
                      v-on:click="setOnLoss('Inc')">
                      IncreaseBy:
                    </button>
                    <div class="relative flex-1">
                      <input
                        v-model="onLossPercent"
                        :disabled="autoBet || onLossSelected === 'Reset'"
                        class="input-bet-winloss"
                        max="1000000"
                        min="0"
                        type="text" />
                      <div
                        class="absolute inset-y-0 right-0 flex items-center rounded-r-md m-0.5 box-context">
                        <button class="btn-winloss">%</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="py-0.5 md:py-1">
                  <label class="mt-1 block MBX text-sm font-medium text-text-dim-color"
                    >Stop On Profit</label
                  >
                  <div class="relative">
                    <input
                      v-model="stopOnProfit"
                      :disabled="autoBet"
                      class="input-bet"
                      placeholder="Stop On Profit"
                      type="text" />
                  </div>
                </div>
                <div class="py-0.5 md:py-1">
                  <label class="mt-1 block MBX text-sm font-medium text-text-dim-color"
                    >Stop On Loss</label
                  >
                  <div class="relative">
                    <input
                      v-model="stopOnLoss"
                      :disabled="autoBet"
                      class="input-bet"
                      placeholder="Stop On Loss"
                      type="text" />
                  </div>
                </div>
                <div class="mt-1">
                  <div class="mt-1 text-slate-300">
                    Remaining: {{ remainingAutoBets }}
                  </div>
                  <span class="mt-1 text-slate-300 inline">
                    Profit: {{ balS.exchangeAndFormatAmount(totalProfit) }}
                    <img class="h-4 w-4 inline" :src="balS.mainIcon" alt />
                  </span>
                </div>
              </div>
            </div>

            <div class="flex-none mt-2 flex flex-col gap-2">
              <!--              <button  v-if="currentState ==='notStarted'" type="button" v-on:click="clearTable()" class="disabled:opacity-50 text-slate-300 focus:outline-none focus:ring-1 font-medium rounded-lg text-sm p-3 bg-secondary-light hover:opacity-70">
                Clear Table
              </button>
              <button v-if="currentState ==='notStarted'" type="button" id="bet-auto-pick" v-on:click="autoPick()"
                class="text-white focus:outline-none focus:ring-1 w-full font-medium rounded-lg text-sm p-3 bg-secondary-light hover:bg-opacity-70">Auto
                Pick
              </button>-->
              <button
                id="bet-auto-cashout"
                v-on:click="
                  () => (canStopAutoBet ? debouncedStopAutoBet() : debouncedAutoBet())
                "
                :disabled="!canAutoBet && !canStopAutoBet"
                class="btn-bet">
                {{ currentState === 'notStarted' ? 'Start Autoplay' : 'Stop Autoplay' }}
              </button>
            </div>
          </div>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import { defaultConvertor } from '@/lib/rsgp';
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue';
const TABS = ['Manual', 'Auto'];
import { ref } from 'vue';
import { balanceStore } from '@/store/pinia/balanceStore';
import { getBalanceIcon } from '@/lib/icons';
import { BigNumber } from 'bignumber.js';

export default {
  name: 'MinesBets',
  components: {
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
  },
  props: {
    currentState: {},
    loading: {},
    initialWager: {},
    initialMines: {},
    numberOfGems: {},
    noOfSelected: {},
    multiplier: {},
    playerProfit: {
      default: 0,
    },
    gameId: {
      default: 0,
    },
    playHandler: {},
    riskHandler: {},
    autoPick: {},
    clearTable: {},
    startGame: {},
    limits: {},
    nextProfits: {},
    endGame: {},
    setActiveMode: {},
    placeAutoBet: {},
    totalProfit: {},
    autoBetsCount: {},
    stopAutoBet: {},
    game: {},
  },
  setup() {
    const balS = balanceStore();
    balS.init();
    return { tabs: TABS, balS };
  },
  created() {
    this.debouncedPrimaryAction = debounce(this.onPrimaryAction, 800, {
      leading: true,
      trailing: false,
    });
    this.debouncedAutoBet = debounce(this.initAutoBet, 800, {
      leading: true,
      trailing: false,
    });
    this.debouncedStopAutoBet = debounce(this.stopAutoBet, 800, {
      leading: true,
      trailing: false,
    });
  },
  data() {
    return {
      wager: '1.00',
      active: 'Manual',
      selectedTab: 0,
      numOfBets: 0,
      autoBet: false,
      autoBetAmount: 0,
      onWinPercent: 0.0,
      onLossPercent: 0.0,
      onWinSelected: 'Reset',
      increaseOnWin: false,
      onLossSelected: 'Reset',
      increaseOnLoss: false,
      stopOnLoss: '0',
      stopOnProfit: '0',
      profit: 0,
      currency: 'BTC',
      buttonDisabled: false,
      minesCount: 1,
      debouncedPrimaryAction: () => {},
      debouncedAutoBet: () => {},
      debouncedStopAutoBet: () => {},
    };
  },
  deactivated() {
    this.autoBet = false;
  },
  watch: {
    initialWager: {
      handler: function (newW, oldW) {
        if (newW !== 0) {
          //this.wager = this.balS.formatRaw(newW)
        }
      },
      immediate: true,
    },
    initialMines: {
      handler: function (newW, oldW) {
        this.minesCount = newW && newW !== 0 ? newW : this.minesCount;
      },
      immediate: true,
    },
    currentState: function (newState, oldState) {
      this.buttonDisabled = false;
      if (this.autoBet) {
        if (newState === 'finished' || newState === 'failed') {
          if (this.active === 'Manual' || newState === 'failed') {
            this.autoBet = false;
            return;
          }
          if (this.numOfBets <= 0) {
            this.numOfBets = 0;
            this.autoBet = false;
          }

          this.profit += this.playerProfit - this.betAmount;
          if (this.playerWonBet) {
            if (this.onWinSelected === 'Reset') {
              this.wager = this.bals.formatRaw(this.autoBetAmount);
            } else {
              this.scaleWager(1 + Number.parseFloat(this.onWinPercent) / 100.0);
            }
          } else if (this.playerLostBet) {
            if (this.onLossSelected === 'Reset') {
              this.wager = this.bals.formatRaw(this.autoBetAmount);
            } else {
              this.scaleWager(1 + Number.parseFloat(this.onLossPercent) / 100.0);
            }
          }
          if (this.stopOnProfitAmount !== 0 && this.profit >= this.stopOnProfitAmount) {
            this.autoBet = false;
          } else if (
            this.stopOnLossAmount !== 0 &&
            this.profit <= -this.stopOnLossAmount
          ) {
            this.autoBet = false;
          }

          if (this.autoBet && this.numOfBets > 0) {
            this.bet(this.betAmount);
          } else {
            this.autoBet = false;
            this.numOfBets = 0;
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters(['me', 'userBalance']),
    betAmount() {
      return this.balS.reverseExchangeCurrency(this.wager);
    },
    balanceIcon() {
      return getBalanceIcon(this.balS.default);
    },
    stopOnProfitAmount() {
      return this.balS.reverseExchangeCurrency(this.stopOnProfit);
    },
    stopOnLossAmount() {
      return this.balS.reverseExchangeCurrency(this.stopOnLoss);
    },
    playerLostBet() {
      return Number.parseFloat(this.playerProfit) < this.betAmount;
    },
    playerWonBet() {
      return !this.playerLostBet;
    },
    remainingAutoBets() {
      let remaining =
        (!isNaN(this.numOfBets) ? this.numOfBets : 0) -
        (!isNaN(this.autoBetsCount) ? this.autoBetsCount : 0);
      return remaining > 0 ? remaining : 0;
    },
    canAutoBet() {
      return (
        this.noOfSelected > 0 && this.currentState === 'notStarted' && this.numOfBets > 0
      );
    },
    canStopAutoBet() {
      return this.currentState === 'inProgress' && this.active === 'Auto';
    },
    canAutoPick() {
      if (this.active === 'Manual') {
        return this.currentState === 'inProgress';
      } else {
        return false;
      }
      return this.currentState === 'inProgress' && this.active === 'Auto';
    },
    inProgress() {
      return this.currentState === 'inProgress';
    },
  },
  methods: {
    rsgp(x) {
      return this.balS.exchangeCurrency(
        x,
        this.inProgress ? this.game.game_mode : this.balS.default,
      );
    },
    onPrimaryAction() {
      if (this.currentState === 'notStarted') {
        this.placeBet();
      } else if (this.currentState === 'inProgress') {
        this.cashOut();
      }
    },
    placeBet() {
      let error = null;
      if (isNaN(this.betAmount)) {
        error = 'Play amount must be numeric.';
      }

      if (error && error.length > 0) {
        window.swal('Uh-oh', error, 'error');
      } else {
        this.startGame(this.betAmount, this.minesCount);
      }
    },
    cashOut() {
      this.endGame();
    },
    scaleWager(x) {
      try {
        this.wager = this.balS.formatRaw(x * (isNaN(this.wager) ? 0 : this.wager));
      } catch (e) {}
    },
    setOnWin(selected) {
      this.onWinSelected = selected;
      this.increaseOnWin = selected !== 'Reset';
      if (selected === 'Reset') {
        this.onWinPercent = 0;
      }
    },
    setOnLoss(selected) {
      this.onLossSelected = selected;
      this.increaseOnLoss = selected !== 'Reset';
      if (selected === 'Reset') {
        this.onLossPercent = 0;
      }
    },
    setActive(active) {
      if (this.currentState !== 'notStarted') return;
      this.buttonDisabled = false;
      this.active = active;
      this.autoBet = false;
      this.setActiveMode(active);
    },

    async initAutoBet() {
      this.currency = this.balS.default;
      this.autoBetAmount = this.wager;
      await this.placeAutoBet(
        this.betAmount,
        this.minesCount,
        this.numOfBets,
        this.stopOnProfitAmount,
        this.stopOnLossAmount,
        this.onWinPercent,
        this.onLossPercent,
        this.increaseOnWin,
        this.increaseOnLoss,
      );
    },
    bet(amount) {
      this.buttonDisabled = true;
      this.playHandler(amount, this.risk);
      this.numOfBets -= 1;
    },
    setActiveTab(index) {
      const tab = TABS[index];
      this.selectedTab = index;
      this.activeTab = tab;
      this.setActive(tab);
    },
  },
};
</script>
