<template>
  <punt-modal show-close="true" size="max-w-2xl" class="rules_title">
    <template #title>
      <div class="flex flex-row items-center gap-2 text-slate-300/80">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-8 w-8"
          viewBox="0 0 20 20"
          fill="currentColor">
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clip-rule="evenodd" />
        </svg>
        <h1 class="text-lg font-bold">Game ID {{ gameId.toLocaleString() }}</h1>
      </div>
    </template>
    <template #body>
      <div class="modal is-active">
        <div class="modal-card">
          <section class="modal-card-body text-slate-300">
            <div v-if="!game" class="text-center">Loading...</div>
            <div v-else>
              <div class="flex flex-row justify-center mt-8">
                <p class="text-center text-lg">
                  <strong class="inline">
                    {{ rsgp(game.wager) }}
                    <img
                      class="ml-1 inline h-4 w-4"
                      :src="balS.getBalanceIcon(game.game_mode)" />
                  </strong>
                  <span v-if="user?.username != null">
                    played by <strong>{{ user?.username }}</strong></span
                  >
                </p>
              </div>
              <div class="flex flex-row text-center pt-8">
                <div class="flex-1">
                  <h2 class="text-lg font-bold">Play</h2>
                  <h5 class="subtitle is-4">
                    {{ rsgp(game.wager) }}
                    <img
                      class="ml-1 inline h-4 w-4"
                      :src="balS.getBalanceIcon(game.game_mode)" />
                  </h5>
                </div>
                <div class="flex-1">
                  <h2 class="text-lg font-bold">Profit</h2>
                  <h5 class="inline is-4">
                    <span
                      class="inline"
                      :style="{ color: game.profit > 0 ? 'green' : 'red' }"
                      >{{ rsgp(game.profit) }}</span
                    >
                    <img
                      class="ml-1 inline h-4 w-4"
                      :src="balS.getBalanceIcon(game.game_mode)" />
                  </h5>
                </div>
              </div>
              <div>
                <div>
                  <h2 style="font-size: 1.4rem; font-weight: bold; text-align: center">
                    Result
                  </h2>
                  <div class="columns">
                    <div class="column scale-75">
                      <MinesGrid
                        currentState="inProgress"
                        :result="result"
                        :fields="fields"
                        :numberOfSelected="10"
                        :completed="true"
                        :profit="-200"
                        :game_mode="game ? game.game_mode : ''"
                        :multiplier="0"
                        :isManualMode="false" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </template>
  </punt-modal>
</template>

<script>
import { defaultConvertor as rsgp, convertGameMode } from '../../lib/rsgp';
import MinesGrid from './MinesGrid.vue';
import PuntModal from '@/components/Shared/PuntModal.vue';
import { balanceStore } from '@/store/pinia/balanceStore';
import { copyToClipboard } from '@/lib/utils';

const JSBIN = 'https://output.jsbin.com/jixuvup';

export default {
  components: {
    MinesGrid,
    PuntModal,
  },
  setup() {
    return {
      balS: balanceStore(),
    };
  },
  props: {
    gameId: {
      type: [Number, String],
      validator: function (x) {
        return x > 0;
      },
    },
    onClose: {
      type: String,
      required: false,
      default: function () {
        return 'close';
      },
      validator: function (x) {
        return ['close', 'back'].includes(x);
      },
    },
  },
  data() {
    return {
      user: null,
      game: null,
      result: [],
      seed: null,
      error: null,
      fetching: false,
      showVerify: false,
      fields: {
        1: { selected: false, isMine: false },
        2: { selected: false, isMine: false },
        3: { selected: false, isMine: false },
        4: { selected: false, isMine: false },
        5: { selected: false, isMine: false },
        6: { selected: false, isMine: false },
        7: { selected: false, isMine: false },
        8: { selected: false, isMine: false },
        9: { selected: false, isMine: false },
        10: { selected: false, isMine: false },
        11: { selected: false, isMine: false },
        12: { selected: false, isMine: false },
        13: { selected: false, isMine: false },
        14: { selected: false, isMine: false },
        15: { selected: false, isMine: false },
        16: { selected: false, isMine: false },
        17: { selected: false, isMine: false },
        18: { selected: false, isMine: false },
        19: { selected: false, isMine: false },
        20: { selected: false, isMine: false },
        21: { selected: false, isMine: false },
        22: { selected: false, isMine: false },
        23: { selected: false, isMine: false },
        24: { selected: false, isMine: false },
        25: { selected: false, isMine: false },
      },
    };
  },
  created() {
    this.fetchGameInfo();
  },
  computed: {
    currency() {
      return (convertGameMode(this.user.game_mode) || '').toUpperCase();
    },
    interactiveUrl() {
      return `${JSBIN}?cs=${this.seed.client_seed}&ss=${this.seed.server_seed}&nonce=${this.seed.nonce}&mines=${this.game.mines}`;
    },
  },
  methods: {
    closeModal() {
      switch (this.onClose) {
        case 'back':
          this.$router.go(-1);
          break;
        case 'close':
          this.$emit('close');
          break;
      }
    },
    fetchGameInfo() {
      this.fetching = true;
      this.$http
        .get('mines/' + this.gameId)
        .then((res) => res.data)
        .then(({ game, user, seed, result }) => {
          this.game = game;
          this.bets = game.guess;
          this.user = user;
          this.result = result;
          this.seed = seed;

          // build
          for (let i = 1; i <= 25; i++) {
            this.fields[i] = { isMine: false, selected: false, loading: false };
          }
          game.guess.forEach((g) => {
            this.fields[g.field].selected = true;
            this.fields[g.field].isMine = g.mines;
          });

          game.result.forEach((g) => {
            this.fields[g].isMine = true;
          });

          this.fetching = false;
        })
        .catch((err) => {
          this.fetching = false;
          this.error = err.toString();
        });
    },
    copyClient() {
      copyToClipboard(this.seed.client_seed);
    },
    copyServer() {
      copyToClipboard(this.seed.server_seed);
    },
    copyServerHashed() {
      copyToClipboard(this.seed.server_seed_hashed);
    },
    copyNonce() {
      copyToClipboard(this.seed.nonce);
    },
    rsgp(x) {
      return this.balS.exchangeAndFormatAmount(x, this.game.game_mode);
    },
  },
  watch: {
    gameId() {
      this.fetchGameInfo();
    },
  },
};
</script>
