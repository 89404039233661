<template>
  <div>
    <label
      for="bet-manual-amount"
      class="mt-1 flex justify-between mb-1 text-sm font-medium text-text-dim-color">
      Play Amount
    </label>
    <div class="relative">
      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
        <img class="w-4 h-4" :src="balanceIcon" alt />
      </div>
      <input
        v-if="playStarted && game"
        type="text"
        :value="balS.formatCryptoRaw(game.wager, true)"
        disabled
        class="input-bet-amount"
        placeholder="0.0000" />
      <input
        v-else
        type="number"
        id="bet-manual-amount"
        @keydown="onInput"
        :disabled="disabled"
        v-model="userInput"
        class="input-bet-amount"
        placeholder="0.01" />
      <div
        class="flex absolute inset-y-0 right-0 items-center pr-2 space-x-2 pointer-events-auto">
        <button
          type="button"
          v-on:click="multiplyWager(0.5)"
          :disabled="disabled"
          class="btn-multiplier">
          ½
        </button>
        <button
          type="button"
          v-on:click="multiplyWager(2.0)"
          :disabled="disabled"
          class="btn-multiplier">
          2x
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { getBalanceIcon } from '@/lib/icons';
import { balanceStore } from '@/store/pinia/balanceStore';
import { mapGetters } from 'vuex';
import { formatCryptoRaw } from '@/lib/utils';

export default {
  name: 'WagerInput',
  setup() {
    const balS = balanceStore();
    balS.init();
    return { balS };
  },
  data() {
    return { userInput: '0.01' };
  },
  props: {
    disabled: {
      default: false,
    },
    maxValue: {
      default: false,
    },
    game: {
      default: null,
    },
    wagerValue: {
      type: String,
      default: '0.01',
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },
  created() {
    this.onChange(this.userInput);
  },
  computed: {
    ...mapGetters('Blackjack', ['playStarted', 'wager']),
    gameBetAmount() {
      if (!this.game) return 0;
      return this.balS.formatCryptoRaw(this.game.wager, true);
    },
    valid() {
      return true;
    },
    value() {
      let num = '0' + this.userInput;
      num = num.toUpperCase().replace('K', 'e3').replace('M', 'e6').replace('B', 'e9');

      return parseFloat(num);
    },
    balanceIcon() {
      return this.balS.getIcon(
        this.game && this.game.completed === false ? this.game.game_mode : this.balS.mainCurrency,
      );
    },
  },
  watch: {
    wagerValue(n) {
      if (parseFloat(this.userInput) !== parseFloat(n)) {
        this.userInput = n;
      }
    },
    userInput() {
      if (this.valid) {
        this.onChange(this.userInput);
        return;
      }
      this.onChange(0);
    },
  },
  methods: {
    onInput(e) {
      if (this.disabled) {
        return false;
      }
    },
    multiplyWager(x) {
      if (this.disabled) {
        return;
      }
      const desiredWager = x * Number.parseFloat(this.userInput);
      this.userInput = this.balS.formatCryptoRaw(desiredWager, 2);
    },
    processValue(num) {
      if (num >= 1e9) {
        return `${Number((num / 1e9).toFixed(3))}` + 'B';
      }

      if (num >= 1e6) {
        return `${Number((num / 1e6).toFixed(3))}` + 'M';
      }

      if (num >= 1e3) {
        return `${Number((num / 1e3).toFixed(3))}` + 'K';
      }
    },
  },
};
</script>
