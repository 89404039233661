<template>
  <punt-modal show-close="true" size="max-w-3xl" class="rules_title">
    <template #title>
      <h1 class="text-xl font-bold text-[#dfbc00] mb-2 max-[767px]:text-lg">
        Game Rules and Limits
      </h1>
    </template>
    <template #body>
      <div class="modal is-active">
        <div class="modal-background" v-on:click.stop.prevent="closeModal"></div>
        <div class="modal-card">
          <section class="modal-card-body space-y-2 text-slate-300">
            <div v-if="!limits" class="has-text-centered">Loading...</div>
            <div class="flex flex-col max-[767px]:text-sm gap-1" v-else>
              <span class="mt-1 inline text-sm text-white gap-2">
                <strong style="color: white">Minimum play</strong> is
                <strong style="color: white">{{
                  balS.exchangeAndFormatAmount(limits.minBet)
                }}</strong>
                <img
                  :src="balS.getIcon()"
                  alt="Currency Icon"
                  class="inline h-4 w-4 ml-1" />
              </span>
              <span class="mt-1 inline text-sm text-white gap-2">
                <strong style="color: white">Maximum play</strong> is
                <strong style="color: white">{{
                  balS.exchangeAndFormatAmount(limits.maxBet)
                }}</strong>
                <img
                  :src="balS.getIcon()"
                  alt="Currency Icon"
                  class="inline h-4 w-4 ml-1" />
              </span>
              <span class="mt-1 inline text-sm text-white gap-2">
                <strong style="color: white">Maximum payout</strong> is
                <strong style="color: white">{{
                  balS.exchangeAndFormatAmount(limits.maxPayout)
                }}</strong>
                <img
                  :src="balS.getIcon()"
                  alt="Currency Icon"
                  class="inline h-4 w-4 ml-1" />
              </span>
            </div>
          </section>
        </div>
      </div>
    </template>
  </punt-modal>
</template>

<script>
import { defaultConvertor as rsgp } from '../../lib/rsgp';
import PuntModal from '@/components/Shared/PuntModal.vue';
import { BigNumber } from 'bignumber.js';
import { balanceStore } from '@/store/pinia/balanceStore';

export default {
  components: { PuntModal },
  props: {
    onClose: {
      type: String,
      required: false,
      default: function () {
        return 'close';
      },
      validator: function (x) {
        return ['close', 'back'].includes(x);
      },
    },
  },
  setup() {
    return { balS: balanceStore() };
  },
  data() {
    return {
      limits: false,
      error: null,
      fetching: false,
    };
  },
  created() {
    this.fetchLimits();
  },
  methods: {
    closeModal() {
      switch (this.onClose) {
        case 'back':
          this.$router.go(-1);
          break;
        case 'close':
          this.$emit('close');
          break;
      }
    },
    fetchLimits() {
      this.fetching = true;
      this.$http
        .get('roulette/limits')
        .then(({ data }) => {
          let min_bets = new BigNumber(data.minBet);
          let max_bets = new BigNumber(data.maxBet);
          let max_payout = new BigNumber(data.maxPayout);
          data.minBet = min_bets.toFixed();
          data.maxBet = max_bets.toFixed();
          data.maxPayout = max_payout.toFixed();
          this.limits = data;
          this.fetching = false;
        })
        .catch((err) => {
          this.fetching = false;
          this.error = err.toString();
        });
    },
    rsgp(x) {
      return rsgp.format(x);
    },
  },
};
</script>

<style lang="sass">
.dark section.modal-card-body p, .dark section.modal-card-body .subtitle
  color: black
</style>
