<template>
  <div class="text-white p-4 rounded-md" style="background: rgb(17, 34, 45)">
    <div class="columns is-vcentered">
      <div class="column">
        <span class="label is-small is-unselectable">Number of Mines</span>
        <div class="control">
          <div class="select is-half" style="min-width: 150px; margin-top: 0.7em">
            <select
              v-model="noOfMines"
              :disabled="false"
              style="
                background-color: #12222d;
                color: #ffffff;
                border-color: #375262;
                min-width: 150px;
              ">
              <option v-for="i in 24" v-bind:key="i">{{ i }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="mines-paytable-container">
      <div class="mines-paytable-grid">
        <div v-for="(item, index) in payTable" :key="index" class="mines-paytable-item">
          <div>
            <h1 style="opacity: 0.87; font-size: 1.3em; font-style: bold">
              {{ index + 1 }}
            </h1>
          </div>
          <div>
            <div class="mines-items text-center">
              <p style="color: white; opacity: 0.54; font-size: 0.8em">
                {{ new Intl.NumberFormat().format(item.toFixed(2)) + 'x' }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'mines-paytable',
  data() {
    return {
      noOfMines: 1,
      payTable: [],
    };
  },
  created() {
    this.fetchPayTable();
  },
  methods: {
    fetchPayTable() {
      this.$http
        .post(`mines/paytable`, {
          mines: this.noOfMines,
        })
        .then((res) => {
          this.payTable = res.data;
        })
        .catch(() => {
          window.swal('Uh-oh', 'Something went wrong. Please try again.', 'error');
        });
    },
  },
  watch: {
    noOfMines: {
      handler: function () {
        this.fetchPayTable();
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.mines-paytable-container {
  width: 100%;
  display: grid;
  grid-auto-columns: minmax(0px, 1fr);
  justify-items: center;
  padding: 2rem;
  gap: 2em;
}

.mines-paytable-grid {
  display: grid;
  width: 100%;
  grid-template-rows: repeat(5, 1fr);
  grid-template-columns: repeat(5, 1fr);
  background: rgb(17, 34, 45);
  gap: 1rem;
  border-radius: 4px;
  padding: 1rem;
}

.mines-paytable-item > div:first-child {
  margin-bottom: 1rem;
  color: rgb(255, 255, 255);
  text-align: center;
}

.mines-items {
  /* display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(5, 1fr);
  gap: 0.25rem; */
  background: #1a2b32;
  color: white;
  padding: 0.5em;
}

.mine-item {
  width: 10px;
  height: 10px;
  background: #1a2b32;
  border-radius: 2px;
}

.mine-item-white {
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 2px;
}
</style>
