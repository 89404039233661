<template>
  <router-view></router-view>
  <div
    class="flex md:h-auto flex-row justify-center p-0 md:min-h-[auto] md:p-8 main_original_wrapper">
    <div class="w-full lg:max-w-[1200px] max-[767px]:max-w-[400px]">
      <div
        class="flex h-auto flex-col-reverse justify-center md:min-h-[auto] xl:flex-row">
        <!-- Side Menu -->
        <div
          class="w-full min-w-[300px] bg-side-color px-2 py-0 pb-4 md:w-full md:rounded-tl-xl md:px-3 md:py-3 xl:w-[300px]">
          <mines-bets
            :currentState="currentState"
            :playHandler="play"
            :playerProfit="profit"
            :autoPick="debouncedAutoPick"
            :clear-table="resetGame"
            :nextProfits="nextProfits"
            :startGame="startGame"
            :placeAutoBet="placeAutoBet"
            :limits="limits"
            :endGame="cashout"
            :loading="loading"
            :initialWager="wager"
            :initialMines="initialMines"
            :numberOfGems="numberOfGems"
            :noOfSelected="numberOfSelected"
            :setActiveMode="setActiveMode"
            :totalProfit="totalProfit"
            :autoBetsCount="autoBetsCount"
            :game="game"
            :stopAutoBet="stopAutoBet" />
        </div>
        <div
          class="flex flex-col items-center md:h-auto md:min-h-[auto] md:w-full md:rounded-tr-xl">
          <div class="h-full w-full">
            <div class="h-auto w-full p-0 md:p-5 bg-secondary">
              <!-- The Game Grid -->
              <mines-grid
                :currentState="currentState"
                :result="result"
                :stateHandler="changeState"
                :soundHandler="playSound"
                :fields="fields"
                :playNumber="play"
                :numberOfSelected="numberOfSelected"
                :completed="completed"
                :profit="profit"
                :game_mode="game ? game.game_mode : ''"
                :multiplier="multiplier"
                :isManualMode="isManualMode" />
            </div>
          </div>
        </div>
      </div>

      <game-actions
          :game-data="{ name: 'Mines', id: null }"
          :pre-extras="gameActions"
          class="bottom rounded-b-xl bg-secondary" />

      <game-details
        game="Mines"
        edge="1"
        provider="Chanced Originals"
        class="mt-6 md:mt-12" />
    </div>
  </div>
  <game-history-table class="hidden md:flex" />

  <img class="h-0 w-0" src="../../assets/svg/mines/dmd.png" />
  <img class="h-0 w-0" src="../../assets/svg/mines/bomb-lg.png" />
</template>

<script>
import { defaultConvertor } from '../../lib/rsgp';
import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'lodash';
import MinesGrid from './MinesGrid.vue';
import MinesBets from './MinesBets.vue';
import MinesFairness from './MinesFairness.vue';
import { betSound, winSound, missSound, cashoutSound } from './sounds';
import GameHistoryTable from './../GameHistory/BetsHistory.vue';
import GameActions from '../Shared/GameActions.vue';
import GameDetails from '../Shared/GameDetails.vue';

import axios from '@/lib/myAxios';
import { getErrorCodeMessage } from '@/utils';

export default {
  components: {
    MinesGrid,
    MinesBets,
    MinesFairness,
    GameHistoryTable,
    GameActions,
    GameDetails,
  },
  props: {},
  created() {
    this.debouncedAutoPick = debounce(this.autoPick, 50, {
      leading: true,
      trailing: false,
    });
    this.fetcActive();
  },
  data: () => ({
    showFairness: false,
    currentState: 'notStarted',
    activeMode: 'Manual',
    game: null,
    seed: null,
    limits: [],
    result: [],
    gameActions: [
      ['/casino/mines/rules', 'Rules'],
      // ['/casino/mines/fairness', 'Fairness'],
    ],
    fields: {
      1: { selected: false, isMine: false },
      2: { selected: false, isMine: false },
      3: { selected: false, isMine: false },
      4: { selected: false, isMine: false },
      5: { selected: false, isMine: false },
      6: { selected: false, isMine: false },
      7: { selected: false, isMine: false },
      8: { selected: false, isMine: false },
      9: { selected: false, isMine: false },
      10: { selected: false, isMine: false },
      11: { selected: false, isMine: false },
      12: { selected: false, isMine: false },
      13: { selected: false, isMine: false },
      14: { selected: false, isMine: false },
      15: { selected: false, isMine: false },
      16: { selected: false, isMine: false },
      17: { selected: false, isMine: false },
      18: { selected: false, isMine: false },
      19: { selected: false, isMine: false },
      20: { selected: false, isMine: false },
      21: { selected: false, isMine: false },
      22: { selected: false, isMine: false },
      23: { selected: false, isMine: false },
      24: { selected: false, isMine: false },
      25: { selected: false, isMine: false },
    },
    profit: 0,
    nextProfits: null,
    loading: false,
    multiplier: 0,
    wager: 0,
    initialMines: 0,
    totalProfit: 0,
    cancelAutoBet: false,
    autoBetsCount: 0,
    debouncedAutoPick: () => {},
  }),
  computed: {
    ...mapGetters(['me', 'userBalance']),
    ...mapGetters('Mines', ['clientSeed']),
    ...mapGetters('Sound', ['isSoundMuted']),
    isManualMode() {
      return this.activeMode === 'Manual';
    },
    numberOfGems() {
      let result = 0;
      for (let i in this.fields) {
        if (
          this.fields.hasOwnProperty(i) &&
          this.fields[i].selected &&
          !this.fields[i].isMine
        ) {
          result++;
        }
      }
      return result;
    },
    selectedFields() {
      let res = [];
      for (let i = 1; i <= 25; ++i) {
        if (this.fields[i].selected) res.push(i);
      }
      return res;
    },
    numberOfSelected() {
      return this.selectedFields.length;
    },
    completed() {
      return !isNaN(this.profit) && this.profit !== 0;
    },
  },
  methods: {
    ...mapActions('Mines', ['updateNonce']),
    ...mapActions('Sound', ['toggleSound']),
    setActiveMode(mode) {
      this.activeMode = mode;
      // reset auto bet stuff
      this.totalProfit = 0;
      this.autoBetsCount = 0;
      this.resetGame();
    },
    rsgp(x) {
      return defaultConvertor.format(x);
    },
    playSound(sound) {
      if (!this.isSoundMuted) {
        sound.play();
      }
    },
    fetcActive() {
      try
      {
        axios
        .get(`mines`)
        .then((res) => res.data)
        .then((game) => {
          this.initGame(game);
        })
        .catch((e) => {
          const { message } = getErrorCodeMessage(e, 'Unable to fetch mines!');
          this.$swal('Uh-oh', 'Something went wrong: ' + message, 'error');
        });
      } catch(e) {
        console.log('error fetching mines');
      }
    },

    initGame({ game, limits, nextProfits }) {
      // clear grid
      this.resetGame();
      // game
      this.game = game;
      this.currentState = game === null ? 'notStarted' : 'inProgress';
      // update selected
      if (game) {
        game.guess.forEach(({ field, mine }) => {
          this.fields[field].selected = true;
          this.fields[field].isMine = mine;
        });
        this.wager = game.wager;
        this.initialMines = game.mines;
      }

      this.nextProfits = nextProfits;

      // limits
      this.limits = limits;
      // seed
      // this.seed = seed != null ? seed : {};
    },

    // :D
    verify2bo(period) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, period);
      });
    },

    async placeAutoBet(
      wager,
      mines,
      noOfBets,
      stopOnProfit,
      stopOnLoss,
      increaseOnProfit,
      decreaseOnLoss,
      increaseOnWin,
      increaseOnLoss,
    ) {
      let initialWager = wager;

      // get fields
      this.totalProfit = 0;
      this.cancelAutoBet = false;
      this.autoBetsCount = 0;
      const selectedFields = this.selectedFields;
      while (!this.cancelAutoBet) {
        try {
          this.currentState = 'inProgress';
          let response = await axios.post('mines', {
            wager,
            mines,
            fields: selectedFields,
          });
          let game = response.data;
          this.game = game;
          this.profit = 0;
          this.playSound(game.profit > 0 ? winSound : missSound);
          await this.verify2bo(game.profit > 0 ? 400 : 250);
          this.resetGame();
          // update fields
          game.guess.forEach(({ field, mine }) => {
            this.fields[field].selected = true;
            this.fields[field].isMine = mine;
          });

          // wager and mines
          this.wager = game.wager;
          this.initialMines = game.mines;

          // result
          game.result.forEach((field) => {
            this.fields[field].isMine = true;
          });

          // update profit & multiplier
          this.profit = game.profit;
          this.multiplier = game.multiplier;

          this.totalProfit += this.profit;
          this.autoBetsCount += 1;

          // cancel on profit/loss
          if (
            (this.totalProfit >= stopOnProfit && stopOnProfit > 0) ||
            (stopOnLoss > 0 && this.totalProfit <= Math.abs(stopOnLoss) * -1)
          ) {
            this.cancelAutoBet = true;
          }

          if (this.autoBetsCount >= noOfBets) {
            this.cancelAutoBet = true;
          }

          // change wager on profit or loss
          if (increaseOnWin && game.profit > 0 && increaseOnProfit > 0) {
            wager = (wager * (1 + increaseOnProfit / 100)).toFixed(10);
          }

          // reset wager if not increase on win
          if (!increaseOnWin && game.profit > 0) {
            wager = initialWager;
          }

          if (game.profit < 0 && decreaseOnLoss > 0) {
            wager = (wager * (1 + decreaseOnLoss / 100)).toFixed(10);
          }

          // reset on loss
          if (!increaseOnLoss && game.profit < 0) {
            wager = initialWager;
          }

          await this.verify2bo(600);
        } catch (e) {
          this.cancelAutoBet = true;
          const { message } = getErrorCodeMessage(e, 'Unable to place play!');
          this.$swal('Uh-oh', 'Something went wrong: ' + message, 'error');
        }
      }
      await this.verify2bo(1000);
      await this.stopAutoBet();
    },

    async stopAutoBet() {
      this.cancelAutoBet = true;
      this.currentState = 'notStarted';
      await this.verify2bo(1000);
      //this.resetGame()
      this.profit = 0;
    },

    startGame(wager, mines) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      try
      {
        axios
        .post(`mines`, { wager, mines })
        .then((res) => res.data)
        .then((game) => {
          this.initGame(game);
          this.playSound(betSound);
          this.loading = false;
        })
        .catch((e) => {
          const { message } = getErrorCodeMessage(e, 'Unable to start game!');
          if (message === 'Unauthenticated.') {
            this.$router.replace('?tab=login');
          } else {
            this.$swal(
              'Uh-oh',
              'Something went wrong: ' + message,
              'error',
            );
          }
        });
      } catch(e) {
        console.log('error starting the game');
      } finally {
        this.loading = false;
      }
    },
    cashout() {
      if (this.loading) return;
      this.loading = true;
      try
      {
        axios
        .post(`mines/cashout`)
        .then((res) => {
          this.completeGame(res.data);
          this.loading = false;
        })
        .catch((e) => {
          const { message } = getErrorCodeMessage(e, 'Unable to cashout!');
          this.$swal('Uh-oh', 'Something went wrong: ' + message, 'error');
        });
      } catch(e) {
        console.log('error cashing out');
      } finally {
        this.loading = false;
      }
    },
    completeGame(game) {
      this.playSound(cashoutSound);
      this.currentState = 'notStarted';
      // update mines
      game.result.forEach((field) => {
        this.fields[field].isMine = true;
      });
      // update profit & multiplier
      this.profit = game.profit;
      this.multiplier = game.multiplier;
    },
    resetGame() {
      for (let i = 1; i <= 25; i++) {
        this.fields[i].selected = false;
        this.fields[i].isMine = false;
        this.fields[i].loading = false;
      }

      this.profit = 0;
      this.multiplier = null;
    },
    play(pos) {
      if (!this.isManualMode) {
        this.fields[pos].selected = !this.fields[pos].selected;
        return;
      }

      this.fields[pos] = { ...this.fields[pos], loading: true };
      // play bet sound
      this.playSound(betSound);
      axios
        .post(`mines/play`, { field: pos })
        .then((res) => res.data)
        .then(({ field, mine, game, nextProfits }) => {
          this.playSound(mine ? missSound : winSound);

          setTimeout(
            () => {
              this.fields[field].selected = true;
              this.fields[field].isMine = mine;
              this.fields[field].loading = false;
              this.nextProfits = nextProfits;

              // mark game as ended
              if (game !== null) {
                this.completeGame(game);
              }
            },
            this.fields[field].isMine ? 250 : 400,
          );
        })
        .catch((e) => {
          this.fields[pos].loading = false;
          const { message, code } = getErrorCodeMessage(e, 'Unable to play!');
          if (code === 403) {
            this.$swal('Uh-oh', 'Not allowed to play: ' + message , 'error');
          } else {
            if (message === 'Unauthenticated.') {
              this.$router.replace('?tab=login');
            } else {
              this.$swal(
                'Uh-oh',
                'Something went wrong: ' + message,
                'error',
              );
            }
          }
        });
    },
    changeState(newState) {
      this.currentState = newState;
    },
    autoPick() {
      let unselected = [];
      // get unselected and add to list
      for (let i = 1; i <= 25; i++) {
        if (!this.fields[i].selected && !this.fields[i].loading) {
          unselected.push(i);
        }
      }

      if (unselected.length === 0) return;

      // generate random
      let next = Math.floor(Math.random() * unselected.length);
      this.play(unselected[next]);
    },
  },
};
// test
</script>
