<template>
  <div class="flex flex-row md:w-[auto] w-full">
    <TabGroup :selectedIndex="selectedTab" as="div" class="w-full max-w-[1200px]">
      <div class="flex flex-col w-full justify-start">
        <TabList
          class="flex flex-row justify-center md:justify-start w-full md:w-auto space-x-1 sm:space-x-2 bg-primary rounded-xl">
          <Tab v-for="tab in tabs" :key="tab" v-slot="{ selected }" as="template">
            <button
              :class="[
                selected ? 'bg-secondary-light text-white' : 'bg-primary text-slate-600',
                tab === '' ? 'flex-1 border-b-0 pointer-events-none' : '',
              ]"
              class="hidden text-white focus:outline-none w-full font-medium rounded-lg p-3 bg-secondary hover:bg-opacity-70">
              {{ tab }}
            </button>
          </Tab>
        </TabList>
      </div>
      <TabPanels class="md:mt-2">
        <TabPanel key="1">
          <div class="flex flex-col-reverse md:flex-col h-full">
            <div>
              <div class="flex-none mt-1">
                <span class="flex h-[1px] opacity-50 bg-line-color-bright hidden" />
              </div>
              <div class="flex-1 gap-2">
                <div class="grid grid-cols-1 grid-rows-1">
                  <div class="flex flex-row gap-2 max-[767px]:flex-col">
                    <div class="flex-grow max-[767px]:w-full">
                      <div class="relative grid grid-rows-1 grid-cols-1">
                        <span
                          class="mt-1 gap-2 flex text-sm font-medium text-text-dim-color xl:justify-start justify-center xl:mb-1 mb-2">
                          Chip Value <img :src="balanceIcon" class="h-4 w-4" />
                          {{ balS.formatCrypto(selected * oneChip, true) }}
                        </span>
                        <div
                          class="relative flex max-[767px]:border-[1px] max-[767px]:border-[#434343] max-[767px]:rounded-[5px] max-[767px]:justify-around xl:justify-start justify-center">
                          <button
                            :disabled="currentState === 'inProgress'"
                            class="text-text-dim-color focus:outline-none focus:ring-1 font-medium text-sm rounded-l-lg px-3 py-2 bg-secondary-light hover:bg-opacity-70 float-left pointer-events-auto"
                            type="button"
                            v-on:click="doScroll('left')">
                            <svg
                              fill="none"
                              height="15"
                              viewBox="0 0 7 15"
                              width="7"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M4.83336 13.9793C5.08336 14.0303 5.35236 14.0083 5.58136 13.8543C6.03836 13.5483 6.16636 12.9083 5.86136 12.4483L2.24937 7.01128L5.86136 1.57331C6.16635 1.11431 6.03836 0.473304 5.58136 0.167305C5.12336 -0.138694 4.48536 -0.0106886 4.18036 0.44831L0.194379 6.4483C-0.0286209 6.78429 -0.0286208 7.23729 0.194379 7.57329L4.18036 13.5733C4.33236 13.8033 4.58336 13.9293 4.83336 13.9793Z"
                                fill="#69879C" />
                            </svg>
                          </button>
                          <div
                            id="chips-bag"
                            class="bac-chips-wrap-content bg-primary p-1">
                            <div class="bac-chips-wrap-content-slider max-h-10">
                              <rol-chip
                                v-for="(item, i) in chipsValues"
                                :key="i"
                                :color="item.color"
                                :display="item.displayValue"
                                :onclick="onChipSelected"
                                :selected="selected"
                                :value="item.value" />
                            </div>
                          </div>
                          <button
                            :disabled="currentState === 'inProgress'"
                            class="text-text-dim-color focus:outline-none focus:ring-1 font-medium rounded-r-lg text-sm px-3 py-2 inset-x-full bg-secondary-light hover:bg-opacity-70 float-right pointer-events-auto"
                            type="button"
                            v-on:click="doScroll('right')">
                            <svg
                              fill="none"
                              height="15"
                              viewBox="0 0 7 15"
                              width="7"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M2.16664 1.02072C1.91664 0.96972 1.64764 0.99172 1.41864 1.14572C0.961643 1.45172 0.833644 2.09172 1.13864 2.55172L4.75063 7.98872L1.13864 13.4267C0.833644 13.8857 0.961643 14.5267 1.41864 14.8327C1.87664 15.1387 2.51464 15.0107 2.81964 14.5517L6.80562 8.55171C7.02862 8.21571 7.02862 7.76271 6.80562 7.42671L2.81964 1.42672C2.66764 1.19673 2.41664 1.07072 2.16664 1.02072Z"
                                fill="#69879C" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="flex-1 block md:hidden">
                      <button
                        :disabled="totalBets <= 0 || !bettingEnabled"
                        class="btn-bet h-full !mt-0"
                        v-on:click.prevent="spinBall">
                        Play
                      </button>
                    </div>
                  </div>
                </div>

                <div>
                  <label
                    class="mt-2 flex justify-between mb-1 text-sm font-medium text-text-dim-color">
                    Total Amount
                    <span>{{ balS.formatCrypto(totalBets * oneChip, true) }}</span>
                  </label>
                  <div class="relative">
                    <input
                      :value="balS.formatCrypto(totalBets * oneChip, true)"
                      class="input-bet"
                      disabled
                      placeholder="0.0000"
                      readonly
                      type="text" />
                    <div
                      class="flex absolute inset-y-0 right-0 items-center pr-2 space-x-1 pointer-events-auto">
                      <img :src="balanceIcon" class="h-5 w-5" />
                      <button
                        :disabled="autoBet"
                        class="btn-multiplier"
                        type="button"
                        v-on:click="scaleWagers(0.5)">
                        ½
                      </button>
                      <button
                        :disabled="autoBet"
                        class="btn-multiplier"
                        type="button"
                        v-on:click="scaleWagers(2.0)">
                        2x
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full flex-none md:block hidden">
              <button
                :disabled="totalBets <= 0 || !bettingEnabled"
                class="btn-bet"
                v-on:click.prevent="spinBall">
                Play
              </button>
            </div>
          </div>
        </TabPanel>
        <TabPanel key="2">
          <div class="flex flex-col-reverse md:flex-col">
            <div class="flex flex-col h-full">
              <div class="mt-1">
                <span class="flex h-[1px] opacity-50 bg-line-color-bright" />
              </div>
              <div>
                <span
                  class="mt-1 gap-2 flex mb-1 text-sm font-medium text-text-dim-color">
                  Chip Value <img :src="balanceIcon" class="h-4 w-4" />
                  {{ balS.exchangeAndFormatAmount(selected * oneChip, balS.default) }}
                </span>
                <div class="relative flex">
                  <button
                    :disabled="currentState === 'inProgress'"
                    class="text-text-dim-color focus:outline-none focus:ring-1 font-medium text-sm rounded-l-lg px-3 py-2 bg-secondary-light hover:bg-opacity-70 float-left pointer-events-auto"
                    type="button"
                    v-on:click="doScroll('left')">
                    <svg
                      fill="none"
                      height="15"
                      viewBox="0 0 7 15"
                      width="7"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M4.83336 13.9793C5.08336 14.0303 5.35236 14.0083 5.58136 13.8543C6.03836 13.5483 6.16636 12.9083 5.86136 12.4483L2.24937 7.01128L5.86136 1.57331C6.16635 1.11431 6.03836 0.473304 5.58136 0.167305C5.12336 -0.138694 4.48536 -0.0106886 4.18036 0.44831L0.194379 6.4483C-0.0286209 6.78429 -0.0286208 7.23729 0.194379 7.57329L4.18036 13.5733C4.33236 13.8033 4.58336 13.9293 4.83336 13.9793Z"
                        fill="#69879C" />
                    </svg>
                  </button>
                  <div id="chips-bag" class="bac-chips-wrap-content bg-primary p-1">
                    <div class="bac-chips-wrap-content-slider max-h-10">
                      <rol-chip
                        v-for="(item, i) in chipsValues"
                        :key="i"
                        :color="item.color"
                        :display="item.displayValue"
                        :onclick="onChipSelected"
                        :selected="selected"
                        :value="item.value" />
                    </div>
                  </div>
                  <button
                    :disabled="currentState === 'inProgress'"
                    class="text-text-dim-color focus:outline-none focus:ring-1 font-medium rounded-r-lg text-sm px-3 py-2 inset-x-full bg-secondary-light hover:bg-opacity-70 float-right pointer-events-auto"
                    type="button"
                    v-on:click="doScroll('right')">
                    <svg
                      fill="none"
                      height="15"
                      viewBox="0 0 7 15"
                      width="7"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M2.16664 1.02072C1.91664 0.96972 1.64764 0.99172 1.41864 1.14572C0.961643 1.45172 0.833644 2.09172 1.13864 2.55172L4.75063 7.98872L1.13864 13.4267C0.833644 13.8857 0.961643 14.5267 1.41864 14.8327C1.87664 15.1387 2.51464 15.0107 2.81964 14.5517L6.80562 8.55171C7.02862 8.21571 7.02862 7.76271 6.80562 7.42671L2.81964 1.42672C2.66764 1.19673 2.41664 1.07072 2.16664 1.02072Z"
                        fill="#69879C" />
                    </svg>
                  </button>
                </div>
              </div>
              <div>
                <label
                  class="mt-2 flex justify-between mb-1 text-sm font-medium text-text-dim-color">
                  Total Amount
                  <span>{{ balS.formatCrypto(totalBets * oneChip, true) }}</span>
                </label>
                <div class="relative">
                  <input
                    :value="balS.formatCrypto(totalBets * oneChip, true)"
                    class="input-bet"
                    disabled
                    placeholder="0.0000"
                    readonly
                    type="text" />
                  <div
                    class="flex absolute inset-y-0 right-0 items-center pr-2 space-x-1 pointer-events-auto">
                    <button
                      :disabled="autdoBet"
                      class="btn-multiplier"
                      type="button"
                      v-on:click="scaleWagers(0.5)">
                      ½
                    </button>
                    <button
                      :disabled="autoBdet"
                      class="btn-multiplier"
                      type="button"
                      v-on:click="scaleWagers(2.0)">
                      2x
                    </button>
                  </div>
                </div>
              </div>
              <div class="flex-1">
                <div class="py-0.5 md:">
                  <label class="mt-1 block mb-1 text-sm font-medium text-text-dim-color"
                    >Number of Plays</label
                  >
                  <div class="relative">
                    <input
                      v-model="numOfBets"
                      :disabled="autoBet"
                      class="input-bet"
                      placeholder="Number of Plays"
                      type="text" />
                  </div>
                </div>
                <div class="py-0.5 md:">
                  <label class="mt-1 block mb-1 text-sm font-medium text-text-dim-color"
                    >On Win</label
                  >
                  <div class="flex flex-row w-full bg-primary p-0.5 space-x-1 rounded">
                    <button
                      :class="[
                        onWinSelected === 'Reset' ? 'hover:bg-primary' : 'bg-secondary',
                      ]"
                      :disabled="autoBet"
                      class="px-2 text-slate-300 shadow rounded"
                      v-on:click="setOnWin('Reset')">
                      Reset
                    </button>
                    <button
                      :class="[
                        onWinSelected !== 'Reset' ? 'bg-primary' : 'hover:bg-secondary',
                      ]"
                      :disabled="loading"
                      class="px-2 shadow rounded text-slate-300"
                      v-on:click="setOnWin('Inc')">
                      IncreaseBy:
                    </button>
                    <div class="relative flex-1 rounded-xl">
                      <input
                        v-model="onWinPercent"
                        :disabled="autoBet || onWinSelected === 'Reset'"
                        class="input-bet-winloss"
                        max="1000000"
                        min="0"
                        type="text" />
                      <div
                        class="absolute inset-y-0 right-0 flex items-center rounded-r-md m-0.5 box-context">
                        <button class="btn-winloss">%</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="py-0.5 md:">
                  <label class="mt-1 block mb-1 text-sm font-medium text-text-dim-color"
                    >On Loss</label
                  >
                  <div class="flex flex-row w-full bg-primary p-0.5 space-x-1 rounded">
                    <button
                      :class="[
                        onLossSelected === 'Reset' ? 'hover:bg-primary' : 'bg-secondary',
                      ]"
                      :disabled="autoBet"
                      class="px-2 text-slate-300 shadow rounded"
                      v-on:click="setOnLoss('Reset')">
                      Reset
                    </button>
                    <button
                      :class="[
                        onLossSelected !== 'Reset' ? 'bg-primary' : 'hover:bg-secondary',
                      ]"
                      :disabled="autoBet"
                      class="px-2 shadow rounded text-slate-300"
                      v-on:click="setOnLoss('Inc')">
                      IncreaseBy:
                    </button>
                    <div class="relative flex-1">
                      <input
                        v-model="onLossPercent"
                        :disabled="autoBet || onLossSelected === 'Reset'"
                        class="input-bet-winloss"
                        max="1000000"
                        min="0"
                        type="text" />
                      <div
                        class="absolute inset-y-0 right-0 flex items-center rounded-r-md m-0.5 box-context">
                        <button class="btn-winloss">%</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="py-0.5 md:">
                  <label class="mt-1 block mb-1 text-sm font-medium text-text-dim-color"
                    >Stop On Profit</label
                  >
                  <div class="relative">
                    <input
                      v-model="stopOnProfit"
                      :disabled="autoBet"
                      class="input-bet"
                      placeholder="Stop On Profit"
                      type="text" />
                  </div>
                </div>
                <div class="py-0.5 md:">
                  <label class="mt-1 block mb-1 text-sm font-medium text-text-dim-color"
                    >Stop On Loss</label
                  >
                  <div class="relative">
                    <input
                      v-model="stopOnLoss"
                      :disabled="autoBet"
                      class="input-bet"
                      placeholder="Stop On Loss"
                      type="text" />
                  </div>
                </div>
                <div class="mt-2">
                  <span class="mt-1 text-slate-300 inline">
                    Profit: {{ balS.formatAmount(profit) }}
                    <img :src="balS.icon" alt class="h-4 w-4 inline" />
                  </span>
                </div>
              </div>
            </div>
            <div class="flex-none">
              <button
                :disabled="
                  buttonDisabled ||
                  !numOfBets ||
                  Number.parseInt(numOfBets) <= 0 ||
                  (currentState === 'inprogress' && !this.autoBet)
                "
                class="btn-bet"
                type="button"
                v-on:click="spinBall">
                {{
                  currentState === 'inprogress' && this.autoBet
                    ? 'Stop Autoplay'
                    : 'Start Autoplay'
                }}
              </button>
            </div>
          </div>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { defaultConvertor } from '../../lib/rsgp';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue';
import { ref } from 'vue';

//chip
import RolChip from './RoulChip.vue';
import { CHIPS } from './PlayingCards';
import { getBalanceIcon } from '@/lib/icons';
import { balanceStore } from '@/store/pinia/balanceStore';
import { rouletteStore } from '@/store/pinia/rouletteStore';

const TABS = ['Manual' /*, 'Auto'*/];

export default {
  name: 'RouletteBets',
  components: {
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    RolChip,
  },
  props: {
    currentState: {},
    playerProfit: {},
    gameId: {
      default: 0,
    },
    spinBall: {},
    updateSelectedChipValue: {},
    disabled: {
      default: false,
    },
    maxValue: {
      default: false,
    },
    chips: {
      default: 0,
    },
  },
  data() {
    return {
      wager: '5K',
      selected: 1e6,
      numOfLines: '20',
      active: 'Manual',
      numOfBets: 0,
      autoBet: false,
      autoBetAmount: 0,
      onWinPercent: 0.0,
      onLossPercent: 0.0,
      onWinSelected: 'Reset',
      onLossSelected: 'Reset',
      stopOnLoss: '0',
      stopOnProfit: '0',
      profit: 0,
      buttonDisabled: false,
      chipsValues: CHIPS,
      //wager
      number: '1',
      modifier: '',
    };
  },
  setup() {
    let selectedTab = ref(0);
    const balS = balanceStore();
    const rouletteS = rouletteStore();
    const oneChip = 1 / 1e8;
    balS.init();
    return { tabs: TABS, selectedTab, balS, rouletteS, oneChip };
  },
  mounted() {
    this.scaleWager(1);
  },
  deactivated() {
    this.autoBet = false;
  },
  watch: {
    currentState: function (newState, oldState) {
      this.buttonDisabled = false;
      if (this.autoBet) {
        if (newState === 'finished' || newState === 'failed') {
          if (this.active === 'Manual' || newState === 'failed') {
            this.autoBet = false;
            return;
          }
          if (this.numOfBets <= 0) {
            this.numOfBets = 0;
            this.autoBet = false;
          }

          this.profit += this.playerProfit - this.betAmount;
          if (this.playerWonBet) {
            if (this.onWinSelected === 'Reset') {
              this.wager = this.rsgp(this.autoBetAmount);
            } else {
              this.scaleWager(1 + Number.parseFloat(this.onWinPercent) / 100.0);
            }
          } else if (this.playerLostBet) {
            if (this.onLossSelected === 'Reset') {
              this.wager = this.rsgp(this.autoBetAmount);
            } else {
              this.scaleWager(1 + Number.parseFloat(this.onLossPercent) / 100.0);
            }
          }

          if (this.numOfBets > 0) {
            this.bet(this.betAmount);
          } else {
            this.autoBet = false;
            this.numOfBets = 0;
          }
        }

        if (this.stopOnProfitAmount !== 0 && this.profit >= this.stopOnProfitAmount) {
          this.autoBet = false;
        } else if (this.stopOnLossAmount !== 0 && this.profit <= -this.stopOnLossAmount) {
          this.autoBet = false;
        }
      }
    },
    number: function (n, o) {
      if (n < 0) {
        this.number = o;
      }
      this.$emit('change', { value: this.value });
    },
    modifier: function () {
      this.$emit('change', { value: this.value });
    },
  },
  computed: {
    ...mapGetters(['me', 'userBalance']),
    ...mapGetters('Roulette', [
      'totalBets',
      'placedBets',
      'removingBets',
      'bettingEnabled',
    ]),
    betAmount() {
      return defaultConvertor.parse(this.wager);
    },
    totalWager() {},
    balanceIcon() {
      return getBalanceIcon(this.balS.mainCurrency);
    },
    stopOnProfitAmount() {
      return defaultConvertor.parse(this.stopOnProfit).amount;
    },
    stopOnLossAmount() {
      return defaultConvertor.parse(this.stopOnLoss).amount;
    },
    playerLostBet() {
      return Number.parseFloat(this.playerProfit) < this.betAmount;
    },
    playerWonBet() {
      return !this.playerLostBet;
    },
    //wager
    multiplier: function () {
      if (this.modifier === 'K') {
        return 1e3;
      }

      if (this.modifier === 'M') {
        return 1e6;
      }

      if (this.modifier === 'B') {
        return 1e9;
      }

      return 1;
    },
    value: function () {
      let num =
        this.number === undefined || this.number === null || this.number.length === 0
          ? '0'
          : this.number;
      return parseFloat(num) * this.multiplier;
    },
  },
  methods: {
    ...mapActions('Roulette', ['scaleWagers', 'executePlay']),
    rsgp(x) {
      return defaultConvertor.format(x);
    },
    scaleWager(x) {
      const desiredWager = x * this.betAmount;
      this.wager = this.rsgp(desiredWager);
    },
    setOnWin(selected) {
      this.onWinSelected = selected;
    },
    setOnLoss(selected) {
      this.onLossSelected = selected;
    },
    setActive(active) {
      this.active = active;
      this.autoBet = false;
    },
    initAutoBet(cnt) {
      if (this.autoBet) {
        this.autoBet = false;
      } else if ((cnt || this.numOfBets) > 0) {
        if (cnt) {
          this.numOfBets = cnt;
        }
        this.profit = 0;
        this.autoBet = true;
        this.autoBetAmount = this.betAmount;
        this.bet(this.betAmount);
      }
    },
    bet(amount) {
      this.buttonDisabled = true;
      this.playHandler(amount, this.numOfLines);
      this.numOfBets -= 1;
    },
    doScroll(direction) {
      let amount = 150 * (direction === 'left' ? -1 : 1);
      document.getElementById('chips-bag').scrollLeft += amount;
    },
    onChipSelected(value) {
      this.selected = value;
      this.rouletteS.selectedChip = value;
      this.updateSelectedChipValue(value);
    },
    //scale wager
    processNumber(num) {
      if (num >= 1e9) {
        this.number = `${num / 1e9}` + 'B';
        this.modifier = 'B';
      } else if (num >= 1e6) {
        this.number = `${num / 1e6}` + 'M';
        this.modifier = 'M';
      } else if (num >= 1e3) {
        this.number = `${num / 1e3}` + 'K';
        this.modifier = 'K';
      } else {
        this.number = `${num}`;
        this.modifier = '';
      }
    },
    onInput(e) {
      if (this.disabled) {
        return;
      }

      const allowedKeys = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '.',
        'k',
        'K',
        'm',
        'M',
        'b',
        'B',
        'Backspace',
      ];

      let key = e.key || e.keyCode;

      if (['ArrowUp', '+'].includes(key)) {
        this.processNumber(this.value + this.multiplier);
      }

      if (['ArrowDown', '-'].includes(key)) {
        if (this.value > this.multiplier) {
          this.processNumber(this.value - this.multiplier);
        } else {
          let nextMultiplier = this.multiplier / 1e3;
          this.processNumber(this.value - (nextMultiplier > 1 ? nextMultiplier : 1));
        }
      }

      if (!allowedKeys.includes(key)) {
        return;
      }

      if (key === 'k' || key === 'K') {
        this.modifier = 'K';
        return;
      }

      if (key === 'm' || key === 'M') {
        this.modifier = 'M';
        return;
      }

      if (key === 'b' || key === 'B') {
        this.modifier = 'B';
        return;
      }

      if (key === '.') {
        if (!this.number.includes('.')) {
          this.number = `${this.number}.`;
        }
        return;
      }

      if (key === 'Backspace') {
        if (this.number.length === 1) {
          this.number = '';
        }

        if (this.number.length === 0) {
          this.number = '';
          this.modifier = '';
        }

        this.number = this.number.slice(0, -1);
        return;
      }

      if (this.number.length >= 6) {
        return;
      }

      this.number = `${this.number}${key}`;
    },
    multiplyWager(x) {
      /* if (this.disabled) {
         return
       }

       if ((this.chips * this.value * x) > this.maxValue) {
         return
       }*/

      let num = Math.round(this.value * x * 100) / 100;
      this.processNumber(num);
    },
    //from old roulette
    onBallDrop() {
      this.animateBall();
      //this.playSound(spinningSound)

      setTimeout(() => {
        //this.playSound(bouncingSound)
      }, 3000);
    },
    onBallStopped() {
      const vm = this;

      vm.enableBetting();
      vm.showWinningNumber = true;

      vm.winningNumbers.push(vm.finalPositionOfBall);
      this.backupBets();
      this.clearBets();

      //this.playSound(winSound)

      this.finalPositionOfBallOnTheWheel = -1;
    },
    updateBetsPerChip(e) {
      this.betsPerChip(e.value);
    },
    onChipsPlacedOrRemoved() {
      //this.playSound(placeSound)
    },
    playSound(sound) {
      if (!this.isSoundMuted) {
        sound.play();
      }
    },
  },
};
</script>

<style scoped>
.bac-chips-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  max-width: 100%;
  width: 100%;
}

.bac-chips-wrap-content {
  display: flex;
  align-items: center;
  gap: 3px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.bac-chips-wrap-content-slider {
  display: flex;
  align-items: center;
  padding: 0.5em;
}
</style>
