<template>
  <div
    class="h-auto max-w-[100%] md:min-h-[auto] flex flex-col p-0 items-center child:max-w-[1600p]">
    <div class="w-full">
      <PHeader />
      <main-section />
      <CasinoGames />
      <LatestWinner />
      <RealPeople />
      <Testimonials />
      <play-for-cash />
      <PFooter />
      <!--      <faqs :show-all="false" />-->
    </div>
  </div>
</template>

<script>
import Auth from '@/components/Home/Auth.vue';
import { mapGetters } from 'vuex';
import MainSection from '@/components/Home/Landingpage/MainSection.vue';
import PlayForFun from '@/components/Home/Landingpage/PlayForFun.vue';
import PlayForCash from '@/components/Home/Landingpage/PlayForCash.vue';
import Tournaments from '@/components/Home/Promotions.vue';
import CasinoGames from '@/components/Home/Landingpage/CasinoGames.vue';
import LatestWinner from '@/components/Home/Landingpage/LatestWinner.vue';
import RealPeople from '@/components/Home/Landingpage/RealPeople.vue';
import Testimonials from '@/components/Home/Landingpage/Testimonials.vue';
import PHeader from '@/components/Home/Landingpage/PHeader.vue';
import PFooter from '@/components/Home/Landingpage/PFooter.vue';

export default {
  name: 'landing',
  components: {
    Tournaments,
    PlayForCash,
    PlayForFun,
    MainSection,
    CasinoGames,
    LatestWinner,
    RealPeople,
    Testimonials,
    PHeader,
    PFooter,
    Auth,
  },
  computed: {
    ...mapGetters(['activeRoom', 'me', 'isLoggedIn']),
  },
  mounted() {
    if (this.$route.query.aid) {
      this.$router.push(`/c/${this.$route.query.aid}`);
    }
    let section = this.$router.currentRoute.value.hash.replace('#', '');
    if (section)
      this.$nextTick(() => window.document.getElementById(section)?.scrollIntoView());
  },
  data() {
    return {
      loading: true,
      games: [],
    };
  },
  watch: {
    '$route.hash': {
      handler: function (hash) {
        hash = hash.length === 0 ? 'main' : hash.replace('#', '');
        document.getElementById(hash)?.scrollIntoView();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style>
@import '@/components/Home/Landingpage/style.css';
</style>
