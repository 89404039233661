<template>
  <div class="flex flex-row md:w-[auto] w-full">
    <TabGroup
      :selectedIndex="selectedTab"
      @change="changeTab"
      as="div"
      class="flex flex-col-reverse md:flex-col w-full max-w-[650px]">
      <div class="flex flex-col w-full justify-start">
        <TabList
          class="flex flex-row mt-2 md:mt-0 justify-center md:justify-start w-full md:w-auto space-x-1 sm:space-x-2 bg-primary rounded-xl">
          <Tab v-for="tab in tabs" :key="tab" v-slot="{ selected }" as="template">
            <button
              :class="[
                selected ? 'bg-secondary-light text-white' : 'bg-primary text-slate-600',
                tab === '' ? 'flex-1 border-b-0 pointer-events-none' : '',
              ]"
              class="text-white focus:outline-none w-full font-medium rounded-lg p-3 bg-secondary-light hover:bg-opacity-70">
              {{ tab }}
            </button>
          </Tab>
        </TabList>
      </div>
      <TabPanels class="mt-1">
        <TabPanel key="1">
          <div>
            <div
              class="md:p-10 flex flex-col-reverse md:flex-col"
              v-if="duelS.duels.length === 0">
              <div class="pt-2">
                <p style="text-align: center">There are currently no active duels.</p>
              </div>
              <div class="md:pt-5">
                <button v-on:click="startDuel" class="btn-bet">Start Duel</button>
              </div>
            </div>
            <perfect-scrollbar
              v-else
              style="min-width: 100%; max-height: 700px; overflow-y: scroll"
              class="w-full pt-0 md:pt-2">
              <div
                v-for="(item, i) in duelS.duels"
                :key="i"
                class="p-2 md:p-3 rounded-lg flex items-center justify-between"
                :style="'background:' + (i % 2 === 0 ? '#11222E' : '#192C38')">
                <div class="">
                  <div>
                    <div class="flex flex-row justify-start items-center text-sm gap-2">
                      {{ balS.exchangeAndFormatAmount(item.wager, item.game_mode) }}
                      <img class="h-4 w-4" :src="balS.getBalanceIcon(item.game_mode)" />
                    </div>
                    <p class="opacity-75 font-sm">
                      <span class="flex justify-start flex-row opacity-60">
                        {{
                          (!item.host.username
                            ? item.host.id === me.id
                              ? me.username
                              : 'Hidden'
                            : item.host.username) + ' '
                        }}
                        <img
                          v-if="!item.host.username && item.host.id !== me.id"
                          style="width: 8px; margin-left: 5px; opacity: 0.54"
                          src="../../assets/svg/ic_ghost_mode.svg"
                          alt="incognito logo" />
                        <span style="opacity: 0.54; font-size: 0.8em"> </span>
                      </span>
                    </p>
                  </div>
                </div>

                <div class="">
                  <button
                    v-on:click="spectateDuel(item, true)"
                    class="btn-bet px-2 py-2 text-xs md:text-sm !mt-0">
                    View
                  </button>
                </div>
              </div>
            </perfect-scrollbar>
          </div>
        </TabPanel>
        <TabPanel key="2">
          <div class="flex flex-col-reverse md:flex-col h-full justify-between">
            <div class="mt-1">
              <span class="flex h-[1px] opacity-50 bg-line-color-bright" />
            </div>
            <div class="flex flex-col">
              <div class="grow">
                <label
                  for="bet-manual-amount"
                  class="mt-1 flex justify-between mb-1 text-sm font-medium text-text-dim-color">
                  Play Amount
                  <span v-if="duelS.currentState === 'inProgress'">
                    {{ balS.formatWithSymbol(gameBetAmount, !balS.displayInFiat) }}
                  </span>
                  <span v-else>{{ balS.formatWagerWithSymbol(wager) }}</span>
                </label>
                <div class="relative">
                  <div
                    class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <img class="w-4 h-4" :src="balanceIcon" alt />
                  </div>
                  <input
                    v-if="duelS.currentState === 'inProgress'"
                    type="text"
                    :value="
                      balS.exchangeAndFormatAmount(
                        duelS.current.wager,
                        duelS.current.game_mode,
                      )
                    "
                    disabled
                    class="input-bet-amount"
                    placeholder="0.0000" />
                  <input
                    v-else
                    type="number"
                    id="bet-manual-amount"
                    v-model="wager"
                    :disabled="duelS.currentState !== 'notStarted'"
                    class="input-bet-amount"
                    placeholder="0.0000" />
                  <div
                    class="flex absolute inset-y-0 right-0 items-center pr-2 space-x-2 pointer-events-auto">
                    <button
                      type="button"
                      v-on:click="scaleWager(0.5)"
                      :disabled="duelS.currentState !== 'notStarted'"
                      class="btn-multiplier">
                      ½
                    </button>
                    <button
                      type="button"
                      v-on:click="scaleWager(2.0)"
                      :disabled="duelS.currentState !== 'notStarted'"
                      class="btn-multiplier">
                      2x
                    </button>
                  </div>
                </div>

                <div
                  v-if="
                    active === 'NewBet' &&
                    (duelS.currentState === 'notStarted' ||
                      (duelS.currentState === 'inProgress' && !duelS.joined))
                  ">
                  <label
                    for="in-seed"
                    class="mt-1 block MBX text-sm font-medium text-text-dim-color"
                    >Your Seed</label
                  >
                  <div class="relative">
                    <div
                      class="flex absolute inset-y-0 left-0 items-center pointer-events-none"></div>
                    <input
                      type="text"
                      id="in-seed"
                      v-model="client_seed"
                      :disabled="canCancel"
                      class="input-bet pr-[5rem]"
                      placeholder="0.0000" />
                    <div
                      class="flex absolute inset-y-0 right-0 items-center pr-2 space-x-2 pointer-events-auto">
                      <button
                        type="button"
                        v-on:click="generateSeed()"
                        :disabled="canCancel"
                        class="btn-multiplier">
                        Change
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  v-if="
                    active === 'NewBet' &&
                    duelS.current !== null &&
                    duelS.currentState === 'inProgress'
                  ">
                  <label
                    for="in-seed"
                    class="mt-1 block MBX text-sm font-medium text-text-dim-color">
                    Host Seed Hash
                  </label>
                  <div class="relative">
                    <div
                      class="flex absolute inset-y-0 left-0 items-center pointer-events-none"></div>
                    <input
                      type="text"
                      :value="duelS.current.seed.host_seed_hash"
                      readonly
                      class="bg-primary h-10 placeholder:text-text-dim-color border border-primary text-slate-300 text-sm rounded-xl font-semibold focus:ring-green-600 focus:border-green-600 block w-full pr-16 p-2.5"
                      placeholder="0.0000" />
                    <div
                      class="flex absolute inset-y-0 right-0 items-center pr-2 space-x-2 pointer-events-auto">
                      <button
                        type="button"
                        v-on:click="copyToClipboard(duelS.current.seed.host_seed_hash)"
                        class="btn-multiplier">
                        Copy
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-1 md:mt-3 gap-2 flex flex-row md:flex-col">
              <div
                v-if="
                  !duelS.current || canBetOrJoin || (duelS.current && duelS.current.guest)
                "
                class="flex-1">
                <button
                  v-on:click="debouncedPrimaryAction()"
                  :disabled="duelS.loading"
                  class="btn-bet mt-0">
                  balS.mainIco {{ canStartNewOrJoin ? 'Join' : 'Play' }}
                </button>
              </div>

              <div v-if="canStartNewOrJoin || canCancel" class="flex-1">
                <button
                  v-on:click="cancelOrStartNew"
                  :disabled="duelS.loading"
                  class="bg-secondary-light w-full mt-0 md:mt-2 p-3 shadow text-slate-300 font-semibold rounded-md flex flex-row items-center justify-center gap-2 disabled:bg-secondary-light/40 hover:bg-secondary-light/80">
                  {{ canCancel ? 'Cancel Duel' : 'Start New' }}
                </button>
              </div>
            </div>
          </div>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import { generateHexString } from '@/lib/random';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue';
import { ref } from 'vue';
import { getBalanceIcon } from '@/lib/icons';
import { balanceStore } from '@/store/pinia/balanceStore';
import { duelStore } from '@/store/pinia/duelStore';

const TABS = ['Lobby', 'New Play'];
export default {
  name: 'DuelBets',
  components: {
    PerfectScrollbar,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
  },
  props: {
    currentState: {},
    loading: {},
    gameId: {
      default: 0,
    },
    startGame: {},
    limits: {},
    joinDuel: {},
    spectateDuel: {},
    cancelDuel: {},
  },
  setup() {
    let selectedTab = ref(0);
    const balS = balanceStore();
    const duelS = duelStore();
    return { tabs: TABS, selectedTab, balS, duelS };
  },
  created() {
    this.debouncedPrimaryAction = debounce(this.onPrimaryAction, 300, {
      leading: true,
      trailing: false,
    });
    this.generateSeed();
  },
  data() {
    return {
      wager: '0.0001',
      active: 'Lobby',
      Bet: false,
      buttonDisabled: false,
      client_seed: '',
      debouncedPrimaryAction: () => {},
      debouncedBet: () => {},
    };
  },
  mounted() {
    this.scaleWager(1);
  },
  deactivated() {
    this.Bet = false;
  },
  watch: {
    'duelS.currentState': function (newState, oldState) {
      if (newState === 'notStarted') {
        this.generateSeed();
      }
    },
    'duelS.activeMode': {
      handler: function (newState) {
        if (newState) {
          this.active = newState;
          this.setActiveTab(newState === 'Lobby' ? 0 : 1);
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(['me', 'userBalance']),
    betAmount() {
      return this.balS.reverseExchangeCurrency(this.wager);
    },
    gameBetAmount() {
      if (!this.duelS.current) return 0;
      if (this.balS.displayInFiat) return this.duelS.current.wager;
      const amount = this.balS.cryptoToFiat(
        this.duelS.current.game_mode,
        this.balS.defaultFiat,
        this.duelS.current.wager,
      );
      return amount;
    },
    balanceIcon() {
      return this.balS.getIcon(
        this.inProgress ? this.duelS.current.game_mode : this.balS.default,
      );
    },
    inProgress() {
      return this.duelS.currentState === 'inProgress';
    },
    canStartNewOrJoin() {
      return this.duelS.current && !this.duelS.joined && !this.duelS.current.guest;
    },
    canBetOrJoin() {
      return (
        this.duelS.current &&
        !this.duelS.joined &&
        !this.duelS.current.guest &&
        this.inProgress
      );
    },
    canCancel() {
      return this.duelS.joined && !this.duelS.current.guest;
    },
  },
  methods: {
    generateSeed() {
      this.client_seed = generateHexString(30);
    },
    setActive(active) {
      // if (this.duelS.currentState !== 'notStarted') return
      this.active = active;
      this.Bet = false;
      this.activeMode = active;
      this.duelS.activeMode = active;
    },
    changeTab(index) {
      this.selectedTab = index;
      const tabs = ['Lobby', 'NewBet'];
      this.setActive(tabs[index]);
    },
    startDuel() {
      this.duelS.current = null;
      this.duelS.currentState = 'notStarted';
      this.changeTab(1);
    },
    setActiveTab(index) {
      this.selectedTab = index;
    },
    cancelOrStartNew() {
      if (this.canCancel) {
        this.cancelDuel();
      } else {
        this.startDuel();
      }
    },
    rsgp(x) {
      return this.balS.exchangeCurrency(
        x,
        this.inProgress ? this.duelS.current.game_mode : this.balS.default,
      );
    },
    copyToClipboard(text) {
      if (window.clipboardData && window.clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData('Text', text);
      } else if (
        document.queryCommandSupported &&
        document.queryCommandSupported('copy')
      ) {
        var textarea = document.createElement('textarea');
        textarea.textContent = text;
        textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
          document.execCommand('copy'); // Security exception may be thrown by some browsers.
          window
            .swal({
              showConfirmButton: false,
              timer: 1500,
              type: 'success',
              title: 'Copied',
            })
            .then(
              () => {},
              () => {},
            );
        } catch (ex) {
          console.warn('Copy to clipboard failed.', ex);
          return false;
        } finally {
          document.body.removeChild(textarea);
        }
      }
    },
    onPrimaryAction() {
      if (this.duelS.currentState === 'notStarted') {
        this.placeBet();
      } else {
        if (!this.duelS.joined) {
          this.placeBet(true);
        }
      }
    },
    placeBet(isJoin = false) {
      let error = null;
      if (this.client_seed.length === 0) {
        error = 'Your seed cannot be empty.';
      }
      if (error && error.length > 0) {
        window.swal('Uh-oh', error, 'error');
      } else {
        if (!isJoin) {
          this.startGame(this.betAmount, this.client_seed);
        } else {
          this.joinDuel(this.duelS.current.id, this.client_seed);
        }
      }
    },
    scaleWager(x) {
      try {
        this.wager = this.balS.formatAmount(
          x * (isNaN(this.wager) ? 0 : this.wager),
          false,
        );
      } catch (e) {}
    },
  },
};
</script>
