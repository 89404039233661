<template>
  <div class="flex flex-row md:w-[auto] w-full h-full">
    <TabGroup
      :selectedIndex="selectedTab"
      as="div"
      class="plinkoSideMenu w-full flex flex-col max-w-[650px]">
      <div class="flex flex-col w-full justify-start">
        <TabList
          v-if="value1 > 768"
          class="flex flex-row justify-center md:justify-start w-full md:w-auto space-x-1 sm:space-x-2 bg-primary rounded-xl">
          <Tab
            v-for="tab in tabs"
            :disabled="currentState === 'inProgress'"
            :key="tab"
            v-slot="{ selected }"
            as="template">
            <button
              v-on:click="setActiveTab(tab)"
              :class="[
                selected ? 'bg-secondary-light text-white' : 'bg-primary text-slate-600',
                tab === '' ? 'flex-1 border-b-0 pointer-events-none' : '',
              ]"
              class="text-white focus:outline-none flex-1 font-medium rounded-lg p-1 m-2 bg-secondary hover:bg-opacity-70 disabled:opacity-80">
              {{ tab }}
            </button>
          </Tab>
        </TabList>
      </div>
      <TabPanels class="mt-1 mb-4">
        <TabPanel key="1">
          <div class="flex flex-col-reverse md:flex-col justify-between">
            <div class="h-full">
              <div class="mt-1">
                <span class="flex h-[1px] opacity-50 bg-line-color-bright" />
              </div>

              <div class="grow">
                <!-- dvchagdfhsgfhjsfhg -->
                <div v-if="value1 > 460">
                  <label
                    for="bet-manual-amount"
                    class="mt-1 flex justify-between mb-1 text-sm font-medium text-text-dim-color">
                    Play Amount
                  </label>
                  <div class="relative">
                    <div
                      class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                      <img class="h-5 w-5" :src="balanceIcon" alt />
                    </div>
                    <input
                      type="number"
                      id="bet-manual-amount"
                      v-model="wager"
                      :disabled="currentState !== 'notStarted'"
                      class="input-bet-amount"
                      placeholder="0.0000" />
                    <div
                      class="flex absolute inset-y-0 right-0 items-center pr-2 space-x-2 pointer-events-auto">
                      <button
                        type="button"
                        v-on:click="scaleWager(0.5)"
                        :disabled="
                          currentState === 'inProgress' || currentState !== 'notStarted'
                        "
                        class="btn-multiplier">
                        ½
                      </button>
                      <button
                        type="button"
                        v-on:click="scaleWager(2.0)"
                        :disabled="
                          currentState === 'inProgress' || currentState !== 'notStarted'
                        "
                        class="btn-multiplier">
                        2x
                      </button>
                    </div>
                  </div>
                </div>
                <!-- sdhvjasvdfjsjvfdh -->
                <label class="mt-1 block MBX text-sm font-medium text-text-dim-color"
                  >Risk</label
                >
                <select
                  v-model="gameRisk"
                  :disabled="
                    currentState === 'inProgress' || currentState !== 'notStarted'
                  "
                  class="input-bet-select">
                  <option>LOW</option>
                  <option>MEDIUM</option>
                  <option>HIGH</option>
                </select>

                <label class="mt-1 block MBX text-sm font-medium text-text-dim-color"
                  >Rows</label
                >
                <select
                  v-model="rowsCount"
                  :disabled="
                    currentState === 'inProgress' || currentState !== 'notStarted'
                  "
                  class="input-bet-select">
                  <option v-for="i in 9" v-bind:key="i">
                    {{ i + 7 }}
                  </option>
                </select>
              </div>
            </div>
            <div class="flex">
              <!-- nsfbdfhdshf -->

              <div class="block" v-if="value1 < 460">
                <label
                  for="bet-manual-amount"
                  class="mt-1 flex justify-between mb-1 text-sm font-medium text-text-dim-color">
                  Play Amount
                </label>
                <div class="relative">
                  <div
                    class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <img class="h-5 w-5" :src="balanceIcon" alt />
                  </div>
                  <input
                    type="number"
                    id="bet-manual-amount"
                    v-model="wager"
                    class="input-bet-amount"
                    placeholder="0.0000" />
                  <div
                    class="flex absolute inset-y-0 right-0 items-center pr-2 space-x-2 pointer-events-auto">
                    <button
                      type="button"
                      v-on:click="scaleWager(0.5)"
                      :disabled="
                        currentState === 'inProgress' || currentState !== 'notStarted'
                      "
                      class="btn-multiplier">
                      ½
                    </button>
                    <button
                      type="button"
                      v-on:click="scaleWager(2.0)"
                      :disabled="
                        currentState === 'inProgress' || currentState !== 'notStarted'
                      "
                      class="btn-multiplier">
                      2x
                    </button>
                  </div>
                </div>
              </div>

              <!-- jasvdjahvdfhvag -->

              <div class="flex-none mt-1 plinkoBetButton">
                <button
                  v-on:click="debouncedPrimaryAction"
                  :disabled="loading || buttonDisabled || currentState === 'picking'"
                  class="btn-bet">
                  Play
                </button>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel key="2" class="h-full">
          <div class="flex flex-col-reverse md:flex-col">
            <div class="flex flex-col h-full">
              <div class="mt-1">
                <span class="flex h-[1px] opacity-50 bg-line-color-bright" />
              </div>
              <div class="flex-none">
                <label
                  for="bet-manual-amount"
                  class="mt-1 flex justify-between mb-1 text-sm font-medium text-text-dim-color">
                  Play Amount
                </label>
                <div class="relative">
                  <div
                    class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <img class="h-5 w-5" :src="balanceIcon" alt />
                  </div>
                  <input
                    type="number"
                    id="bet-manual-amount"
                    v-model="wager"
                    class="input-bet-amount"
                    placeholder="0.0000" />
                  <div
                    class="flex absolute inset-y-0 right-0 items-center pr-2 space-x-2 pointer-events-auto">
                    <button
                      type="button"
                      v-on:click="scaleWager(0.5)"
                      :disabled="
                        currentState === 'inProgress' || currentState !== 'notStarted'
                      "
                      class="btn-multiplier">
                      ½
                    </button>
                    <button
                      type="button"
                      v-on:click="scaleWager(2.0)"
                      :disabled="
                        currentState === 'inProgress' || currentState !== 'notStarted'
                      "
                      class="btn-multiplier">
                      2x
                    </button>
                  </div>
                </div>
              </div>
              <div class="flex-1">
                <label class="mt-1 block MBX text-sm font-medium text-text-dim-color"
                  >Risk</label
                >
                <select
                  v-model="gameRisk"
                  :disabled="
                    currentState === 'inProgress' || currentState !== 'notStarted'
                  "
                  class="input-bet-select">
                  <option>LOW</option>
                  <option>MEDIUM</option>
                  <option>HIGH</option>
                </select>

                <label class="mt-1 block MBX text-sm font-medium text-text-dim-color"
                  >Rows</label
                >
                <select
                  v-model="rowsCount"
                  :disabled="
                    currentState === 'inProgress' || currentState !== 'notStarted'
                  "
                  class="input-bet-select">
                  <option v-for="i in 9" v-bind:key="i">
                    {{ i + 7 }}
                  </option>
                </select>
                <div class="py-0.5 md:py-1">
                  <label class="mt-1 block MBX text-sm font-medium text-text-dim-color"
                    >Number of Plays</label
                  >
                  <div class="relative">
                    <input
                      v-model="numOfBets"
                      :disabled="
                        currentState === 'inProgress' || currentState !== 'notStarted'
                      "
                      class="input-bet"
                      placeholder="Number of Plays"
                      type="text" />
                  </div>
                </div>

                <div class="mt-3">
                  <p class="mt-1 text-slate-300 font-semibold">
                    Remaining Bets: {{ remainingAutoBets }}
                  </p>
                </div>
                <div class="mt-1">
                  <span class="mt-1 text-slate-300 inline">
                    Profit: {{ balS.exchangeAndFormatAmount(totalProfit) }}
                    <img class="h-4 w-4 inline" :src="balS.mainIcon" alt />
                  </span>
                </div>
              </div>
            </div>
            <div class="flex-none mt-1">
              <button
                class="btn-bet"
                type="button"
                v-on:click="
                  () => (canStopAutoBet ? debouncedStopAutoBet() : debouncedAutoBet())
                "
                :disabled="(!canAutoBet && !canStopAutoBet) || numOfBets < 0">
                {{ currentState === 'notStarted' ? 'Start Autoplay' : 'Stop Autoplay' }}
              </button>
            </div>
          </div>
        </TabPanel>
      </TabPanels>

      <TabList
        v-if="value1 < 768"
        class="flex flex-row justify-center md:justify-start w-full md:w-auto space-x-1 sm:space-x-2 bg-primary rounded-xl">
        <Tab
          v-for="tab in tabs"
          :disabled="currentState === 'inProgress'"
          :key="tab"
          v-slot="{ selected }"
          as="template">
          <button
            v-on:click="setActiveTab(tab)"
            :class="[
              selected ? 'bg-secondary-light text-white' : 'bg-primary text-slate-600',
              tab === '' ? 'flex-1 border-b-0 pointer-events-none' : '',
            ]"
            class="text-white focus:outline-none w-full font-medium rounded-lg p-3 bg-secondary hover:bg-opacity-70">
            {{ tab }}
          </button>
        </Tab>
      </TabList>
    </TabGroup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import { defaultConvertor } from '../../lib/rsgp';
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue';
import { getBalanceIcon } from '@/lib/icons';
import { balanceStore } from '@/store/pinia/balanceStore';

const TABS = ['Manual', 'Auto'];
import { ref } from 'vue';
import styles from '@tailwindcss/typography/src/styles';
var sw = window.innerWidth;

export default {
  name: 'PlinkoBets',
  components: {
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
  },

  props: {
    currentState: {},
    loading: {},
    rows: {},
    risk: {},
    updateRisk: {},
    updateRows: {},
    initialWager: {},
    multiplier: {},
    playerProfit: {
      default: 0,
    },
    gameId: {
      default: 0,
    },
    startGame: {},
    limits: {},
    setActiveMode: {},
    placeAutoBet: {},
    totalProfit: {},
    autoBetsCount: {},
    stopAutoBet: {},
  },
  setup() {
    const balS = balanceStore();
    balS.init();
    let selectedTab = ref(0);
    return { tabs: TABS, selectedTab, balS };
  },
  created() {
    // init defaults
    this.gameRisk = this.risk;
    this.rowsCount = this.rows;
    this.debouncedPrimaryAction = debounce(this.onPrimaryAction, 0, {
      leading: true,
      trailing: false,
    });
    this.debouncedAutoBet = debounce(this.initAutoBet, 800, {
      leading: true,
      trailing: false,
    });
    this.debouncedStopAutoBet = debounce(this.stopAutoBet, 800, {
      leading: true,
      trailing: false,
    });
  },
  data() {
    return {
      wager: '0.01',
      active: 'Manual',
      rowsCount: 0,
      gameRisk: '',
      numOfBets: 0,
      autoBet: false,
      autoBetAmount: 0,
      profit: 0,
      buttonDisabled: false,
      value1: window.innerWidth,
      debouncedPrimaryAction: () => {},
      debouncedAutoBet: () => {},
    };
  },
  deactivated() {
    this.autoBet = false;
  },
  watch: {
    limits: {
      handler: function (newW, oldW) {
        if (newW) {
          //this.wager = this.rsgp(newW.min_wager)
        }
      },
      immediate: true,
    },
    currentState: function (newState, oldState) {
      this.buttonDisabled = false;
    },
    rowsCount: function (newState, oldState) {
      this.updateRows(newState);
    },
    gameRisk: function (newState, oldState) {
      this.updateRisk(newState);
    },
  },
  computed: {
    ...mapGetters(['me', 'userBalance']),
    betAmount() {
      return this.balS.reverseExchangeCurrency(this.wager);
    },
    canAutoBet() {
      return this.currentState === 'notStarted';
    },
    canStopAutoBet() {
      return this.currentState === 'inProgress' && this.active === 'Auto';
    },
    remainingAutoBets() {
      let remaining =
        (!isNaN(this.numOfBets) ? this.numOfBets : 0) -
        (!isNaN(this.autoBetsCount) ? this.autoBetsCount : 0);
      return remaining > 0 ? remaining : 0;
    },
    balanceIcon() {
      return this.balS.mainIcon;
    },
  },
  methods: {
    rsgp(x) {
      return this.balS.exchangeCurrency(x, this.balS.default);
    },
    onPrimaryAction() {
      this.placeBet();
    },
    setActive(active) {
      if (this.currentState !== 'notStarted') return;
      this.active = active;
      this.autoBet = false;
      this.setActiveMode(active);
    },
    placeBet() {
      let error = null;
      if (isNaN(this.betAmount)) {
        error = 'Play amount must be numeric';
      }
      if (error && error.length > 0) {
        window.swal('Uh-oh', error, 'error');
      } else {
        this.startGame(this.betAmount, this.risk, this.rows);
      }
    },
    scaleWager(x) {
      try {
        this.wager = this.balS.formatRaw(x * (isNaN(this.wager) ? 0 : this.wager));
      } catch (e) {}
    },

    async initAutoBet() {
      await this.placeAutoBet(this.betAmount, this.numOfBets, this.risk, this.rows);
    },
    setActiveTab(tab) {
      this.setActive(tab);
    },
  },
};
</script>

<style scoped>
.plinkoBetButton {
  width: 100%;
}
@media (max-width: 460px) {
  .plinkoBetButton {
    width: 70px !important;
    float: right;
    margin-left: 3rem;
    /* margin-left: 75vw; */
  }
}
</style>
