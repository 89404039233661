<template>
   
    
    <SocialUserField v-if="me.username_not_set"/>
    <FullScreenLoader
    height="30px"
    v-if="!isLoggedIn"
     />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { doRecaptcha } from '@/lib/recaptcha';
import Radar from 'radar-sdk-js';
import { fraudAndLocationPassed, proxyLocation } from '@/lib/utils';
import Loader from '@/components/Shared/Loader.vue';
import SocialUserField from '@/components/Auth/SocialUserField.vue';
import PuntModal from '@/components/Shared/PuntModal.vue';
import { generalStore } from '@/store/pinia/generalStore';
import FullScreenLoader from '@/components/Shared/FullScreenLoader.vue';
export default {
  name: 'SocialLogin',
  components: { Loader ,PuntModal,SocialUserField,FullScreenLoader},
  setup()
  {
    const generalS = generalStore();
    return { generalS };
  },
 
  computed: {
    ...mapGetters(['isLoggedIn','me']),
  },
  mounted()
  {
    this.generalS.isLoading = true;
    this.socialLogin();
  },
  methods: {
     
    close(){

    },
    
    async socialLogin() {
      try{
     
        await  this.performSocialLogin({...this.$route.query}).then((data)=>{
          this.generalS.isLoading = false;

        });
        await this.fetchUser();
        if(!this.me.username_not_set)
        {
          this.$router.push('/');
        }
          
      } 
      catch(e)
      {
    
        console.log(e);
        window.swal({
          title: 'Somethign went wrong',
          text: (e.data && e.data.message !=undefined)?e.data.message:'Something wrong please try again ',
          type: 'error',

        });
        this.generalS.isLoading = false;
        

      }
       
      
    },
    ...mapActions(['performLogin', 'performSocialLogin','fetchUser']),
  },
};
</script>
