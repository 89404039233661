<template>
  <div class="flex flex-row md:w-[auto] w-full">
    <TabGroup
      :selectedIndex="selectedTab"
      @change="changeTab"
      as="div"
      class="w-full max-w-[1200px]">
      <div class="flex flex-col w-full justify-start">
        <TabList
          class="flex flex-row justify-center md:justify-start w-full md:w-auto space-x-1 sm:space-x-2 bg-primary rounded-xl">
          <Tab v-for="tab in tabs" :key="tab" v-slot="{ selected }" as="template">
            <button
              :class="[
                selected ? 'bg-secondary-light text-white' : 'bg-primary text-slate-600',
                tab === '' ? 'flex-1 border-b-0 pointer-events-none' : '',
              ]"
              class="text-white focus:outline-none flex-1 font-medium rounded-lg p-1 m-2 bg-secondary hover:bg-opacity-70 disabled:opacity-80">
              {{ tab }}
            </button>
          </Tab>
        </TabList>
      </div>

      <TabPanels class="mt-2">
        <TabPanel key="1">
          <div class="flex flex-col-reverse md:flex-col">
            <div class="">
              <div class="flex-none">
                <span class="flex h-[1px] opacity-50 bg-line-color-bright" />
              </div>
              <div>
                <label
                  for="bet-manual-amount"
                  class="mt-1 flex justify-between mb-1 text-sm font-medium text-text-dim-color">
                  Play Amount
                </label>
                <div class="relative">
                  <div
                    class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <img class="h-5 w-5" :src="balS.mainIcon" alt />
                  </div>
                  <input
                    type="number"
                    id="bet-manual-amount"
                    v-model="wager"
                    class="input-bet-amount"
                    placeholder="0.0000" />
                  <div
                    class="flex absolute inset-y-0 right-0 items-center pr-2 space-x-1 pointer-events-auto">
                    <button
                      type="button"
                      v-on:click="scaleWager(0.5)"
                      :disabled="autoBet"
                      class="btn-multiplier">
                      ½
                    </button>
                    <button
                      type="button"
                      v-on:click="scaleWager(2.0)"
                      :disabled="autoBet"
                      class="btn-multiplier">
                      2x
                    </button>
                  </div>
                </div>

                <label
                  for="mines"
                  class="block mb-2 text-sm font-medium text-text-dim-color"
                  >Number of Lines</label
                >
                <select v-model="numOfLines" :disabled="autoBet" class="input-bet-select">
                  <option v-for="i in 20" v-bind:key="i">{{ i }}</option>
                </select>
              </div>
            </div>
            <div class="w-full">
              <button
                v-on:click="initAutoBet(1)"
                :disabled="buttonDisabled || currentState === 'inprogress'"
                class="btn-bet">
                Play
              </button>
            </div>
          </div>
        </TabPanel>
        <TabPanel key="2">
          <div class="flex flex-col-reverse md:flex-col">
            <div class="">
              <div class="flex-none">
                <span class="flex h-[1px] opacity-50 bg-line-color-bright" />
              </div>
              <div>
                <label
                  for="bet-manual-amount"
                  class="mt-1 flex justify-between mb-1 text-sm font-medium text-text-dim-color">
                  Play Amount
                </label>
                <div class="relative">
                  <div
                    class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <img class="h-5 w-5" :src="balS.mainIcon" alt />
                  </div>
                  <input
                    type="number"
                    id="bet-manual-amount"
                    v-model="wager"
                    :disabled="autoBet"
                    class="input-bet-amount"
                    placeholder="0.0000" />
                  <div
                    class="flex absolute inset-y-0 right-0 items-center pr-2 space-x-1 pointer-events-auto">
                    <button
                      type="button"
                      v-on:click="scaleWager(0.5)"
                      :disabled="autoBet"
                      class="btn-multiplier">
                      ½
                    </button>
                    <button
                      type="button"
                      v-on:click="scaleWager(2.0)"
                      :disabled="autoBet"
                      class="btn-multiplier">
                      2x
                    </button>
                  </div>
                </div>

                <label
                  for="mines"
                  class="block mb-2 text-sm font-medium text-text-dim-color"
                  >Number of Lines</label
                >
                <select v-model="numOfLines" :disabled="autoBet" class="input-bet-select">
                  <option v-for="i in 20" v-bind:key="i">{{ i }}</option>
                </select>
              </div>
            </div>
            <div class="flex flex-col md:mt-0">
              <div class="flex-1">
                <div class="py-0.5 md:pyt-1">
                  <label class="block mb-2 text-sm font-medium text-text-dim-color"
                    >Number of Plays</label
                  >
                  <div class="relative">
                    <input
                      v-model="numOfBets"
                      :disabled="autoBet"
                      class="input-bet"
                      placeholder="Number of Plays"
                      type="text" />
                  </div>
                </div>
                <div class="py-0.5 md:py-1">
                  <label class="mt-1 block MBX text-sm font-medium text-text-dim-color"
                    >On Win</label
                  >
                  <div class="flex flex-row w-full bg-primary p-0.5 space-x-1 rounded">
                    <button
                      :disabled="inProgress"
                      :class="[
                        onWinSelected === 'Reset' ? 'bg-secondary ' : 'bg-secondary/0',
                      ]"
                      class="px-2 text-sm text-slate-300 shadow rounded hover:opacity-70"
                      v-on:click="setOnWin('Reset')">
                      Reset
                    </button>
                    <button
                      :disabled="buttonDisabled"
                      :class="[
                        onWinSelected !== 'Reset' ? 'bg-secondary ' : 'bg-secondary/0',
                      ]"
                      class="px-2 shadow rounded text-sm text-slate-300 hover:opacity-70"
                      v-on:click="setOnWin('Inc')">
                      IncreaseBy:
                    </button>
                    <div class="relative flex-1 rounded-xl">
                      <input
                        v-model="onWinPercent"
                        :disabled="inProgress || onWinSelected === 'Reset'"
                        class="input-bet-winloss"
                        max="1000000"
                        min="0"
                        type="text" />
                      <div
                        class="absolute inset-y-0 right-0 flex items-center rounded-r-md m-0.5 box-context">
                        <button class="btn-winloss">%</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="py-0.5 md:py-1">
                  <label class="mt-1 block MBX text-sm font-medium text-text-dim-color"
                    >On Loss</label
                  >
                  <div class="flex flex-row w-full bg-primary p-0.5 space-x-1 rounded">
                    <button
                      :disabled="autoBet"
                      :class="[
                        onLossSelected === 'Reset' ? 'bg-secondary ' : 'bg-secondary/0',
                      ]"
                      class="px-2 text-slate-300 shadow rounded text-sm hover:opacity-70"
                      v-on:click="setOnLoss('Reset')">
                      Reset
                    </button>
                    <button
                      :disabled="autoBet"
                      :class="[
                        onLossSelected !== 'Reset' ? 'bg-secondary ' : 'bg-secondary/0',
                      ]"
                      class="px-2 shadow rounded text-slate-300 text-sm hover:opacity-70"
                      v-on:click="setOnLoss('Inc')">
                      IncreaseBy:
                    </button>
                    <div class="relative flex-1">
                      <input
                        v-model="onLossPercent"
                        :disabled="autoBet || onLossSelected === 'Reset'"
                        class="input-bet-winloss"
                        max="1000000"
                        min="0"
                        type="text" />
                      <div
                        class="absolute inset-y-0 right-0 flex items-center rounded-r-md m-0.5 box-context">
                        <button class="btn-winloss">%</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="py-0.5 md:pyt-1">
                  <label class="block mb-2 text-sm font-medium text-text-dim-color"
                    >Stop On Profit</label
                  >
                  <div class="relative">
                    <input
                      v-model="stopOnProfit"
                      :disabled="autoBet"
                      class="input-bet"
                      placeholder="Stop On Profit"
                      type="text" />
                  </div>
                </div>
                <div class="py-0.5 md:pyt-1">
                  <label class="block mb-2 text-sm font-medium text-text-dim-color"
                    >Stop On Loss</label
                  >
                  <div class="relative">
                    <input
                      v-model="stopOnLoss"
                      :disabled="autoBet"
                      class="input-bet"
                      placeholder="Stop On Loss"
                      type="text" />
                  </div>
                </div>
                <div class="mt-2">
                  <span class="mt-2 text-slate-300 inline">
                    Profit: {{ balS.formatCrypto(profit, true) }}
                    <img class="h-4 w-4 inline" :src="balS.mainIcon" alt />
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div class="flex-none py-4">
                <button
                  :disabled="
                    buttonDisabled ||
                    !numOfBets ||
                    Number.parseInt(numOfBets) <= 0 ||
                    (currentState === 'inprogress' && !this.autoBet)
                  "
                  v-on:click="initAutoBet()"
                  class="bg-onprimary w-full p-3 focus:ring-1 text-line-color font-semibold rounded-xl disabled:bg-[#0b692d]/80 disabled:cursor-not-allowed hover:bg-[#43cc45]"
                  type="button">
                  {{
                    currentState === 'inprogress' && this.autoBet
                      ? 'Stop Autobet'
                      : 'Start Autobet'
                  }}
                </button>
              </div>
            </div>
          </div>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { defaultConvertor } from '@/lib/rsgp';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue';
import { ref } from 'vue';
import { getBalanceIcon } from '@/lib/icons';
import { balanceStore } from '@/store/pinia/balanceStore';
import { formatCryptoRaw } from '@/lib/utils';

const TABS = ['Manual', 'Auto'];

export default {
  name: 'SlotsBets',
  components: {
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
  },
  props: {
    currentState: {},
    playerProfit: {},
    gameId: {
      default: 0,
    },
    playHandler: {},
  },
  data() {
    return {
      wager: '0.01',
      numOfLines: 20,
      active: 'Manual',
      currency: 'BTC',
      numOfBets: 0,
      autoBet: false,
      autoBetAmount: 0,
      onWinPercent: 0.0,
      onLossPercent: 0.0,
      onWinSelected: 'Reset',
      onLossSelected: 'Reset',
      stopOnLoss: '0',
      stopOnProfit: '0',
      profit: 0,
      buttonDisabled: false,
    };
  },
  setup() {
    let selectedTab = ref(0);
    const balS = balanceStore();
    return { balS, tabs: TABS, selectedTab };
  },
  mounted() {
    this.wager = '0.01';
    this.scaleWager(1);
  },
  deactivated() {
    this.autoBet = false;
  },
  watch: {
    currentState: function (newState, oldState) {
      this.buttonDisabled = false;
      if (this.autoBet) {
        if (newState === 'finished' || newState === 'failed') {
          if (this.active === 'Manual' || newState === 'failed') {
            this.autoBet = false;
            return;
          }
          if (this.numOfBets <= 0) {
            this.numOfBets = 0;
            this.autoBet = false;
          }

          this.profit += this.playerProfit - this.betAmount;
          if (this.playerWonBet) {
            if (this.onWinSelected === 'Reset') {
              this.wager = this.rsgp(this.autoBetAmount);
            } else {
              this.scaleWager(1 + Number.parseFloat(this.onWinPercent) / 100.0);
            }
          } else if (this.playerLostBet) {
            if (this.onLossSelected === 'Reset') {
              this.wager = this.rsgp(this.autoBetAmount);
            } else {
              this.scaleWager(1 + Number.parseFloat(this.onLossPercent) / 100.0);
            }
          }

          if (this.numOfBets > 0) {
            this.bet(this.betAmount);
          } else {
            this.autoBet = false;
            this.numOfBets = 0;
          }
        }

        if (this.stopOnProfitAmount !== 0 && this.profit >= this.stopOnProfitAmount) {
          this.autoBet = false;
        } else if (this.stopOnLossAmount !== 0 && this.profit <= -this.stopOnLossAmount) {
          this.autoBet = false;
        }
      }
    },
  },
  computed: {
    ...mapGetters(['me', 'userBalance']),
    betAmount() {
      return this.balS.reverseExchangeCurrency(this.wager);
    },
    stopOnProfitAmount() {
      return this.balS.reverseExchangeCurrency(this.stopOnProfit);
    },
    stopOnLossAmount() {
      return this.balS.reverseExchangeCurrency(this.stopOnLoss);
    },
    playerLostBet() {
      return Number.parseFloat(this.playerProfit) < this.betAmount;
    },
    playerWonBet() {
      return !this.playerLostBet;
    },
    balanceIcon() {
      return getBalanceIcon(this.balS.default);
    },
    inProgress() {
      return this.currentState.toLowerCase() === 'inprogress';
    },
  },
  methods: {
    rsgp(x) {
      return this.balS.exchangeCurrency(x, this.currency);
    },
    changeTab(index) {
      this.selectedTab = index;
      this.active = this.tabs[index];
    },
    scaleWager(x) {
      this.wager = this.balS.formatCryptoRaw(
        x * (isNaN(this.wager) ? 0 : this.wager),
        true,
      ); //this.balS.normalizeWager(desiredWager)
    },
    setOnWin(selected) {
      this.onWinSelected = selected;
    },
    setOnLoss(selected) {
      this.onLossSelected = selected;
    },
    setActive(active) {
      this.active = active;
      this.autoBet = false;
    },
    initAutoBet(cnt) {
      this.currency = this.balS.default;
      if (this.autoBet) {
        this.autoBet = false;
      } else if ((cnt || this.numOfBets) > 0) {
        if (cnt) {
          this.numOfBets = cnt;
        }
        this.profit = 0;
        this.autoBet = true;
        this.autoBetAmount = this.betAmount;
        this.bet(this.betAmount);
      }
    },
    bet(amount) {
      this.buttonDisabled = true;
      this.playHandler(amount, this.numOfLines, this.currency);
      this.numOfBets -= 1;
    },
  },
};
</script>
