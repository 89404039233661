<template>
  <punt-modal show-close="true" size="max-w-2xl" class="rules_title">
    <template #title>
      <h1 class="text-xl font-bold text-[#dfbc00] mb-2 max-[767px]:text-lg">
        Game Rules and Limits
      </h1>
    </template>
    <template #body>
      <div class="modal is-active">
        <div class="modal-background" v-on:click.stop.prevent="closeModal"></div>
        <div class="modal-card">
          <section class="modal-card-body">
            <div class="space-y-2 max-[767px]:text-sm">
              <p class="has-text-centered" style="color: white; padding-bottom: 3px">
                <strong style="color: white">Unlimited</strong> decks in play
              </p>
              <p class="has-text-centered" style="color: white; padding-bottom: 3px">
                Insurance is an optional side play only available when the dealers reveals
                an Ace face card.
              </p>
              <p class="has-text-centered" style="color: white; padding-bottom: 3px">
                If both the player and the dealer have natural blackjack, then the result
                is a push.
              </p>
              <p class="has-text-centered" style="color: white; padding-bottom: 3px">
                If the dealer has natural blackjack, game concludes and insurance is paid
                if taken.
              </p>
              <p class="has-text-centered" style="color: white; padding-bottom: 3px">
                You can only split once.
              </p>
              <p class="has-text-centered" style="color: white; padding-bottom: 3px">
                You cannot hit on split aces.
              </p>
              <p class="has-text-centered" style="color: white; padding-bottom: 3px">
                You can double on any first two cards.
              </p>
              <p class="has-text-centered" style="color: white; padding-bottom: 3px">
                You can double on a split.
              </p>
              <p class="has-text-centered" style="color: white; padding-bottom: 3px">
                Dealer stands on soft 17.
              </p>
            </div>
          </section>
        </div>
      </div>
    </template>
  </punt-modal>
</template>

<script>
import { defaultConvertor as rsgp } from '@/lib/rsgp';
import PuntModal from '@/components/Shared/PuntModal.vue';

export default {
  components: { PuntModal },
  props: {
    onClose: {
      type: String,
      required: false,
      default: function () {
        return 'close';
      },
      validator: function (x) {
        return ['close', 'back'].includes(x);
      },
    },
  },
  data() {
    return {
      limits: false,
      error: null,
      fetching: false,
    };
  },
  methods: {
    closeModal() {
      switch (this.onClose) {
        case 'back':
          this.$router.go(-1);
          break;
        case 'close':
          this.$emit('close');
          break;
      }
    },
    rsgp(x) {
      return rsgp.format(x);
    },
  },
};
</script>

<style lang="sass">
.dark section.modal-card-body p, .dark section.modal-card-body .subtitle
  color: black
</style>
