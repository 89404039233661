<template>
  <punt-modal show-close="true" size="max-w-2xl" class="rules_title">
    <template #title>
      <div class="flex flex-row items-center gap-2 text-slate-300/80">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-8 w-8"
          viewBox="0 0 20 20"
          fill="currentColor">
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clip-rule="evenodd" />
        </svg>
        <h1 class="text-lg font-bold">Roulette Spin #{{ gameId.toLocaleString() }}</h1>
      </div>
    </template>
    <template #body>
      <section class="modal-card-body text-slate-300">
        <div v-if="!game" class="text-center py-8">Loading...</div>
        <div class="space-y-2" v-else>
          <div
            class="flex flex-row justify-center items-center gap-1 text-center inline py-8">
            <span class="inline">
              <strong>{{ rsgp(game.wager) }}</strong>
              <img
                class="ml-1 inline h-4 w-4"
                :src="balS.getBalanceIcon(game.game_mode)" />
            </span>
            <span v-if="user?.username != null">
              played by <strong>{{ user?.username }}</strong></span
            >
          </div>
          <div class="flex flex-row text-center mt-8">
            <div class="flex-1">
              <h2 class="subtitle font-bold">Result</h2>
              <h5 class="subtitle font-medium">{{ gameResult }}</h5>
            </div>
            <div class="flex-1">
              <h2 class="subtitle font-bold">Play</h2>
              <h5 class="inline font-medium">
                {{ rsgp(game.wager) }}
                <img
                  class="ml-1 inline h-4 w-4"
                  :src="balS.getBalanceIcon(game.game_mode)" />
              </h5>
            </div>
            <div class="flex-1">
              <h2 class="subtitle font-bold">Profit</h2>
              <h5 class="inline font-medium">
                <span
                  class="inline"
                  :style="{ color: game.profit > 0 ? 'green' : 'red' }"
                  >{{ rsgp(game.profit) }}</span
                >
                <img
                  class="ml-1 inline h-4 w-4"
                  :src="balS.getBalanceIcon(game.game_mode)" />
              </h5>
            </div>
          </div>
          <div class="scale-75">
            <RouletteTable view-only="true" :bets="bets"> </RouletteTable>
          </div>
        </div>
      </section>
    </template>
  </punt-modal>
</template>

<script>
import RouletteTable from './RouletteTable.vue';
import { copyToClipboard } from '@/lib/utils';
import PuntModal from '@/components/Shared/PuntModal.vue';
import { balanceStore } from '@/store/pinia/balanceStore';

const JSBIN = 'https://output.jsbinnnn.com/raxucab';

export default {
  components: {
    RouletteTable,
    PuntModal,
  },
  setup() {
    return { balS: balanceStore() };
  },
  data() {
    return {
      game: null,
      error: null,
      fetching: false,
      showVerify: false,
    };
  },
  created() {
    this.fetchGameInfo();
  },
  computed: {
    bets() {
      const bets = this.game.bets;
      return Object.keys(bets).reduce((arr, k) => {
        arr[parseInt(k.replace('bet_', ''))] = bets[k];
        return arr;
      }, []);
    },
    interactiveUrl() {
      return `${JSBIN}?cs=${this.seed.client_seed}&ss=${this.seed.server_seed}&nonce=${this.game.nonce}`;
    },
    seed() {
      return this.game.seed;
    },
    user() {
      return this.game.user;
    },
    gameResult() {
      if (this.game.result < 37) {
        return this.game.result;
      }
      if (this.game.result === 37) {
        return '00';
      }
    },
  },
  methods: {
    closeModal() {
      switch (this.onClose) {
        case 'back':
          this.$router.go(-1);
          break;
        case 'close':
          this.$emit('close');
          break;
      }
    },
    fetchGameInfo() {
      this.fetching = true;
      this.$http
        .get('roulette/' + this.gameId)
        .then(({ data }) => {
          data.bets =
            typeof data.bets === 'string' || data.bets instanceof String
              ? JSON.parse(data.bets)
              : data.bets;
          this.game = data;
          this.fetching = false;
        })
        .catch((err) => {
          this.fetching = false;
          this.error = err.toString();
        });
    },
    copyClient() {
      copyToClipboard(this.seed.client_seed);
    },
    copyServer() {
      copyToClipboard(this.seed.server_seed);
    },
    copyServerHashed() {
      copyToClipboard(this.seed.server_seed_hashed);
    },
    copyNonce() {
      copyToClipboard(this.seed.nonce);
    },
    rsgp(x) {
      return this.balS.exchangeAndFormatAmount(x, this.game.game_mode);
    },
  },
  watch: {
    '$route.params.gameId': {
      handler: function (betId) {
        this.gameId = betId;
        this.fetchGameInfo();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
