export default class Optimove
{
    constructor()
    {
        const identifier = 'optimove-sdk-script';
        this.enabled = import.meta.env.VITE_OPTIMOVE_ON;
        this.loaded = false;

        if (!this.enabled) {
            return;
        }

        if (document.getElementById(identifier)) {
            this.loaded = true;
            return;
        }

        const head = document.getElementsByTagName('head')[0];
        const script = document.createElement('script');
        script.id = identifier.toString();
        script.type = 'text/javascript';
        script.src = 'https://sdk.optimove.net/v2/websdk/?tenant_id=1421&tenant_token=bba89b2545724498a0b606b9c953a73b';
        script.async = true;
        script['data-optimove-service-worker-path'] = "/optimove-push-notification-worker.js";

        script.onload = () => { this.loaded = true };

        head.appendChild(script);
    }

    isReady()
    {
        return this.enabled && this.loaded;
    }

    sendPageVisit(url, title, category, userId)
    {
        if (!this.isReady()) {
            return;
        }

        // userId has to be a string when we send it to Optimove, otherwise
        // their SDK doesn't recognize it. But when the player isn't logged in,
        // we don't have any value for userId.
        optimoveSDK.API.setPageVisit(url, title, category, userId ? `chanced_${userId}` : null);
    }
}
